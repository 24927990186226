import Crud from "./crud"
import { Client } from "@/shared/services/client"
import { Template, TemplatePayload } from "@/shared/models/template"
import { Tag } from "@/shared/models/tag"

const client = new Client()

export class TemplateService extends Crud<Template> {
    constructor() {
        super("/crm/messaging/templates")
    }

    async createTemplate(body: TemplatePayload): Promise<{ templates: Template[] }> {
        const response = await client.post(this.endpoint, body)
        if (response?.template) {
            return { templates: [response.template] }
        }
        return response
    }

    updateTemplate(templateId: string, body: TemplatePayload): Promise<{ template: Template }> {
        return client.put(`${this.endpoint}/${templateId}`, body)
    }

    deleteTemplate(templateId: string): Promise<{ category: Template }> {
        return client.delete(`${this.endpoint}/${templateId}`)
    }

    checkTemplateList(typeName: string, entityIds: string[]) {
        return client.get(`${this.endpoint}/listCheck`, { params: { typeName, entityIds } })
    }

    bulkDelete(ids: string[]): Promise<{ deletedCount: number }> {
        return client.post(`${this.endpoint}/bulk/delete`, {
            ids,
        })
    }

    bulkUpdateTagOnTemplate(templateIds: string[], toAdd: Tag[], toRemove: Tag[], all: boolean) {
        return client.post(`${this.endpoint}/bulk/update/tags`, {
            ids: templateIds,
            all,
            updates: {
                toAdd,
                toRemove,
            },
        })
    }
}
