import { Client } from "@/shared/services/client"
import Crud from "@/shared/services/crud"
import { Log } from "@/shared/models/event-log"
import { TableQueryParams } from "../models/table-query-params"
import { PagedResponse } from "../models/paged-response"

const client = new Client()

export class EventLogService extends Crud<Log> {
    constructor() {
        super("crm/logs")
    }

    updateEventLog(id: string, eventLog: Partial<Log>): Promise<Log> {
        return client.patch(`${this.endpoint}/${id}`, eventLog)
    }

    getAll(queryParams: TableQueryParams, isPointsUsage?: boolean): Promise<PagedResponse<Log>> {
        return client.get(this.endpoint, { params: { ...queryParams, isPointsUsage } })
    }

    checkEventLogList(typeName: string, entityIds: string[]) {
        return client.get(`${this.endpoint}/listCheck`, { params: { typeName, entityIds } })
    }
}
