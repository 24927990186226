import { io, Socket } from "socket.io-client"
import { getToken } from "../utils/helpers"

export class SocketClientWrapper {
    private socket: Socket | null = null
    private static instance: SocketClientWrapper | null = null

    constructor(private serverUrl: string) {
        if (SocketClientWrapper.instance) {
            throw new Error("SocketClientWrapper instance already exists. Use getInstance() to obtain the instance.")
        }

        this.socket = io(serverUrl, {
            extraHeaders: {
                Authorization: `Bearer ${getToken()}`,
            },
            reconnection: true,
            reconnectionAttempts: 5,
            reconnectionDelay: 1000,
            reconnectionDelayMax: 5000,
            timeout: 20000,
            autoConnect: false, // Don't connect automatically, we'll do it manually
        })

        // Set up reconnection with fresh token
        this.socket.on("reconnect_attempt", () => {
            if (this.socket) {
                this.socket.io.opts.extraHeaders = {
                    Authorization: `Bearer ${getToken()}`, // Get fresh token on reconnect
                }
            }
        })

        // Handle connection errors
        this.socket.on("connect_error", (error) => {
            console.error("Socket connection error:", error.message)
            // If the error is related to authentication, we might need to refresh the token
            if (error.message.includes("auth") || error.message.includes("unauthorized")) {
                console.log("Authentication error, will try with fresh token on next reconnect")
            }
        })

        SocketClientWrapper.instance = this
    }

    static getInstance(serverUrl: string): SocketClientWrapper {
        if (!SocketClientWrapper.instance) {
            SocketClientWrapper.instance = new SocketClientWrapper(serverUrl)
        }
        return SocketClientWrapper.instance
    }

    connect() {
        if (!this.socket) {
            throw new Error("Socket is not initialized!")
        }
        // Ensure we have the latest token before connecting
        this.socket.io.opts.extraHeaders = {
            Authorization: `Bearer ${getToken()}`,
        }
        this.socket.connect()
    }

    disconnect() {
        if (!this.socket) {
            throw new Error("Socket is not initialized!")
        }
        this.socket.disconnect()
        this.socket = null
        SocketClientWrapper.instance = null
    }

    on(event: string, callback: (...args: any[]) => void) {
        if (!this.socket) {
            throw new Error("Socket is not initialized!")
        }
        this.socket.on(event, callback)
    }

    once(event: string, callback: (...args: any[]) => void) {
        if (!this.socket) {
            throw new Error("Socket is not initialized!")
        }
        this.socket.once(event, callback)
    }

    off(event: string, callback?: (...args: any[]) => void) {
        if (!this.socket) {
            throw new Error("Socket is not initialized!")
        }
        if (callback) {
            this.socket.off(event, callback)
            return
        }

        this.socket.removeAllListeners(event)
    }

    emit(event: string, ...args: any[]) {
        if (!this.socket) {
            throw new Error("Socket is not initialized!")
        }
        this.socket.emit(event, ...args)
    }
}
