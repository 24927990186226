import { ref } from "vue"

/**
 * Global authentication state management
 * These flags help prevent infinite loops and handle session expiration gracefully
 */

// Tracks if a 401/403 error is currently being handled
export const isHandling401Error = ref(false)

// Tracks if a session expiration toast is currently being shown
export const isAuthToastShown = ref(false)

// Tracks if the app is in the process of logging out
export const isLoggingOut = ref(false)

/**
 * Set the authentication error handling state
 * @param value - Whether the app is handling an authentication error
 */
export function setIsHandling401Error(value: boolean): void {
    isHandling401Error.value = value
}

/**
 * Set the authentication toast state
 * @param value - Whether the authentication toast is being shown
 */
export function setIsAuthToastShown(value: boolean): void {
    isAuthToastShown.value = value
}

/**
 * Set the logging out state
 * @param value - Whether the app is in process of logging out
 */
export function setIsLoggingOut(value: boolean): void {
    isLoggingOut.value = value
}

/**
 * Reset all authentication state flags
 * Used when user logs in successfully
 */
export function resetAuthState(): void {
    isHandling401Error.value = false
    isAuthToastShown.value = false
    isLoggingOut.value = false
}
