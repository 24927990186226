<template>
    <div class="flex flex-col w-fit" :class="disabledClass">
        <input
            ref="hiddenInput"
            type="file"
            hidden
            :accept="acceptedTypes"
            :multiple="multipleFiles"
            :disabled="isDisabled"
            @click="fixReselect"
            @change.prevent.stop="onChange($event?.target?.files)"
        />

        <div v-if="!isButtonUpload">
            <div
                ref="dragAndDrop"
                class="border-2 rounded-md border-dashed w-full"
                :class="isOverClass"
                :is-over="isOver"
                @dragover.prevent.stop="toggleDragOver"
                @dragleave.prevent.stop="toggleDragLeave"
                @drop.prevent.stop="onChange($event?.dataTransfer?.files, true)"
            >
                <!-- Default slot -->
                <div class="w-full cursor-pointer" @click.prevent.stop="hiddenInput?.click()">
                    <slot v-if="multipleFiles || (!multipleFiles && !fileList.length && !uploadedFileList.length)">
                        <div class="flex flex-col justify-center items-center px-6 py-10 w-full">
                            <component :is="mainIcon" class="w-12 h-12 text-gray-400 stroke-1 mb-3" />
                            <p class="text-sm font-medium text-gray-600 mb-1">
                                <a class="text-primary-color-600">Upload a file</a>
                                or drag and drop {{ dragAndDropText }}
                            </p>
                            <p class="text-xs text-gray-500">{{ acceptFilesText }}</p>
                        </div>
                    </slot>
                </div>

                <!-- Preview images -->
                <div v-if="isPreview">
                    <div
                        v-show="uploadedFileList.length || fileList.length"
                        class="px-6 py-10 flex items-center justify-center flex-wrap gap-8 text-sm"
                    >
                        <!-- Already uploaded files -->
                        <div
                            v-for="(file, index) in uploadedFileList"
                            :key="index"
                            class="relative"
                            @mouseover="setXButton(file.name, true)"
                            @mouseleave="setXButton(file.name, false)"
                        >
                            <div
                                v-if="file.mimetype === 'application/pdf'"
                                class="shadow-lg rounded-lg bg-gray-100 mb-2 flex justify-center items-center"
                                :class="hoverOnFile(file.name)"
                            >
                                <img class="w-10 h-10" src="/img/pdf-preview.svg" alt="pdf" />
                            </div>
                            <img
                                v-else
                                class="object-cover shadow-lg rounded-lg mb-2"
                                :height="minHeight"
                                :width="minWidth"
                                :class="hoverOnFile(file.name)"
                                :src="file.url"
                                alt="image"
                            />
                            <button
                                v-if="isXVisible && hoveredFileName == file.name"
                                class="absolute top-3 right-3 flex justify-center items-center bg-white w-10 h-10 rounded-lg"
                                @click.prevent="removePreviewFile(index, file.name, true)"
                            >
                                <TrashIcon class="text-red-500 w-4 h-4" />
                            </button>
                        </div>

                        <!-- New uploading files -->
                        <div
                            v-for="(file, index) in fileList"
                            :key="index"
                            class="relative max-w-fit"
                            @mouseover="setXButton(file.name, true)"
                            @mouseleave="setXButton(file.name, false)"
                        >
                            <div
                                v-if="file.type === 'application/pdf'"
                                class="shadow-lg rounded-lg bg-gray-100 mb-2 flex justify-center items-center"
                                :class="hoverOnFile(file.name)"
                            >
                                <img class="w-10 h-10" src="/img/pdf-preview.svg" alt="pdf" />
                            </div>
                            <img
                                v-else
                                ref="imagePreview"
                                class="object-cover max-h-44 shadow-lg rounded-lg mb-2"
                                :class="hoverOnFile(file.name)"
                                :src="createPreview(file)"
                                alt="image"
                            />
                            <button
                                v-if="isXVisible && hoveredFileName == file.name"
                                class="absolute top-3 right-3 flex justify-center items-center bg-white w-10 h-10 rounded-lg"
                                @click.prevent="removePreviewFile(index, file.name, false)"
                            >
                                <TrashIcon class="text-red-500 w-4 h-4" />
                            </button>
                        </div>
                    </div>
                </div>

                <!-- Preview files in row -->
                <div v-else>
                    <div
                        v-show="defaultDocuments.length || fileList.length"
                        class="flex flex-col gap-y-2 px-7 py-3 max-h-32 overflow-y-auto"
                    >
                        <div v-for="(file, index) in defaultDocuments" :key="index" class="flex flex-col text-sm">
                            <div class="flex flex-row items-center w-full justify-between mb-3 last:mb-0">
                                <div class="flex flex-row">
                                    <PaperClipIcon class="w-5 h-5 mr-3 text-gray-400" />
                                    <p class="image-text">
                                        {{ file.name }}
                                    </p>
                                </div>
                                <TrashIcon
                                    class="text-gray-400 w-5 h-5 cursor-pointer"
                                    @click.prevent="removeItemFromList(index)"
                                />
                            </div>
                        </div>
                        <div v-for="(file, index) in fileList" :key="index" class="flex flex-col text-sm">
                            <div class="flex flex-row items-center w-full justify-between mb-3 last:mb-0">
                                <div class="flex flex-row">
                                    <PaperClipIcon class="w-5 h-5 mr-3 text-gray-400" />
                                    <p class="image-text">
                                        {{ file.name }}
                                    </p>
                                </div>
                                <TrashIcon
                                    class="text-gray-400 w-5 h-5 cursor-pointer"
                                    @click.prevent="removeItemFromList(index)"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-else>
            <slot name="alternative" :upload="inputClick" />
        </div>
    </div>
</template>

<script lang="ts" setup>
import { onMounted, PropType, ref, computed, Component } from "vue"
import { UserGroupIcon, PaperClipIcon, TrashIcon } from "@heroicons/vue/outline"
import { ToastService } from "@/shared/services/toast"
import { UploadFile } from "@/shared/models/upload-file"

const props = defineProps({
    modelValue: {
        type: Array as PropType<File[]>,
        default: () => [],
    },
    multipleFiles: {
        type: Boolean,
        default: false,
    },
    accept: {
        type: Array as PropType<string[]>,
        default: Array.from(["image"]),
        validator: (values: string[]) =>
            values.every((value) => ["image", "video", "audio", "doc", "pdf", "excel", "text"].includes(value)),
    },
    maxSize: {
        type: Number,
        default: 1,
    },
    defaultDocuments: {
        type: Array as PropType<UploadFile[]>,
        default: () => [],
    },
    isPreview: {
        type: Boolean,
        default: false,
    },
    isDisabled: {
        type: Boolean,
        default: false,
    },
    minWidth: {
        type: String,
        default: "200",
    },
    minHeight: {
        type: String,
        default: "200",
    },
    fileUrl: {
        type: String,
        default: "",
    },
    isButtonUpload: {
        type: Boolean,
        default: false,
    },
    dragAndDropText: {
        type: String,
        default: "",
    },
    acceptFilesText: {
        type: String,
        default: "",
    },
    mainIcon: {
        type: Object as PropType<Component>,
        default: UserGroupIcon,
    },
})

const emit = defineEmits(["update:modelValue", "uploadFiles", "removeFile", "removePreviewFile"])
const toastService = new ToastService()

const fileList = computed<File[]>({
    get() {
        return props.modelValue
    },
    set(val) {
        emit("update:modelValue", val)
    },
})
const uploadedFileList = computed(() => props.defaultDocuments)
const hiddenInput = ref<HTMLInputElement>()
const dragAndDrop = ref<HTMLElement>()
const acceptedTypes = ref("")
const errorMsg = ref("")
const hoveredFileName = ref("")
const isOver = ref(false)
const isXVisible = ref(false)
const isOverClass = computed(() => (isOver.value ? "bg-gray-100" : "bg-white"))
const disabledClass = computed(() => (props.isDisabled ? "opacity-50 pointer-events-none" : ""))

// This needed when the user reselects the same file
function fixReselect() {
    if (hiddenInput.value) {
        hiddenInput.value.value = ""
    }
}

function inputClick() {
    hiddenInput.value?.click()
}

function hoverOnFile(fileName: string) {
    return isXVisible.value && hoveredFileName.value === fileName ? "opacity-50" : ""
}

function toggleDragOver() {
    isOver.value = true
}

function toggleDragLeave() {
    isOver.value = false
}

function onChange(files: FileList | undefined, isDrop = false) {
    if (!files?.length) {
        return
    }
    if (props.multipleFiles) {
        Array.from(files).forEach((file) => {
            validateFile(file)
        })
    } else {
        if (files.length > 1) errorMsg.value = "You can only upload one file"
        validateFile(files[0])
        fileList.value = [files[0]]
    }

    if (isDrop) {
        isOver.value = false
    }

    if (errorMsg.value.length) {
        toastService.addToast({ message: errorMsg.value, type: "error" })
        errorMsg.value = ""
    }
    emit("uploadFiles", fileList.value)
}

function validateFile(file: File) {
    if (!validateType(file)) {
        errorMsg.value = "The file type is not allowed"
    } else if (
        fileList.value.some(({ name }) => name === file.name) ||
        uploadedFileList.value.some(({ name }) => name === file.name)
    ) {
        errorMsg.value = "The file already exists"
    } else {
        fileList.value.push(file)
    }

    if (errorMsg.value.length) {
        toastService.addToast({ message: errorMsg.value, type: "error" })
        errorMsg.value = ""
    }
}

function validateType(file: File): boolean {
    return acceptedTypes.value.includes(file.type)
}

function createPreview(file: File) {
    return URL.createObjectURL(file)
}

function setXButton(name: string, visibility: boolean) {
    hoveredFileName.value = name
    isXVisible.value = visibility
}

function removeItemFromList(index: number) {
    fileList.value.splice(index, 1)
    emit("uploadFiles", fileList.value)
}

function removePreviewFile(index: number, name: string, isExisting: boolean) {
    isExisting ? uploadedFileList.value.splice(index, 1) : fileList.value.splice(index, 1)
    emit("removePreviewFile", name)
}

function setAcceptTypes() {
    props.accept.forEach((type) => {
        switch (type) {
            case "image": {
                acceptedTypes.value = `${acceptedTypes.value},image/jpeg,image/png,image/jpg`
                break
            }
            case "video": {
                acceptedTypes.value = `${acceptedTypes.value},video/mp4,video/3gpp,video/webm,video/quicktime`
                break
            }
            case "audio": {
                acceptedTypes.value = `${acceptedTypes.value},audio/mp3,audio/3gpp,audio/mpeg,audio/wave`
                break
            }
            case "doc": {
                acceptedTypes.value = `${acceptedTypes.value},.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document`
                break
            }
            case "pdf": {
                acceptedTypes.value = `${acceptedTypes.value},application/pdf`
                break
            }
            case "excel": {
                acceptedTypes.value = `${acceptedTypes.value},text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`
                break
            }
            case "text": {
                acceptedTypes.value = `${acceptedTypes.value},text/plain`
                break
            }
        }
    })
    acceptedTypes.value = acceptedTypes.value.replace(",", "")
}

onMounted(() => {
    setAcceptTypes()
})
</script>

<style scoped>
.image-text {
    color: rgb(75 85 99);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 20rem;
}
</style>
