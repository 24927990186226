<template>
    <GgmsModal :open="props.open" @closeModal="closeModal">
        <template #header>
            <div class="flex justify-flex-start items-center">
                <div class="mr-4 flex items-center justify-center h-12 w-12 rounded-full bg-primary-color-100">
                    <DocumentTextIcon class="h-6 w-6 text-primary-color-600" aria-hidden="true" />
                </div>
                <DialogTitle as="h3" class="text-lg leading-6 font-medium">
                    <div class="text-primary-color-600">
                        <span class="last:text-black cursor-pointer last:cursor-auto" @click="currentStepIndex = 0"
                            >{{ templateTitle }} Templates</span
                        >
                        <template v-for="(number, stepIndex) in currentStepIndex" :key="number">
                            <span
                                class="last:text-black ml-2 cursor-pointer last:cursor-auto"
                                @click="currentStepIndex = number"
                                >&gt; {{ steps[stepIndex].resolveTitle() }}</span
                            >
                        </template>
                    </div>
                </DialogTitle>
            </div>
        </template>
        <form class="space-y-6" @submit.prevent="">
            <component
                :key="currentStepIndex"
                :is="currentStep.component"
                :model-value="currentStep.value"
                @update:model-value="currentStep.handleUpdate"
                :category="steps[0].value"
            />
        </form>
        <template #under>
            <div class="flex justify-end">
                <GgmsButton styleType="secondary" @click="closeModal">Cancel</GgmsButton>
                <GgmsButton class="ml-3" @click="applyTemplate" :disabled="currentStepIndex !== 1">Insert</GgmsButton>
            </div>
        </template>
    </GgmsModal>
</template>

<script lang="ts" setup>
import GgmsModal from "@/components/GgmsModal.vue"
import { computed, onMounted, ref } from "vue"
import { DocumentTextIcon } from "@heroicons/vue/outline"
import { DialogTitle } from "@headlessui/vue"
import { Template } from "@/shared/models/template"
import GgmsButton from "@/components/GgmsButton.vue"
import TemplateSelectStep from "@/components/profile-details/middle-panel/message/TemplateSelectStep.vue"
import TemplatePreviewStep from "@/components/profile-details/middle-panel/message/TemplatePreviewStep.vue"
import { useTemplateStore } from "@/stores/template"

const templateStore = useTemplateStore()

interface Props {
    open: boolean
    templateTitle: string
}
const emit = defineEmits(["closeModal", "update:modelValue"])
const props = defineProps<Props>()

const currentStepIndex = ref(0)

const steps = [
    // choose template step
    {
        component: TemplateSelectStep,
        value: { templateType: setTemplateTitle() } as Template,
        handleUpdate: (value: Template) => {
            steps[0].value = value
            steps[1].value = value
            currentStepIndex.value += 1
        },
        resolveTitle: () => "Preview",
    },
    // preview step
    {
        component: TemplatePreviewStep,
        value: {} as Template,
        handleUpdate: (value: Template) => {
            steps[1].value = value
            return
        },
        resolveTitle: () => "",
    },
]

const currentStep = computed(() => steps[currentStepIndex.value])
function applyTemplate() {
    emit("update:modelValue", currentStep.value.value)
    closeModal()
}

function closeModal() {
    currentStepIndex.value = 0
    steps.forEach((step) => (step.value = {} as Template))
    steps[0].value = { templateType: setTemplateTitle() } as Template
    emit("closeModal")
}

function setTemplateTitle() {
    if (props.templateTitle === "Text Message") {
        return "sms"
    }
    return "email"
}

onMounted(() => {
    if (templateStore.tableState.total) {
        return
    }
    templateStore.getTemplates()
})
</script>
