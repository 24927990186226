import { defineStore } from "pinia"
import { nanoid } from "nanoid"
import { useGridCollectionStore } from "./grid-collection"
import { Component } from "vue"
import {
    AdjustmentsIcon,
    AnnotationIcon,
    ChatAltIcon,
    ClipboardCheckIcon,
    ClipboardListIcon,
    ClockIcon,
    FireIcon,
    MailIcon,
    TagIcon,
    TrashIcon,
    UserCircleIcon,
} from "@heroicons/vue/outline"
import { LightningBoltIcon } from "@heroicons/vue/solid"
import { Agent } from "@/shared/models/agent"
import { Collection } from "@/shared/models/collection"
import { TableState } from "@/shared/models/table"
import { ActionNodeType, NodeType, Workflow, WorkflowStatus, WorkflowWidget } from "@/shared/models/workflow"
import { ToastService } from "@/shared/services/toast"
import { WorkflowService } from "@/shared/services/workflow"
import { Message } from "@/shared/models/message"
import { Template } from "@/shared/models/template"
import { Sender } from "@/shared/models/sender"
import { EmailPayload } from "@/shared/models/email"
import { Recipient } from "@/shared/models/recipient"
import { FiltersGroup } from "@/shared/models/filter"
import { Form } from "@/shared/models/form"
import { GgmsError } from "@/shared/services/client"
import { DoNotDisturb, MarketingPreferences } from "@/shared/models/contact"
import StartSidebarComponent from "@/components/workflows/sidebar/StartSidebarComponent.vue"
import BranchSidebarComponent from "@/components/workflows/sidebar/BranchSidebarComponent.vue"
import DelaySidebarComponent from "@/components/workflows/sidebar/DelaySidebarComponent.vue"
import SinglePropertyComponent from "@/components/workflows/sidebar/action/branch/SinglePropertyComponent.vue"
import MultiplePropertiesComponent from "@/components/workflows/sidebar/action/branch/MultiplePropertiesComponent.vue"
import MultipleBranchesComponent from "@/components/workflows/sidebar/action/branch/MultipleBranchesComponent.vue"
import PercentageSplitComponent from "@/components/workflows/sidebar/action/branch/PercentageSplitComponent.vue"
import CalendarDateComponent from "@/components/workflows/sidebar/action/delay/CalendarDateComponent.vue"
import DatePropertyComponent from "@/components/workflows/sidebar/action/delay/DatePropertyComponent.vue"
import TimeAmountComponent from "@/components/workflows/sidebar/action/delay/TimeAmountComponent.vue"
import WeekDaysComponent from "@/components/workflows/sidebar/action/delay/WeekDaysComponent.vue"
import TimeOfDayComponent from "@/components/workflows/sidebar/action/delay/TimeOfDayComponent.vue"
import NoteComponent from "@/components/workflows/sidebar/action/crm/NoteComponent.vue"
import PropertyValueComponent from "@/components/workflows/sidebar/action/crm/PropertyValueComponent.vue"
import ContactDistributionComponent from "@/components/workflows/sidebar/action/crm/ContactDistributionComponent.vue"
import SendEmailComponent from "@/components/workflows/sidebar/action/communications/SendEmailComponent.vue"
import EnrolmentTriggersSidebar from "@/components/workflows/sidebar/trigger/EnrolmentTriggersSidebar.vue"
import ContactActivityTriggerSidebar from "@/components/workflows/sidebar/trigger/ContactActivityTriggerSidebar.vue"
import SendTextMessageComponent from "@/components/workflows/sidebar/action/communications/SendTextMessageComponent.vue"
import ApplyTagsComponent from "@/components/workflows/sidebar/action/crm/ApplyTagsComponent.vue"
import MergeIcon from "@/components/icons/MergeIcon.vue"
import MembershipChangedTriggerSidebar from "@/components/workflows/sidebar/trigger/MembershipChangedTriggerSidebar.vue"
import PropertyValueChangeTriggerSidebar from "@/components/workflows/sidebar/trigger/PropertyValueChangeTriggerSidebar.vue"
import SubmissionAndViewSidebar from "@/components/workflows/sidebar/trigger/SubmissionAndViewSidebar.vue"
import EnrollAndUnenrollSidebar from "@/components/workflows/sidebar/trigger/EnrollAndUnenrollSidebar.vue"
import EnrollOrUnenrollWorkflowComponent from "@/components/workflows/sidebar/action/crm/EnrollOrUnenrollWorkflowComponent.vue"
import DeleteContactsComponent from "@/components/workflows/sidebar/action/crm/DeleteContactsComponent.vue"
import ScheduleTriggerSidebar from "@/components/workflows/sidebar/trigger/schedule/ScheduleTriggerSidebar.vue"
import CalendarDateScheduleComponent from "@/components/workflows/sidebar/trigger/schedule/CalendarDateScheduleComponent.vue"
import WeekDaysScheduleComponent from "@/components/workflows/sidebar/trigger/schedule/WeekDaysScheduleComponent.vue"
import TimeOfDayScheduleComponent from "@/components/workflows/sidebar/trigger/schedule/TimeOfDayScheduleComponent.vue"
import CreateTaskSidebarComponent from "@/components/workflows/sidebar/action/crm/CreateTaskSidebarComponent.vue"
import GreyTriggerIcon from "@/components/workflows/icons/GreyTriggerIcon.vue"
import CMSListingActivityTriggerSidebar from "@/components/workflows/sidebar/trigger/CMSListingActivityTriggerSidebar.vue"
import MarketingPreferencesComponent from "@/components/workflows/sidebar/action/communications/MarketingPreferencesComponent.vue"
import DNDIcon from "@/components/icons/DNDIcon.vue"
import DoNotDisturbComponent from "@/components/workflows/sidebar/action/communications/DoNotDisturbComponent.vue"

export interface WorkflowStoreState {
    tableState: TableState<Workflow>
    workflow: Workflow
    currentActionNode: string
    conditionsMetNodeKey: string
    conditionsNotMetNodeKey: string
    areButtonDisabled: boolean
    lastComponent: any
    currentComponent: any
    backText: string
    headerText: string
    showButtons: boolean
    branches: {
        id?: string
        value: string | number
        label?: string
        filtersGroups?: FiltersGroup[]
        selectedFilterGroup?: number
        selectedFilterIndex?: number
        currentStep?: string | undefined
        filterIndex?: number
        type?: string
        selectedFilter?: { field: string; type: string; rule?: string; value?: string; displayName: string }
        selectedEntity?: string
    }[]
    noneBranch: { id?: string; value: string } | undefined
    modelValue: any
    messagePayload: Message | EmailPayload | undefined
    selectedData?:
        | Agent[]
        | Collection[]
        | Template[]
        | string[]
        | Form[]
        | Workflow[]
        | { _id?: string; name: string; label?: string }[]
    emailSendingTypes: string[]
    specificEmail: string
    specificUsers: Recipient[]
    toAdd?: any
    toRemove?: any
    selectedSender: Sender
    disabledBranches: boolean
    isEditing: boolean
    isBranchCopied: boolean
    triggerNodeId: string
    isWorkflowEdited: boolean
    workflowService: WorkflowService
    toastService: ToastService
    gridCollectionStore: ReturnType<typeof useGridCollectionStore>
    sidebarIcon: Component
    hasUnsavedChanges: boolean
    multipleModelValues: boolean[]
    isSubmission: boolean
    isEnrollWorkflow: boolean
    changedNode: boolean
    showDiscardButton: boolean
    schedule: string
    scheduleTime: string
    widgetWorkflows: WorkflowWidget[]
    previewWorkflow: Workflow
    marketingPreferences: MarketingPreferences
    doNotDisturb: DoNotDisturb
    isActiveSidebar: boolean
}

export const useWorkflowStore = defineStore("workflow", {
    state: (): WorkflowStoreState => ({
        tableState: {
            data: [],
            selectedData: [],
            currentPage: 0,
            lastPage: 0,
            pageLength: 50,
            numberOfPages: 0,
            total: 0,
            isLoading: false,
            isAllSelected: false,
            templateType: "",
            firstIds: [],
        } as TableState<Workflow>,
        workflow: {} as Workflow,
        currentActionNode: "",
        conditionsMetNodeKey: "",
        conditionsNotMetNodeKey: "",
        areButtonDisabled: false,
        lastComponent: null,
        currentComponent: StartSidebarComponent,
        backText: "",
        headerText: "",
        showButtons: false,
        branches: [],
        noneBranch: {} as { id?: string; value: string } | undefined,
        modelValue: {},
        messagePayload: {} as Message | EmailPayload | undefined,
        selectedData: [] as
            | Agent[]
            | Collection[]
            | Template[]
            | string[]
            | Form[]
            | Workflow[]
            | { _id?: string; name: string; label?: string }[],
        emailSendingTypes: [] as string[],
        specificEmail: "" as string,
        specificUsers: [] as Recipient[],
        toAdd: [],
        toRemove: [],
        selectedSender: {} as Sender,
        disabledBranches: false,
        isEditing: false,
        isBranchCopied: false,
        triggerNodeId: "",
        isWorkflowEdited: false,
        workflowService: new WorkflowService(),
        toastService: new ToastService(),
        gridCollectionStore: useGridCollectionStore(),
        sidebarIcon: {} as Component,
        hasUnsavedChanges: false,
        multipleModelValues: [] as boolean[],
        isSubmission: false,
        isEnrollWorkflow: false,
        changedNode: false,
        showDiscardButton: true,
        schedule: "daily",
        scheduleTime: "",
        widgetWorkflows: [] as WorkflowWidget[],
        previewWorkflow: {} as Workflow,
        marketingPreferences: {} as MarketingPreferences,
        doNotDisturb: {} as DoNotDisturb,
        isActiveSidebar: false,
    }),

    actions: {
        async getWorkflows(isFromCollection = false) {
            try {
                this.tableState.isLoading = true

                let lastId = null
                let offset = null

                // If we're going to the next page, use keyset pagination.
                if (this.tableState.currentPage === (this.tableState?.lastPage ?? 0) + 1) {
                    lastId =
                        this.tableState.data.length > 0
                            ? this.tableState.data[this.tableState.data?.length - 1]._id
                            : null
                }
                // If we're jumping to a specific page, use offset-based pagination.
                else if (
                    this.tableState.currentPage !== (this.tableState?.lastPage ?? 0) + 1 &&
                    this.tableState.currentPage > 1
                ) {
                    offset = (this.tableState.currentPage - 1) * this.tableState.pageLength
                }

                const filtersGroups = this.gridCollectionStore?.grid?.filtersGroups?.length
                    ? encodeURIComponent(JSON.stringify(this.gridCollectionStore.grid.filtersGroups))
                    : undefined

                if (!this.gridCollectionStore?.grid?.filtersGroups?.length && isFromCollection) {
                    this.tableState.data = []
                    this.tableState.total = 0
                    this.tableState.selectedData = []
                    this.tableState.numberOfPages = Math.ceil(1)
                    this.tableState.lastPage = this.tableState.currentPage
                    return
                }

                const response = await this.workflowService.getAll({
                    length: this.tableState.pageLength,
                    column: this.tableState.column,
                    order: this.tableState.order,
                    search: this.tableState.search,
                    filtersGroups,
                    lastId,
                    offset,
                })

                if (!response) {
                    return
                }

                // Store the first ID of the new page.
                if (this.tableState.firstIds && this.tableState.currentPage) {
                    this.tableState.firstIds[this.tableState.currentPage - 1] = response.data[0]._id as string
                }

                this.tableState.selectedData = []
                this.tableState.data = response.data.map((workflow) => {
                    if (workflow.status === "on") {
                        workflow.publishedStatus = true
                    }
                    if (workflow.status === "off") {
                        workflow.publishedStatus = false
                    }
                    return workflow
                })
                this.tableState.total = response.total
                this.tableState.numberOfPages = Math.ceil(this.tableState.total / this.tableState.pageLength)
                this.tableState.lastPage = this.tableState.currentPage
            } finally {
                this.tableState.isLoading = false
            }
        },

        resetTableState() {
            this.tableState.data = []
            this.tableState.total = 0
            this.tableState.selectedData = []
            this.tableState.numberOfPages = Math.ceil(1)
        },

        /**
         * This function is used to get the workflows that are enrolling to this workflow
         * and add them to a trigger node to be present in the workflow editor
         * if the trigger node is already present, it will update the trigger node
         * @param id : string
         * @param _workflow : Workflow
         * @returns workflow : Workflow
         */
        async getEnrolledFromTriggers(id: string, _workflow: Workflow): Promise<Workflow> {
            const workflow = _workflow
            try {
                const workflowsEnrolledFrom = await this.workflowService.getEnrolled(id)
                workflow.enrolledFrom = workflowsEnrolledFrom

                if (workflow.enrolledFrom) {
                    // create a trigger node with this list of workflows
                    // get top new node to be converted into trigger
                    const node = workflow.nodes.find((_node) => _node.type === NodeType.new && _node.data?.newTrigger)
                    const triggerNode = workflow.nodes.find((_node) => _node.type === NodeType.trigger)
                    const enrollTriggerNode = workflow.nodes.find(
                        (_node) => _node.type === NodeType.trigger && _node.data?.enrolledFrom
                    )
                    if (enrollTriggerNode) {
                        // just update the current enroll Trigger node
                        if (workflow.enrolledFrom.length > 0) {
                            enrollTriggerNode.data = {
                                enrolledFrom: workflowsEnrolledFrom,
                                showDeleteButton: false,
                            }
                        } else {
                            // convert this enroll trigger node to a new node
                            // and remove the newtrigger node already present
                            const index = workflow.nodes.indexOf(enrollTriggerNode)
                            const newTriggerNode = workflow.nodes.find(
                                (_node) => _node.type === NodeType.new && _node.data?.newTrigger
                            )
                            if (newTriggerNode) {
                                workflow.nodes.splice(workflow.nodes.indexOf(newTriggerNode), 1)
                            }
                            workflow.nodes[index].type = NodeType.new
                            workflow.nodes[index].data = { newTrigger: true, note: { text: "" }, type: NodeType.new }
                            // remove the edge from the enroll trigger node
                            const edge = workflow.edges.find((edge) => edge.source === enrollTriggerNode.id)
                            if (edge) {
                                workflow.edges.splice(workflow.edges.indexOf(edge), 1)
                            }
                        }
                    } else if (node && triggerNode && workflow.enrolledFrom.length > 0) {
                        //else create a new one
                        const index = workflow.nodes.indexOf(node)
                        const otherTriggerEdge = workflow.edges.find((edge) => edge.source === triggerNode.id)
                        if (!otherTriggerEdge?.target) {
                            return workflow
                        }
                        workflow.nodes[index].type = NodeType.trigger
                        if (node.data) {
                            node.data.enrolledFrom = workflowsEnrolledFrom
                            node.data.showDeleteButton = false
                        }
                        workflow.edges.push({
                            id: nanoid(),
                            source: node.id,
                            target: otherTriggerEdge.target,
                            type: "smoothstep",
                        })
                        const newId = nanoid()
                        const newNode = {
                            id: newId,
                            type: NodeType.new,
                            position: { x: 0, y: 0 },
                            data: { newTrigger: true, note: { text: "" }, type: NodeType.new },
                        }
                        workflow.nodes.push(newNode)
                    }
                }
            } catch (error) {
                console.error(error)
                return workflow
            }
            return workflow
        },

        async getWorkflowById(id: string) {
            const response = await this.workflowService.getOne(id)
            let workflow: Workflow = response.workflow
            if (!workflow) {
                return
            }
            workflow = await this.getEnrolledFromTriggers(id, workflow)
            this.workflow = workflow
        },

        async getOne(id: string) {
            const response = await this.workflowService.getOne(id)
            this.workflow = response.workflow
        },

        async getOneForPreview(id: string) {
            const response = await this.workflowService.getOne(id)
            this.previewWorkflow = response.workflow
        },

        async createWorkflow(workflow: Workflow) {
            const response = await this.workflowService.createWorkflow(workflow)
            if (!response) {
                return
            }
            this.toastService.addToast({
                message: "Workflow submitted successfully",
                type: "success",
            })
        },

        async updateWorkflow(id: string, workflow: Partial<Workflow>) {
            const response = await this.workflowService.updateWorkflow(id, workflow)
            if (!response) {
                return
            }
            this.toastService.addToast({
                message: "Workflow updated successfully",
                type: "success",
            })
        },

        async deleteWorkflow(id: string) {
            const response = await this.workflowService.deleteOne(id)
            if (!response) {
                return
            }
            this.toastService.addToast({
                message: "Workflow deleted successfully",
                type: "success",
            })
            this.tableState.data = this.tableState.data.filter((workflow) => workflow._id !== id)
        },

        resetWorkflowStore() {
            this.tableState = {
                data: [],
                selectedData: [],
                currentPage: 0,
                pageLength: 50,
                numberOfPages: 0,
                total: 0,
                isAllSelected: false,
            }
        },

        async autocomplete(search?: string, currentId?: string): Promise<Workflow[]> {
            return await this.workflowService.autocomplete(search, currentId)
        },

        clearFilters() {
            this.gridCollectionStore.grid.filtersGroups = []
            this.gridCollectionStore.filtersGroups = []

            this.updateWorkflowsGrid()
        },

        async getWorkflowsGrid() {
            try {
                this.tableState.isLoading = true
                const response = await this.gridCollectionStore.getGrid("workflows")

                await this.getWorkflows()
                return response
            } finally {
                this.tableState.isLoading = false
            }
        },

        async updateWorkflowsGrid() {
            try {
                this.tableState.isLoading = true
                if (!this.gridCollectionStore.grid) return
                this.gridCollectionStore.grid.filtersGroups = this.gridCollectionStore.filtersGroups || []

                this.gridCollectionStore.gridResponse = await this.gridCollectionStore.updateGrid(
                    "workflows",
                    this.gridCollectionStore.grid
                )
                this.gridCollectionStore.grid = this.gridCollectionStore.gridResponse.grid

                await this.getWorkflows()
            } finally {
                this.tableState.isLoading = false
            }
        },

        async checkWorkflowList(typename: string, entityIds: string[]) {
            try {
                return this.workflowService.checkWorkflowList(typename, entityIds)
            } catch (error) {
                const err = error as GgmsError
                if (err.code === "ValidationError") {
                    this.toastService.addToast({
                        type: "error",
                        message: err.message,
                    })
                }
            }
        },

        initializeValues() {
            const triggerId = nanoid()
            this.triggerNodeId = triggerId
            const newId = nanoid()
            const edgeId = nanoid()

            this.workflow = {
                name: "Untitled workflow",
                status: WorkflowStatus.off,
                nodes: [
                    {
                        id: triggerId,
                        position: { x: 0, y: 0 },
                        type: NodeType.trigger,
                        nextNodeId: newId,
                        nextEdgeId: edgeId,
                        data: {
                            toolbarVisible: true,
                            note: { text: "" },
                            showDeleteButton: false,
                        },
                    },
                    {
                        id: nanoid(),
                        type: NodeType.new,
                        position: { x: 0, y: 0 },
                        data: { newTrigger: true, note: { text: "" }, type: NodeType.new },
                    },
                    {
                        id: newId,
                        type: NodeType.new,
                        position: { x: 0, y: 0 },
                        data: { end: true, note: { text: "" }, type: NodeType.new },
                    },
                ],
                edges: [{ id: edgeId, source: triggerId, target: newId, type: "smoothstep" }],
                enrolledFrom: [],
            }

            this.areButtonDisabled = true
            this.disabledBranches = false
            this.isEditing = false
            this.isBranchCopied = false
            this.currentComponent = StartSidebarComponent
            this.isWorkflowEdited = false
            this.setHeaderText()
            this.branches = []
            this.noneBranch = { id: nanoid(), value: "Conditions not met" }
        },

        async getWorkflowsForWidget(contactId: string) {
            const response = await this.workflowService.getWorkflowsWidget(contactId)
            this.widgetWorkflows = response.data
        },

        async pauseWorkflow(workflowWidgetId: string) {
            const response = this.workflowService.pauseWorkflow(workflowWidgetId)
            if (!response) {
                return
            }
            this.toastService.addToast({
                message: "Workflow paused successfully",
                type: "success",
            })
        },

        async resumeWorkflow(workflowWidgetId: string) {
            const response = this.workflowService.resumeWorkflow(workflowWidgetId)
            if (!response) {
                return
            }
            this.toastService.addToast({
                message: "Workflow resumed successfully",
                type: "success",
            })
        },

        setHeaderText(isEdit?: boolean) {
            switch (this.currentComponent.__name) {
                case StartSidebarComponent.__name:
                    this.headerText = "Choose an Action"
                    this.backText = ""
                    this.showButtons = false
                    this.sidebarIcon = {}
                    break
                case EnrolmentTriggersSidebar.__name:
                    this.headerText = "Enrolment triggers"
                    this.backText = ""
                    this.showButtons = false
                    this.sidebarIcon = LightningBoltIcon
                    break
                case ContactActivityTriggerSidebar.__name:
                    this.headerText = "Trigger on contact activity"
                    this.backText = "Change trigger type"
                    this.showButtons = false
                    this.sidebarIcon = LightningBoltIcon
                    break
                case CMSListingActivityTriggerSidebar.__name:
                    this.headerText = "Trigger on listing activity"
                    this.backText = "Change trigger type"
                    this.showButtons = false
                    this.sidebarIcon = LightningBoltIcon
                    break
                case ScheduleTriggerSidebar.__name:
                    this.headerText = "Schedule trigger"
                    this.backText = "Change trigger type"
                    this.showButtons = false
                    this.sidebarIcon = LightningBoltIcon
                    break
                case CalendarDateScheduleComponent.__name:
                    this.headerText = "Calendar date schedule"
                    this.backText = "Change trigger type"
                    this.showButtons = true
                    this.sidebarIcon = LightningBoltIcon
                    break
                case WeekDaysScheduleComponent.__name:
                    this.headerText = "Week day schedule"
                    this.backText = "Change trigger type"
                    this.showButtons = true
                    this.sidebarIcon = LightningBoltIcon
                    break
                case TimeOfDayScheduleComponent.__name:
                    this.headerText = "Time of day schedule"
                    this.backText = "Change trigger type"
                    this.showButtons = true
                    this.sidebarIcon = LightningBoltIcon
                    break
                case MembershipChangedTriggerSidebar.__name:
                    this.headerText = "Membership changed"
                    this.backText = "Change trigger type"
                    this.showButtons = true
                    this.showDiscardButton = true
                    break
                case PropertyValueChangeTriggerSidebar.__name:
                    this.headerText = "Field value changed"
                    this.backText = "Change trigger type"
                    this.showButtons = true
                    this.showDiscardButton = true
                    break
                case SubmissionAndViewSidebar.__name:
                    this.headerText = this.isSubmission ? "Submission" : "View"
                    this.backText = "Change trigger type"
                    this.showButtons = true
                    this.showDiscardButton = true
                    break
                case EnrollAndUnenrollSidebar.__name:
                    this.headerText = this.isEnrollWorkflow ? "Enrolled in Workflow" : "Unenrolled from workflow"
                    this.backText = "Change trigger type"
                    this.showButtons = true
                    this.showDiscardButton = true
                    break
                case BranchSidebarComponent.__name:
                    this.backText = isEdit ? "" : "Change action"
                    this.headerText = "Branch"
                    this.showButtons = false
                    this.sidebarIcon = MergeIcon
                    break
                case DelaySidebarComponent.__name:
                    this.backText = isEdit ? "" : "Change action"
                    this.headerText = "Delay"
                    this.showButtons = false
                    this.sidebarIcon = ClockIcon
                    break
                case SinglePropertyComponent.__name:
                    this.backText = isEdit ? "" : "Change branch type"
                    this.headerText = "Based on single field"
                    this.showButtons = true
                    this.showDiscardButton = true
                    this.sidebarIcon = MergeIcon
                    break
                case MultiplePropertiesComponent.__name:
                    this.backText = isEdit ? "" : "Change branch type"
                    this.headerText = "Based on multiple object fields"
                    this.showButtons = true
                    this.showDiscardButton = true
                    this.sidebarIcon = MergeIcon
                    break
                case MultipleBranchesComponent.__name:
                    this.backText = isEdit ? "" : "Change branch type"
                    this.headerText = "Based on multiple branches filtering"
                    this.showButtons = true
                    this.showDiscardButton = true
                    this.sidebarIcon = MergeIcon
                    break
                case PercentageSplitComponent.__name:
                    this.backText = isEdit ? "" : "Change branch type"
                    this.headerText = "Based on percentage split"
                    this.showButtons = true
                    this.showDiscardButton = true
                    this.sidebarIcon = MergeIcon
                    break
                case CalendarDateComponent.__name:
                    this.backText = isEdit ? "" : "Change delay type"
                    this.headerText = "Calendar date"
                    this.showButtons = true
                    this.showDiscardButton = true
                    this.sidebarIcon = ClockIcon
                    break
                case DatePropertyComponent.__name:
                    this.backText = isEdit ? "" : "Change delay type"
                    this.headerText = "Date Field"
                    this.showButtons = true
                    this.showDiscardButton = true
                    this.sidebarIcon = ClockIcon
                    break
                case TimeAmountComponent.__name:
                    this.backText = isEdit ? "" : "Change delay type"
                    this.headerText = "Set amount of time"
                    this.showButtons = true
                    this.showDiscardButton = true
                    this.sidebarIcon = ClockIcon
                    break
                case WeekDaysComponent.__name:
                    this.backText = isEdit ? "" : "Change delay type"
                    this.headerText = "Days of week"
                    this.showButtons = true
                    this.showDiscardButton = true
                    this.sidebarIcon = ClockIcon
                    break
                case TimeOfDayComponent.__name:
                    this.backText = isEdit ? "" : "Change delay type"
                    this.headerText = "Time of day"
                    this.showButtons = true
                    this.showDiscardButton = true
                    this.sidebarIcon = ClockIcon
                    break
                case NoteComponent.__name:
                    this.backText = isEdit ? "" : "Change action"
                    this.headerText = "Note"
                    this.showButtons = true
                    this.showDiscardButton = true
                    this.sidebarIcon = AnnotationIcon
                    break
                case PropertyValueComponent.__name:
                    this.backText = isEdit ? "" : "Change action"
                    this.headerText = "Update Contact Record"
                    this.showButtons = true
                    this.showDiscardButton = true
                    this.sidebarIcon = ClipboardListIcon
                    break
                case ContactDistributionComponent.__name:
                    this.backText = "Change action"
                    this.headerText = "Contact Assignment"
                    this.showButtons = true
                    this.showDiscardButton = true
                    this.sidebarIcon = UserCircleIcon
                    break
                case SendEmailComponent.__name:
                    this.backText = isEdit ? "" : "Change action"
                    this.headerText = "Send Email"
                    this.showButtons = true
                    this.showDiscardButton = true
                    this.sidebarIcon = MailIcon
                    break
                case SendTextMessageComponent.__name:
                    this.backText = isEdit ? "" : "Change action"
                    this.headerText = "Send text message"
                    this.showButtons = true
                    this.showDiscardButton = true
                    this.sidebarIcon = ChatAltIcon
                    break
                case ApplyTagsComponent.__name:
                    this.backText = isEdit ? "" : "Change action"
                    this.headerText = "Add or remove tags"
                    this.showButtons = true
                    this.showDiscardButton = true
                    this.sidebarIcon = TagIcon
                    break
                case EnrollOrUnenrollWorkflowComponent.__name:
                    this.backText = isEdit ? "" : "Change action"
                    this.headerText = "Enroll or Unenroll from workflows"
                    this.showButtons = true
                    this.showDiscardButton = true
                    this.sidebarIcon = FireIcon
                    break
                case DeleteContactsComponent.__name:
                    this.backText = isEdit ? "" : "Change action"
                    this.headerText = "Delete contact"
                    this.showButtons = true
                    this.showDiscardButton = false
                    this.sidebarIcon = TrashIcon
                    break
                case CreateTaskSidebarComponent.__name:
                    this.backText = isEdit ? "" : "Change action"
                    this.headerText = "Create Task"
                    this.showButtons = true
                    this.sidebarIcon = ClipboardCheckIcon
                    break
                case MarketingPreferencesComponent.__name:
                    this.backText = isEdit ? "" : "Change action"
                    this.headerText = "Marketing Preferences"
                    this.showButtons = true
                    this.showDiscardButton = false
                    this.sidebarIcon = AdjustmentsIcon
                    break
                case DoNotDisturbComponent.__name:
                    this.backText = isEdit ? "" : "Change action"
                    this.headerText = "Do Not Disturb"
                    this.showButtons = true
                    this.showDiscardButton = false
                    this.sidebarIcon = DNDIcon
                    break
                default:
                    this.backText = ""
                    this.headerText = ""
                    this.showButtons = false
            }
        },

        getIcon(type: string) {
            switch (type) {
                case ActionNodeType.delay:
                    return ClockIcon
                case ActionNodeType.branch:
                    return MergeIcon
                case ActionNodeType.doNotDisturb:
                    return DNDIcon
                case ActionNodeType.marketingPreferences:
                    return AdjustmentsIcon
                case ActionNodeType.sendEmail:
                    return MailIcon
                case ActionNodeType.sendTextMessage:
                    return ChatAltIcon
                case ActionNodeType.tags:
                    return TagIcon
                case ActionNodeType.contactDistribution:
                    return UserCircleIcon
                case ActionNodeType.createTask:
                    return ClipboardCheckIcon
                case ActionNodeType.deleteContact:
                    return TrashIcon
                case ActionNodeType.enrollOrUnenroll:
                    return FireIcon
                case ActionNodeType.note:
                    return AnnotationIcon
                case ActionNodeType.propertyValue:
                    return ClipboardListIcon
                case NodeType.trigger:
                    return GreyTriggerIcon
                case NodeType.calendar:
                    return ClockIcon
                default:
                    return ""
            }
        },

        resetModelValues() {
            this.modelValue = {}
        },
    },
})
