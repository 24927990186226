/* eslint-disable vue/multi-word-component-names */
import { createApp } from "vue"
import "./index.css"
import App from "./App.vue"
import router from "./router"
import { createPinia } from "pinia"

// PrimeVue
import PrimeVue from "primevue/config"
import Dialog from "primevue/dialog"
import InputText from "primevue/inputtext"
import OverlayPanel from "primevue/overlaypanel"
import Button from "primevue/button"
import Avatar from "primevue/avatar"
import AvatarGroup from "primevue/avatargroup"
import Card from "primevue/card"
import Checkbox from "primevue/checkbox"
import Dropdown from "primevue/dropdown"
import DataTable from "primevue/datatable"
import Column from "primevue/column"
import InputMask from "primevue/inputmask"
import Tooltip from "primevue/tooltip"
import InputSwitch from "primevue/inputswitch"
import ColorPicker from "primevue/colorpicker"
import Sidebar from "primevue/sidebar"
import BadgeDirective from "primevue/badgedirective"
import Chips from "primevue/chips"
import MultiSelect from "primevue/multiselect"
import CascadeSelect from "primevue/cascadeselect"
import RadioButton from "primevue/radiobutton"
import Calendar from "primevue/calendar"
import TabView from "primevue/tabview"
import TabPanel from "primevue/tabpanel"
import Slider from "primevue/slider"
import ProgressBar from "primevue/progressbar"
import "primevue/resources/themes/tailwind-light/theme.css"
import "primevue/resources/primevue.min.css"

// Register quill modules
import Quill from "quill"
import "quill-emoji/dist/quill-emoji.css"
import ImageResize from "quill-image-resize"
import draggable from "vuedraggable"

// Vue Tel Input
import VueTelInput from "vue-tel-input"
import "vue-tel-input/vue-tel-input.css"

//Tanstack Query
import { VueQueryPlugin } from "@tanstack/vue-query"

/* eslint-disable */
const Emoji = require("quill-emoji")

const app = createApp(App)

app.use(VueTelInput)
app.use(PrimeVue)
app.use(VueQueryPlugin)

app.component("Dialog", Dialog)
app.component("InputText", InputText)
app.component("OverlayPanel", OverlayPanel)
app.component("Button", Button)
app.component("Avatar", Avatar)
app.component("AvatarGroup", AvatarGroup)
app.component("Card", Card)
app.component("Checkbox", Checkbox)
app.component("Dropdown", Dropdown)
app.component("DataTable", DataTable)
app.component("Column", Column)
app.component("InputMask", InputMask)
app.component("InputSwitch", InputSwitch)
app.component("ColorPicker", ColorPicker)
app.component("Sidebar", Sidebar)
app.component("draggable", draggable)
app.component("Chips", Chips)
app.component("MultiSelect", MultiSelect)
app.component("CascadeSelect", CascadeSelect)
app.component("RadioButton", RadioButton)
app.component("TabView", TabView)
app.component("TabPanel", TabPanel)
app.component("Slider", Slider)
app.component("ProgressBar", ProgressBar)
app.component("Calendar", Calendar)
app.directive("tooltip", Tooltip)
app.directive("badge", BadgeDirective)

app.use(createPinia())
app.use(router)

router.isReady().then(() => {
    app.mount("#app")
})

Quill.register("modules/emoji", Emoji)
Quill.register("modules/imageResize", ImageResize)
