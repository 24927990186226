<template>
    <div class="flex flex-col">
        <p class="text-lg leading-7 font-medium text-black pb-6">Field when contacts should move to the next action</p>
        <GgmsDropdown
            v-model="workflowStore.modelValue.date"
            class="w-full"
            :options="options"
            optionLabel="label"
            optionValue="value"
            placeholder="Select field"
        ></GgmsDropdown>
        <p class="pt-8 text-gray-800">Perform this action</p>
        <GgmsRadioSelect
            v-model="workflowStore.modelValue.event"
            :options="radioButtonsOptions"
            :buttonsInline="true"
        ></GgmsRadioSelect>
        <GgmsCalendar
            v-model="workflowStore.modelValue.time"
            position="below"
            class="pt-8"
            label="Time of day"
            :showTime="true"
            :showDate="false"
            :iconStart="ClockIcon"
        >
        </GgmsCalendar>
    </div>
</template>
<script setup lang="ts">
import { watch } from "vue"

import GgmsDropdown from "@/components/GgmsDropdown.vue"
import GgmsRadioSelect from "@/components/GgmsRadioSelect.vue"
import GgmsCalendar from "@/components/GgmsCalendar.vue"
import { ClockIcon } from "@heroicons/vue/outline"
import { useWorkflowStore } from "@/stores/workflow"

const workflowStore = useWorkflowStore()

const options = [{ label: "Last activity date", value: "Last activity date" }]
const radioButtonsOptions = [
    { displayName: "On date", value: "at" },
    { displayName: "After date", value: "after" },
    { displayName: "Before date", value: "before" },
]

watch(
    () => workflowStore.modelValue?.date,
    () => {
        if (workflowStore.modelValue?.date && workflowStore.modelValue?.time && workflowStore.modelValue?.event) {
            workflowStore.areButtonDisabled = false
        }
    }
)
watch(
    () => workflowStore.modelValue?.time,
    () => {
        if (workflowStore.modelValue?.date && workflowStore.modelValue?.time && workflowStore.modelValue?.event) {
            workflowStore.areButtonDisabled = false
        }
    }
)

watch(
    () => workflowStore.modelValue?.event,
    () => {
        if (workflowStore.modelValue?.date && workflowStore.modelValue?.time && workflowStore.modelValue?.event) {
            workflowStore.areButtonDisabled = false
        }
    }
)
</script>
