<template>
    <div class="flex flex-col gap-y-2">
        <p class="text-lg leading-7 font-medium text-gray-900">When following field value/s change:</p>
        <div v-for="prototype in prototypes" :key="prototype.typeName">
            <div v-for="category in getAttributesByCategories(prototype, [], true)" :key="category._id">
                <div class="flex gap-x-2 pt-3 cursor-pointer" @click="toggleGroup(category.name)">
                    <span class="text-base leading-6 font-semibold text-gray-700">{{ category.displayName }}</span>
                    <ArrowDown v-if="openGroups[category.name]" class="fill-gray-700"></ArrowDown>
                    <ArrowDown v-else class="rotate-180 fill-gray-700"></ArrowDown>
                </div>
                <transition name="fade">
                    <div v-if="openGroups[category.name]" class="flex flex-col pt-3">
                        <div
                            v-for="attribute in getFilteredAttributes(category.attributes)"
                            :key="attribute._id"
                            class="p-2.5 gap-x-2 text-sm leading-5 font-medium cursor-pointer hover:bg-gray-100 rounded-lg flex group"
                            @click="onSelect(attribute.name, attribute.displayName)"
                        >
                            <GgmsCheckBox
                                :checked="isValueChecked(attribute.name)"
                                :classes="['mt-1']"
                                :centered="false"
                            >
                                <div class="ml-3 text-sm leading-5 font-normal text-gray-900">
                                    {{ attribute.displayName }}
                                </div>
                            </GgmsCheckBox>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue"
import { useWorkflowStore } from "@/stores/workflow"
import { getAttributesByCategories } from "@/shared/utils/helpers"
import { useGridCollectionStore } from "@/stores/grid-collection"
import { Attribute } from "@/shared/models/attributes"
import GgmsCheckBox from "@/components/GgmsCheckBox.vue"
import ArrowDown from "@/components/icons/ArrowDown.vue"

const workflowStore = useWorkflowStore()
const gridCollectionStore = useGridCollectionStore()

const openGroups = ref<Record<string, boolean>>({})
const prototypes = computed(() => gridCollectionStore.prototypes)

function isValueChecked(value: string) {
    return workflowStore.selectedData
        ? !!(workflowStore.selectedData as Array<{ name: string; label: string }>).find((el) => el.name === value)
        : false
}

function onSelect(value: string, displayName: string) {
    if (workflowStore.selectedData) {
        if (isValueChecked(value)) {
            const index = (workflowStore.selectedData as Array<{ name: string; label: string }>).findIndex(
                (el) => el.name === value
            )
            if (index === -1) return
            workflowStore.selectedData.splice(index, 1)
            return
        }
        ;(workflowStore.selectedData as Array<{ name: string; label: string }>).push({
            name: value,
            label: displayName,
        })
    }
}

function getFilteredAttributes(attributes: Attribute[]) {
    return attributes
        .filter((attribute) => attribute.name !== "_id" && attribute.name !== "password")
        .sort((a, b) => a.displayName.localeCompare(b.displayName))
}

function toggleGroup(groupName: string) {
    openGroups.value[groupName] = !openGroups.value[groupName]
}

onMounted(async () => {
    gridCollectionStore.getPrototypesByPage("contacts")
    prototypes.value.forEach((prototype) => {
        const categories = getAttributesByCategories(prototype, [], true)
        categories.forEach((category) => {
            openGroups.value[category.name as string] = true
        })
    })
})

watch(
    () => workflowStore.selectedData,
    () => {
        workflowStore.areButtonDisabled = workflowStore?.selectedData?.length
            ? !workflowStore?.selectedData?.length
            : true
    },
    { deep: true }
)
</script>
