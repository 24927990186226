<template>
    <Button
        type="button"
        class="p-0 bg-transparent border border-transparent"
        v-tooltip.top="{ value: text, class: `${tooltipStyle}` }"
        :disabled="disabled"
        @click="onButtonClick"
    >
        <component
            :is="icon"
            :class="['w-5 h-5 stroke-gray-400 hover:stroke-gray-500', iconClass]"
            aria-hidden="true"
        />
    </Button>
</template>

<script lang="ts" setup>
import { Component, computed } from "vue"

interface Props {
    text: string
    icon: Component
    disabled?: boolean
    iconClass?: string
    small?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    small: false,
})

const emit = defineEmits(["onButtonClick"])

const tooltipStyle = computed(
    () => `font-medium text-xs px-3 py-2  ${props.small ? "min-w-[70px]" : "min-w-[85px] -translate-x-2"}`
)

function onButtonClick() {
    emit("onButtonClick")
}
</script>

<style scoped>
.tooltip-style .p-tooltip-text {
    font-weight: 500;
    font-size: 12px;
    background-color: #101828;
    padding: 8px 12px;
}

.p-disabled {
    cursor: not-allowed !important;
    pointer-events: visible !important;
}
</style>
