<template>
    <div :class="['flex flex-col justify-center items-center px-4 py-6 gap-y-3 relative', disabledClass]">
        <GgmsBasicEmailParts
            v-model:recipients="recipients"
            v-model:selectedSender="selectedSender"
            :isWorkflow="isWorkflow"
            type="sms"
        />
        <GgmsTextarea
            ref="messageTextArea"
            v-model="message"
            :template-string="emojis"
            placeholder="Write your text"
            :styleType="getStyleType()"
            :rows="11"
            :error-message="validationErrorMessage"
            :invalid="!!validationErrorMessage"
            :is-count-available="true"
            :number-of-recipients="recipients.length"
            :isForSMS="true"
            class="w-full pb-0.5"
            @disable-validation-error="disableValidationError"
            @sms-count-changed="setSmsCount"
            @cost-exceeded="setCostExceeded"
        />
        <div class="flex flex-col gap-y-2 w-full">
            <DNDWarningComponent
                :recipients="recipients"
                :showContactName="true"
                :showCheckbox="true"
            ></DNDWarningComponent>
            <MarketingPreferencesWarningComponent
                :recipients="recipients"
                :showContactName="true"
                :showCheckbox="true"
                type="sms"
            ></MarketingPreferencesWarningComponent>
        </div>

        <!-- Quota warning message -->
        <div v-if="remainingDailySentMessagesThreshold <= 1" class="flex w-full p-4 bg-yellow-50 rounded-md">
            <ExclamationIcon class="h-6 w-6 text-yellow-400 mr-3" />
            <p class="text-sm font-medium text-yellow-800">
                You are about to exceed your Agency daily sending limit of 100.
                <a href="#" class="underline underline-offset-[3px]"
                    >Set up a Business Profile to unlock a higher limit.</a
                >
            </p>
        </div>

        <div class="flex flex-row-reverse items-center justify-between w-full">
            <div class="flex items-center gap-x-4">
                <DocumentTextIcon
                    @click="isTemplatesModalOpen = true"
                    class="w-6 h-6 cursor-pointer text-gray-400 hover:text-gray-600"
                />
                <GgmsButton
                    v-if="isWorkflow"
                    :classes="['rounded-md']"
                    :disabled="isCreatingMessage"
                    @click="createMessage"
                    >Save</GgmsButton
                >
                <div v-else class="flex items-center gap-x-4">
                    <GgmsEmojiPicker @send-emoji="setEmojiInTextarea" />
                    <div class="flex">
                        <GgmsButton
                            :classes="['rounded-md rounded-r-none']"
                            :disabled="isCreatingMessage || costExceeded || isButtonDisabled"
                            @click="createMessage"
                            >Send Text Message</GgmsButton
                        >
                        <GgmsButton
                            :classes="['px-2 rounded-md rounded-l-none']"
                            :disabled="isButtonDisabled"
                            @click="openScheduleMessageModal"
                            ><CalendarIcon class="w-6 h-6 text-white"
                        /></GgmsButton>
                    </div>
                </div>
            </div>
        </div>
        <GgmsWarningBanner class-name="w-full" v-if="costExceeded">
            You don’t have enough points to send this message. Please top up first from the
            <span class="font-medium text-primary-color-500 cursor-pointer" @click="() => router.push(`/billing`)">
                Plans & Billing
            </span>
            section
        </GgmsWarningBanner>

        <MessageTemplatesModal
            :open="isTemplatesModalOpen"
            template-title="Text Message"
            @close-modal="isTemplatesModalOpen = false"
            @update:model-value="updateTemplate"
            size="3xl"
        />
    </div>

    <GgmsModal :open="isOpenScheduleMessageModal" @closeModal="closeScheduleMessageModal">
        <template #header>
            <div class="flex justify-start items-center">
                <div class="flex items-center justify-center mr-4 h-12 w-12 rounded-full bg-primary-color-100">
                    <CalendarIcon class="h-6 w-6 text-primary-color-600" />
                </div>
                <h1 class="text-lg leading-6 font-medium text-gray-900">Schedule Text Message</h1>
            </div>
        </template>
        <div v-for="schedule in scheduleOptions" :key="schedule.text">
            <div class="flex justify-between items-center cursor-pointer" @click="saveScheduleMessage(schedule.date)">
                <div class="flex items-center justify-center">
                    <ClockIcon class="h-5 w-5 text-primary-color-600 mr-3" />
                    <p class="text-base font-medium text-primary-color-600">{{ schedule.text }}</p>
                </div>
                <p class="text-sm text-gray-500">
                    {{ formatHumanReadableDate(schedule.date, true) }}
                </p>
            </div>
            <GgmsSeparator class="my-4" />
        </div>
        <div>
            <button class="flex items-center w-fit border-none bg-transparent" @click="toggleCustomSchedule">
                <CalendarIcon class="h-5 w-5 text-primary-color-600 mr-3" />
                <p class="text-base font-medium text-primary-color-600">Pick Date & Time</p>
            </button>
            <div v-if="isOpenCustomSchedule" class="flex gap-x-4 mt-2">
                <GgmsCalendar
                    v-model="customScheduleDate"
                    position="below"
                    :showTime="false"
                    :showDate="true"
                    minDate="today"
                    :iconStart="CalendarIcon"
                    class="w-full"
                >
                </GgmsCalendar>
                <GgmsCalendar
                    v-model="customScheduleTime"
                    position="below"
                    :minDate="minHour"
                    :showTime="true"
                    :showDate="false"
                    :iconStart="ClockIcon"
                    class="w-full"
                >
                </GgmsCalendar>
            </div>
        </div>
        <template v-if="isOpenCustomSchedule" #under>
            <div class="flex justify-end">
                <GgmsButton styleType="secondary" class="mr-3" @click="closeScheduleMessageModal">Cancel</GgmsButton>
                <GgmsButton :disabled="isScheduleButtonDisabled" @click="saveScheduleMessage()">Done</GgmsButton>
            </div>
        </template>
    </GgmsModal>
    <GgmsModal :open="isMessageLimitModalOpen" size="sm" @closeModal="closeMessageLimitModal">
        <template #header><div></div></template>
        <div class="flex flex-col justify-center items-center">
            <div class="flex items-center justify-center mr-4 h-12 w-12 rounded-full bg-yellow-100 mb-5">
                <ExclamationCircleIcon class="h-6 w-6 text-yellow-600" />
            </div>
            <h1 class="text-lg leading-6 font-medium text-gray-900 mb-2">Failed To Send Text Message</h1>
            <p class="text-center text-sm font-normal text-gray-500 mb-6">
                You have exceeded your Agency daily message sending limit of 100. Set up a Business Profile to unlock a
                higher limit
            </p>
            <GgmsButton styleType="primary" @click="closeMessageLimitModal" class="w-full"
                >Set Up Business Profile</GgmsButton
            >
        </div>
    </GgmsModal>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, provide, watch } from "vue"
import { useRoute, useRouter } from "vue-router"
import { CalendarIcon, DocumentTextIcon, ClockIcon, ExclamationCircleIcon } from "@heroicons/vue/outline"
import { ExclamationIcon } from "@heroicons/vue/solid"
import GgmsTextarea from "@/components/GgmsTextarea.vue"
import GgmsButton from "@/components/GgmsButton.vue"
import GgmsModal from "@/components/GgmsModal.vue"
import GgmsSeparator from "@/components/GgmsSeparator.vue"
import GgmsEmojiPicker from "@/components/GgmsEmojiPicker.vue"
import MessageTemplatesModal from "./message/MessageTemplatesModal.vue"
import GgmsBasicEmailParts from "@/components/GgmsBasicEmailParts.vue"
import GgmsWarningBanner from "@/components/GgmsWarningBanner.vue"
import DNDWarningComponent from "@/components/profile-details/do-not-disturb/DNDWarningComponent.vue"
import MarketingPreferencesWarningComponent from "@/components/profile-details/marketing-preferences/MarketingPreferencesWarningComponent.vue"
import GgmsCalendar from "@/components/GgmsCalendar.vue"
import { useAgencyStore } from "@/stores/agency"
import { useMessageStore } from "@/stores/message"
import { useConfigStore } from "@/stores/config"
import { useContactsStore } from "@/stores/contacts"
import { ToastService } from "@/shared/services/toast"
import { Message } from "@/shared/models/message"
import { Error } from "@/shared/models/error"
import { IntegrationName } from "@/shared/models/integration"
import { Phone } from "@/shared/models/phone"
import { Sender } from "@/shared/models/sender"
import { InboxContact } from "@/shared/models/inbox"
import { Contact } from "@/shared/models/contact"
import {
    setTomoroowMorning,
    setThisAfternoon,
    formatHumanReadableDate,
    getUser,
    getDisplayName,
    isToday,
    isEarlierToday,
} from "@/shared/utils/helpers"
import { useWorkflowStore } from "@/stores/workflow"
import { CountryCode } from "libphonenumber-js"

const router = useRouter()

const emit = defineEmits(["sendFromInbox", "saveTextMessage"])

interface Props {
    isWorkflow?: boolean
    hasEmails?: boolean
    hasTwilio?: boolean
    textMessage?: Message
    hasContact?: boolean
    hasSelectedContacts?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    isWorkflow: false,
    hasEmails: false,
    hasTwilio: false,
    hasContact: false,
    hasSelectedContacts: false,
})

const agencyStore = useAgencyStore()
const contactStore = useContactsStore()
const messageStore = useMessageStore()
const toastService = new ToastService()
const configStore = useConfigStore()
const workflowStore = useWorkflowStore()
const route = useRoute()

provide("contactOverlayTarget-to", "creatingMessage")
provide("contactOverlayTarget-cc", "creatingMsgCcField")
provide("contactOverlayTarget-bcc", "creatingMsgCcField")

const messageTextArea = ref()
const message = ref("")
const recipients = ref<InboxContact[]>([])

const agency = computed(() => agencyStore.agency)
const agencyPhoneNumber = computed(
    () => (getUser().outboundPhoneNumber || agency.value?.agencyOutboundPhoneNumber) as Phone
)
const isTemplatesModalOpen = ref(false)
const isOpenScheduleMessageModal = ref(false)
const isOpenCustomSchedule = ref(false)
const smsCount = ref(0)

const customScheduleDate = ref("")
const customScheduleTime = ref("")
const isScheduleButtonDisabled = computed(() => !customScheduleDate.value || !customScheduleTime.value)

const scheduleDate = ref<Date>()
const scheduleOptions = ref([
    {
        text: "Tomorrow morning",
        date: setTomoroowMorning(),
    },
])

const selectedSender = ref({
    name: agency.value.agencyName || "",
    phone: agencyPhoneNumber.value,
    type: "agency",
} as Sender)

const validationErrorMessage = computed(
    () => messageStore.validationError.fullMessage || messageStore.validationError.message
)
const twilioIntegration = computed(() =>
    configStore.integrations.find((i) => i.integrationName === IntegrationName.twilio)
)
const remainingDailySentMessagesLimit = computed(() => twilioIntegration.value?.remainingDailySentMessagesLimit || 500)
const remainingDailySentMessagesThreshold = computed(
    () => twilioIntegration.value?.remainingDailySentMessagesThreshold || 250
)
const isMessageLimitModalOpen = ref(false)
const emojis = ref("")
const isCreatingMessage = ref(false)

const disabledClass = computed(() =>
    isCreatingMessage.value ? "opacity-50 pointer-events-none cursor-not-allowed" : ""
)

const isButtonDisabled = computed(
    () =>
        (contactStore.haveAllDND ? !contactStore.sendAnywayDND : false) ||
        (contactStore.haveAllMP ? !contactStore.sendAnywayMP : false)
)
const isSelectedDateToday = ref(true)
const minHour = computed(() => (isSelectedDateToday.value ? "now" : "today"))

function getStyleType() {
    if (props.hasEmails && props.hasTwilio) return "emailAndPhone"
    if (props.hasEmails) return "onlyEmail"
    if (props.hasTwilio) return "onlyPhone"
    return "primary"
}

async function createMessage() {
    try {
        isCreatingMessage.value = true
        if (props.isWorkflow) {
            emit("saveTextMessage")
        }
        const to = recipients.value.map((contact) => {
            const fullNameParts = contact.fullName?.split(" ")
            const reversedParts = fullNameParts?.reverse() || []

            const lastName = reversedParts.shift()
            const firstName = reversedParts.reverse().join(" ") || ""

            return {
                firstName,
                lastName,
                phone: {
                    number: contact.phone?.number || "",
                    country: contact.phone?.country || "RO",
                },
                _id: contact._id,
            }
        })
        const messagePayload: Message = {
            body: message.value,
            plain: message.value,
            from: {
                name: selectedSender.value?.name || "",
                number: selectedSender.value.phone?.number || "",
                country: selectedSender.value.phone?.country || "",
            },
            to,
            smsCount: smsCount.value * (to.length > 0 ? to.length : 1),
            sendAnywayDND: contactStore.sendAnywayDND || false,
            sendAnywayMP: contactStore.sendAnywayMP || false,
        }
        if (!messagePayload?.to?.length && !props.isWorkflow) {
            toastService.addToast({
                message: "Unable to send message. There are no contacts to send the message to.",
                type: "error",
            })
            return
        }

        emit("saveTextMessage")
        if (twilioIntegration.value) {
            messagePayload.integration = {
                _id: twilioIntegration.value._id,
                integrationName: twilioIntegration.value.integrationName,
                integrationType: twilioIntegration.value.integrationType,
            }
        }

        if (scheduleDate.value) {
            messagePayload.scheduledAt = scheduleDate.value
        }
        if (!scheduleDate.value && remainingDailySentMessagesLimit.value <= 0) {
            isMessageLimitModalOpen.value = true
            return
        }

        const contactId = contactStore.contact._id
        if (contactId) {
            if (props.isWorkflow) {
                workflowStore.messagePayload = messagePayload
            } else {
                await messageStore.createMessage(messagePayload, contactStore.contact._id)
                emit("sendFromInbox")
            }
        } else {
            if (props.isWorkflow) {
                workflowStore.messagePayload = messagePayload
            } else {
                await messageStore.createMessage(messagePayload)
                emit("sendFromInbox")
            }
        }

        message.value = ""
        scheduleDate.value = undefined
        if (contactStore.contact?.phones) {
            addRecipient(contactStore.contact)
            return
        }
        recipients.value = []
    } finally {
        await agencyStore.loadAgency()
        isCreatingMessage.value = false
        emit("saveTextMessage")
    }
}

function openScheduleMessageModal() {
    isOpenScheduleMessageModal.value = true
    const afternoonSchedule = setThisAfternoon(scheduleOptions.value)
    if (afternoonSchedule) {
        scheduleOptions.value.push(afternoonSchedule)
    }
}

function closeScheduleMessageModal() {
    isOpenScheduleMessageModal.value = false
    isOpenCustomSchedule.value = false
    customScheduleDate.value = ""
    customScheduleTime.value = ""
}

function toggleCustomSchedule() {
    isOpenCustomSchedule.value = !isOpenCustomSchedule.value
}

function getCustomScheduledTime() {
    const date = new Date(customScheduleDate.value)
    const hours = Number(customScheduleTime.value.split(":")[0])
    const minutes = Number(customScheduleTime.value.split(":")[1])
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes)
}

function saveScheduleMessage(date?: Date) {
    scheduleDate.value = date || getCustomScheduledTime()

    createMessage()
    closeScheduleMessageModal()
}

function disableValidationError() {
    messageStore.validationError = {} as Error
}

function closeMessageLimitModal() {
    isMessageLimitModalOpen.value = false
}

function setEmojiInTextarea(emoji: string) {
    emojis.value = emoji
}

function updateTemplate(template: { subject: string; body: string }) {
    message.value = template.body
}

function addRecipient(contact: Contact, index?: number) {
    const recipient = {
        _id: contact._id,
        fullName: getDisplayName({
            firstName: contact.firstName,
            lastName: contact.lastName,
        }),
        doNotDisturb: contact?.doNotDisturb,
        marketingPreferences: contact?.marketingPreferences,
        phone: contact.phones?.[0],
    } as InboxContact

    if (!recipients.value.map((recipient) => recipient._id).includes(recipient._id) && recipient?.phone?.number) {
        index ? recipients.value.splice(index, 0, recipient) : recipients.value.push(recipient)
    }
}

function setSmsCount(count: number) {
    // eslint-disable-next-line no-console
    smsCount.value = count
}

//--------------------------------------------COST VALIDATION--------------------------------------------//

const costExceeded = ref(false)

function setCostExceeded(isExceeded: boolean) {
    costExceeded.value = isExceeded
}

//--------------------------------------------COST VALIDATION END----------------------------------------//

onMounted(() => {
    messageStore.timelineMessageTextAreaRef = messageTextArea?.value?.textarea
    if ((contactStore.contact.phones?.length && !props.isWorkflow && route.name !== "inbox") || props.hasContact) {
        addRecipient(contactStore.contact)
    }
    if ((!props.isWorkflow && route.name !== "inbox") || props.hasSelectedContacts) {
        if (contactStore.tableState.selectedData && contactStore.tableState.selectedData.length > 0) {
            contactStore.tableState.selectedData.forEach((contact) => {
                addRecipient(contact)
            })
        }
    }
    if (props.isWorkflow && props.textMessage) {
        message.value = props.textMessage.body
        if (props.textMessage?.from) {
            selectedSender.value = {
                name: props.textMessage?.from?.name || "",
                phone: {
                    number: props.textMessage?.from?.number,
                    country: props.textMessage?.from?.country as CountryCode,
                },
            }
        }
    }
})

watch(
    () => contactStore.contact.phones,
    () => {
        const contactRecipientIndex = recipients.value.findIndex(
            (recipient) => recipient._id === contactStore.contact._id
        )
        if (contactRecipientIndex < 0) {
            return
        }
        addRecipient(contactStore.contact, contactRecipientIndex)
    },
    { deep: true }
)

watch(
    () => customScheduleDate.value,
    () => {
        isSelectedDateToday.value = isToday(new Date(customScheduleDate.value))
        if (
            customScheduleDate.value &&
            customScheduleTime.value &&
            isEarlierToday(customScheduleDate.value, customScheduleTime.value)
        ) {
            customScheduleTime.value = ""
        }
    }
)
</script>
