<template>
    <div
        v-if="hasDND"
        class="flex bg-yellow-100 rounded-lg p-2 text-sm contacting-5 font-normal text-gray-800 items-center w-full justify-between"
    >
        <div class="flex gap-x-2 items-center">
            <DNDIcon class="w-4 h-4 stroke-[#92400E]"></DNDIcon>
            <p>
                <span v-if="recipients.length > 1">Some contacts have</span>
                <span v-else-if="showContactName"
                    ><b>{{ recipients[0].fullName || `${recipients[0].firstName} ${recipients[0].lastName}` }}</b>
                    has</span
                >
                Do Not Disturb enabled
                <span v-if="recipients.length === 1"
                    >until
                    <b>{{ formatHumanReadableDate(new Date(recipients[0].doNotDisturb?.endDate), false) }}</b></span
                >
            </p>
        </div>
        <GgmsCheckBox
            v-if="showCheckbox"
            v-model="contactStore.sendAnywayDND"
            label="Send anyway"
            @change="setSendAnyway"
        ></GgmsCheckBox>
    </div>
</template>
<script setup lang="ts">
import { defineProps, computed, watch } from "vue"
import { formatHumanReadableDate } from "@/shared/utils/helpers"
import DNDIcon from "@/components/icons/DNDIcon.vue"
import GgmsCheckBox from "@/components/GgmsCheckBox.vue"
import { useContactsStore } from "@/stores/contacts"
import { Contact } from "@/shared/models/contact"
import { InboxContact } from "@/shared/models/inbox"

const contactStore = useContactsStore()

interface Props {
    recipients: Contact[] | InboxContact[]
    showContactName?: boolean
    sendAnyway?: boolean
    showCheckbox?: boolean
}

const emit = defineEmits(["update:sendAnyway"])

const props = withDefaults(defineProps<Props>(), {
    showContactName: false,
    showCheckbox: false,
})

const hasDND = computed(() =>
    props.recipients.some((recipient) => {
        if (!recipient?.doNotDisturb) {
            return false
        }
        return (
            recipient?.doNotDisturb?.isEnabled &&
            new Date() >= new Date(recipient.doNotDisturb.startDate as Date) &&
            new Date() <= new Date(recipient.doNotDisturb.endDate as Date)
        )
    })
)

function setSendAnyway() {
    const recipients = props.recipients as Array<Contact> // Replace Contact with the specific type if it's known
    const currentDate = new Date()
    contactStore.haveAllDND = recipients.every((recipient: Contact) => {
        const { doNotDisturb } = recipient || {}
        return (
            doNotDisturb?.isEnabled &&
            currentDate >= new Date(doNotDisturb.startDate as Date) &&
            currentDate <= new Date(doNotDisturb.endDate as Date)
        )
    })
}

watch(
    () => props.recipients,
    () => setSendAnyway(),
    { deep: true }
)
</script>
