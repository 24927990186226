import { defineStore } from "pinia"
import { Collection, CollectionResponse } from "@/shared/models/collection"
import { CollectionService } from "@/shared/services/collection"
import { ToastService } from "@/shared/services/toast"
import { TableState } from "@/shared/models/table"

export const useCollectionStore = defineStore("collection", {
    state: () => ({
        collectionService: new CollectionService(),
        toastService: new ToastService(),
        collectionsResponse: [] as CollectionResponse[],
        selectedTabId: "",

        tableState: {
            data: [],
            selectedData: [],
            isAllSelected: false,
            currentPage: 0,
            pageLength: 50,
            numberOfPages: 1,
            total: 0,
            isLoading: false,
            type: undefined,
            agentIds: "",
        } as TableState<Collection>,
    }),
    actions: {
        async getCollections() {
            try {
                this.tableState.isLoading = true
                const queryParams: { [key: string]: string | number | undefined } = {
                    start: this.tableState.currentPage,
                    length: this.tableState.pageLength,
                    column: this.tableState.column,
                    order: this.tableState.order,
                    typeName: this.tableState.type,
                    ownerId: this.tableState.ownerId || undefined,
                    agentIds: this.tableState.agentIds || undefined,
                    search: this.tableState.search || undefined,
                }

                const response = await this.collectionService.getCollections(queryParams)
                this.tableState.selectedData = []
                this.tableState.data = response.data as Collection[]
                this.tableState.total = response.total
                this.tableState.numberOfPages = Math.ceil(response.total / this.tableState.pageLength)
            } finally {
                this.tableState.isLoading = false
            }
        },

        async searchCollections(searchText: string, type: string) {
            const response = await this.collectionService.search({ search: searchText, typeName: type })
            return response.data
        },

        async createCollection(data: Partial<Collection>) {
            try {
                this.tableState.isLoading = true
                const { collection } = await this.collectionService.createCollection(data)
                this.tableState.data.unshift(collection)

                this.toastService.addToast({
                    type: "success",
                    message: "List was successfully created",
                })
                return collection
            } catch (e) {
                const error = e as Error
                this.toastService.addToast({
                    type: "error",
                    message: error.message,
                })
            } finally {
                this.tableState.isLoading = false
            }
        },

        async updateCollection(collectionId: string, data: Partial<Collection>) {
            try {
                this.tableState.isLoading = true
                const { collection } = await this.collectionService.updateCollection(collectionId, data)
                const index = this.tableState.data.findIndex((collection) => collection._id === collectionId)
                if (index === -1) return
                this.tableState.data[index] = collection

                this.toastService.addToast({
                    type: "success",
                    message: "List was successfully updated",
                })
            } catch (e) {
                const error = e as Error
                this.toastService.addToast({
                    type: "error",
                    message: error.message,
                })
            } finally {
                this.tableState.isLoading = false
            }
        },

        async bulkUpdateCollections(data: Partial<Collection>[]) {
            await this.collectionService.bulkUpdateCollections(data)
        },

        async deleteCollection(collectionId: string) {
            try {
                this.tableState.isLoading = true
                const response: { collection: Collection } = await this.collectionService.deleteOne(
                    `collections/${collectionId}`
                )
                this.tableState.data = this.tableState.data.filter(
                    (collection) => collection._id !== response.collection._id
                )
            } catch (e) {
                const error = e as Error
                this.toastService.addToast({
                    type: "error",
                    message: error.message,
                })
            } finally {
                this.tableState.isLoading = false
            }
        },

        async bulkDeleteCollections() {
            try {
                this.tableState.isLoading = true
                const collectionIds = this.tableState.selectedData?.map((collection) => collection._id) || []
                const response = await this.collectionService.bulkDelete(collectionIds, !!this.tableState.isAllSelected)

                this.toastService.addToast({
                    type: "success",
                    message: `${response.deletedCount} list(s) successfully deleted`,
                })

                await this.getCollections()
            } catch (e) {
                const error = e as Error
                this.toastService.addToast({
                    type: "error",
                    message: error.message,
                })
            } finally {
                this.tableState.isLoading = false
            }
        },
    },
})
