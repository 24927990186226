<template>
    <div class="flex flex-col gap-y-4">
        <p class="text-lg leading-7 font-medium text-black">Update Contact Record</p>
        <GgmsDropdown
            v-model="modelValue.property"
            :options="options"
            optionLabel="displayName"
            optionValue="name"
            class="w-full text-ellipsis mb-1"
            panelClass="w-20 text-ellipsis"
            placeholder="Select field"
            @click="setNodeNotChanged()"
        ></GgmsDropdown>
        <div class="flex flex-col gap-y-3">
            <div class="flex items-center" @click="selectEvent('setTo')">
                <RadioButton v-model="modelValue.event" value="setTo" name="setTo"></RadioButton>
                <label for="setTo" class="text-sm font-medium text-gray-700 ml-2 cursor-pointer">Set to</label>
            </div>
            <component
                v-if="modelValue.event === 'setTo'"
                v-model="modelValue.value"
                :is="getComponentByType()"
                :type="inputFieldType"
                position="below"
                :showTime="false"
                :showDate="true"
                class="border-0 p-0"
                @click="setNodeNotChanged()"
            ></component>
            <div class="flex items-center" @click="selectEvent('clear')">
                <RadioButton
                    v-model="modelValue.event"
                    value="clear"
                    name="clear"
                    :disabled="modelValue.attribute?.isRequired"
                ></RadioButton>
                <label for="clear" :class="clearStyle">Clear</label>
            </div>
            <div class="flex items-center" @click="selectEvent('copy')">
                <RadioButton v-model="modelValue.event" value="copy" name="copy"></RadioButton>
                <label for="copy" class="text-sm font-medium text-gray-700 ml-2 cursor-pointer">Copy to</label>
            </div>
            <GgmsDropdown
                v-if="modelValue.event === 'copy'"
                v-model="modelValue.value"
                :options="copyOptions"
                optionLabel="displayName"
                optionValue="name"
                class="w-full text-ellipsis"
                @click="setNodeNotChanged()"
            ></GgmsDropdown>
        </div>
    </div>
</template>
<script setup lang="ts">
import { computed, onMounted, watch } from "vue"
import GgmsInput from "@/components/GgmsInput.vue"
import GgmsDropdown from "@/components/GgmsDropdown.vue"
import GgmsPhoneTelInput from "@/components/GgmsPhoneTelInput.vue"
import GgmsCalendar from "@/components/GgmsCalendar.vue"
import { useWorkflowStore } from "@/stores/workflow"
import { useGridCollectionStore } from "@/stores/grid-collection"
import { AttributeType } from "@/shared/models/attributes"

const workflowStore = useWorkflowStore()
const gridCollectionStore = useGridCollectionStore()

const modelValue = computed(() => workflowStore.modelValue)
const inputFieldType = computed(() => (modelValue.value.attribute ? modelValue.value.attribute?.type : "string"))
const prototypes = computed(() => {
    gridCollectionStore.getPrototypesByPage("contacts")
    return gridCollectionStore.prototypes
})
const clearStyle = computed(() => [
    modelValue.value.attribute?.isRequired ? "text-gray-300" : "text-gray-700",
    "text-sm font-medium ml-2 cursor-pointer",
])
const objectTypes = [AttributeType.String, AttributeType.Number, AttributeType.Date]
const options = computed(() =>
    prototypes.value[0].attributes
        .filter((att) => objectTypes.includes(att.type.toLowerCase()) && att.name !== "_id")
        .sort((a, b) => a.displayName.localeCompare(b.displayName))
)

const copyOptions = computed(() => {
    return prototypes.value[0].attributes
        .filter(
            (att) =>
                att.type === modelValue.value.attribute?.type &&
                att._id !== modelValue.value.attribute._id &&
                att.name !== "_id"
        )
        .sort((a, b) => a.displayName.localeCompare(b.displayName))
})

function getAttributeByName(name: string) {
    return prototypes.value[0].attributes.find((attribute) => attribute.name === name)
}

function getComponentByType() {
    if (!modelValue.value.attribute) {
        return GgmsInput
    }
    switch (modelValue.value.attribute?.type?.toLowerCase()) {
        case AttributeType.Date:
            return GgmsCalendar
        case AttributeType.Phones:
            return GgmsPhoneTelInput
        default:
            return GgmsInput
    }
}

function setNodeNotChanged() {
    workflowStore.changedNode = false
}

function selectEvent(event: string) {
    modelValue.value.event = event
    workflowStore.changedNode = false
}

onMounted(() => {
    gridCollectionStore.getPrototypesByPage("contacts")
})

watch(
    () => modelValue.value.event,
    () => {
        if (!workflowStore.changedNode) {
            modelValue.value.value = ""
        }
    }
)

watch(
    () => modelValue.value.property,
    () => {
        modelValue.value.attribute = getAttributeByName(modelValue.value.property)
        if (!workflowStore.changedNode) {
            modelValue.value.value = ""
            modelValue.value.event = ""
        }
    }
)

watch(
    () => [modelValue.value.property, modelValue.value.event, modelValue.value.value],
    () => {
        if (modelValue.value.event === "setTo" || modelValue.value.event === "copy") {
            workflowStore.areButtonDisabled = !(
                modelValue.value.property &&
                modelValue.value.event &&
                modelValue.value.value
            )
            if (modelValue.value.event === "copy") {
                modelValue.value.copyToAttribute = getAttributeByName(modelValue.value.value)
            }
        } else {
            workflowStore.areButtonDisabled = !(modelValue.value.property && modelValue.value.event)
        }
    }
)
</script>
