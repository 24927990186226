<template>
    <div
        v-if="hasMP"
        class="flex bg-red-100 rounded-lg p-2 text-sm contacting-5 font-normal text-gray-800 items-center w-full justify-between"
    >
        <div class="flex gap-x-2 items-center">
            <MarketingPreferencesIcon class="w-4 h-4"></MarketingPreferencesIcon>
            <p>
                <span v-if="recipients.length > 1">Some contacts have</span>
                <span v-else-if="showContactName"
                    ><b>{{ recipients[0].fullName || `${recipients[0].firstName} ${recipients[0].lastName}` }}</b>
                    has</span
                >
                {{ text }}
            </p>
        </div>
        <GgmsCheckBox
            v-if="showCheckbox"
            v-model="contactStore.sendAnywayMP"
            label="Send anyway"
            @change="setSendAnyway"
        ></GgmsCheckBox>
    </div>
</template>
<script setup lang="ts">
import { defineProps, computed, watch } from "vue"
import MarketingPreferencesIcon from "@/components/icons/MarketingPreferencesIcon.vue"
import GgmsCheckBox from "@/components/GgmsCheckBox.vue"
import { useContactsStore } from "@/stores/contacts"
import { Contact } from "@/shared/models/contact"
import { InboxContact } from "@/shared/models/inbox"

const contactStore = useContactsStore()

interface Props {
    recipients: Contact[] | InboxContact[]
    type: string
    showContactName?: boolean
    showCheckbox?: boolean
}

defineEmits(["update:sendAnyway"])

const props = withDefaults(defineProps<Props>(), {
    showContactName: false,
    showCheckbox: false,
})

const text = computed(() => (props.type === "sms" ? "Messages Marketing turned Off" : "Emails Marketing turned Off"))

const hasMP = computed(() =>
    props.recipients.some((recipient) => {
        if (!recipient?.marketingPreferences) {
            return false
        }

        return (
            (!recipient?.marketingPreferences?.isEnabledMessageManual && props.type === "sms") ||
            (!recipient?.marketingPreferences?.isEnabledEmailManual && props.type === "email")
        )
    })
)

function setSendAnyway() {
    const recipients = props.recipients as Array<Contact>
    contactStore.haveAllMP = recipients.every((recipient: Contact) => {
        const { marketingPreferences } = recipient || {}
        return (
            (!marketingPreferences?.isEnabledMessageManual && props.type === "sms") ||
            (!marketingPreferences?.isEnabledEmailManual && props.type === "email")
        )
    })
}

watch(
    () => props.recipients,
    () => setSendAnyway(),
    { deep: true }
)
</script>
