import { defineStore } from "pinia"
import { AgencyService } from "@/shared/services/agency"
import { Agency, AgencyDomain, ChildAgency, PayloadDomain } from "@/shared/models/agency"
import { TableState } from "@/shared/models/table"
import { ToastService } from "@/shared/services/toast"
import { UploadItem } from "@/shared/models/upload-file"
import { setCookie } from "@/shared/utils/helpers"

export const useAgencyStore = defineStore("agency", {
    state: () => ({
        tableState: {
            data: [],
            currentPage: 0,
            pageLength: 50,
            numberOfPages: 0,
            total: 0,
        } as TableState<ChildAgency>,
        agencyService: new AgencyService(),
        agency: {} as Agency,
        availableAgencies: [] as Agency[],
        agencyProfile: {} as ChildAgency,
        toastService: new ToastService(),
        isLoading: false,
        selectedAgency: {} as ChildAgency | Agency,
        selectedDomain: {} as AgencyDomain,
        selectedAgencyAndDomain: "",
        currentDomainConfiguration: {} as AgencyDomain,
    }),
    actions: {
        async loadAgency() {
            try {
                this.isLoading = true
                const response = await this.agencyService.getAgency()
                if (response && response.agency) {
                    this.agency = response.agency
                    setCookie("selectedAgency", JSON.stringify(this.agency), true)
                } else {
                    this.agency = {} as Agency
                }
            } finally {
                this.isLoading = false
            }
        },

        async updateAgency(agency: Partial<Agency>) {
            try {
                this.isLoading = true
                const response = await this.agencyService.updateAgency(agency)
                this.toastService.addToast({
                    type: "success",
                    message: "Settings were updated.",
                })
                if (response && response.agency) {
                    this.agency = response.agency
                } else {
                    this.agency = {} as Agency
                }
                return response
            } finally {
                this.isLoading = false
            }
        },

        async getChildAgencies() {
            try {
                this.isLoading = true
                const response = await this.agencyService.getChildAgencies()
                if (response && response.data) {
                    this.tableState.data = response.data
                    this.tableState.total = response.total | 0
                } else {
                    this.tableState.data = []
                    this.tableState.total = 0
                }
            } finally {
                this.isLoading = false
            }
        },

        async getAvailableAgencies() {
            try {
                this.isLoading = true
                const response = await this.agencyService.getAvailableAgencies()
                if (response && response.agencies) {
                    this.availableAgencies = response.agencies
                } else {
                    this.availableAgencies = []
                }
            } finally {
                this.isLoading = false
            }
        },

        async getAgencyProfile(id: string) {
            try {
                this.isLoading = true
                const { agency } = await this.agencyService.getAgencyProfile(id)
                if (agency) {
                    this.agencyProfile = agency
                }
            } finally {
                this.isLoading = false
            }
        },

        async getDNSConfig(id: string) {
            try {
                this.isLoading = true
                const { config } = await this.agencyService.getDNSConfig(id)
                this.currentDomainConfiguration = config
            } finally {
                this.isLoading = false
            }
        },

        async requestPostDataForFileUpload(uploads: UploadItem[], files: File[]) {
            const response = await this.agencyService.requestPostDataForFileUpload(uploads)
            let uploadedFiles = []
            if (response) {
                uploadedFiles = await Promise.all(
                    response.presignedPostUrls.map((file, index) => {
                        const formData = new FormData()
                        Object.entries(file.fields).forEach(([key, value]) => {
                            formData.append(key, value)
                        })
                        formData.append("file", files[index])
                        return this.agencyService.uploadFiles(file.url, formData)
                    })
                )
            }
            uploadedFiles = uploadedFiles.map((file) => file?.data)

            return uploadedFiles
        },

        // AGENCY DOMAINS
        async createDomain(domain: PayloadDomain) {
            const { agency } = await this.agencyService.createDomain(domain)
            if (!agency) {
                return
            }
            this.toastService.addToast({
                message: "Domain submitted successfully",
                type: "success",
            })
            this.agency = agency
        },

        async configureDomain(domainId: string) {
            const { success } = await this.agencyService.configureDomain(domainId)
            if (!success) {
                this.toastService.addToast({
                    message: "Unable to configure domain",
                    type: "error",
                })
                return
            }
            this.toastService.addToast({
                message: "Domain configured successfully",
                type: "success",
            })
        },

        async updateDomain(domainId: string, domain: PayloadDomain) {
            const { agency } = await this.agencyService.updateDomain(domainId, domain)
            if (!agency) {
                return
            }
            this.toastService.addToast({
                message: "Domain updated successfully",
                type: "success",
            })
            this.agency = agency
        },

        async deleteDomain(id: string) {
            const { agency } = await this.agencyService.deleteDomain(id)
            if (!agency) {
                return
            }
            this.toastService.addToast({
                message: "Domain deleted successfully",
                type: "success",
            })
            this.agency = agency
        },
    },
})
