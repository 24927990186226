<template>
    <div class="flex flex-col gap-y-4 mx-2 mt-1.5 mb-5">
        <!-- Collapsed State -->
        <div v-if="collapsed" class="flex items-center justify-center">
            <GgmsButton styleType="secondary" :classes="['px-2 py-1']" @click="toggleOverlay">
                <component :is="OfficeBuildingIcon" class="h-5 w-5 text-gray-500" />
            </GgmsButton>
        </div>

        <div v-if="!collapsed" class="flex items-center justify-between gap-x-2">
            <img v-if="agencyLogo" :src="agencyLogo" alt="agency-logo" class="max-w-[185px] max-h-6 m-auto" />
            <span v-else class="text-gray-600 text-xl leading-6 line-clamp-1"> {{ selectedAgency?.agencyName }}</span>
            <GgmsSearchDropdown
                :value="selectedAgency"
                :options="agencies"
                optionAttribute="agencyName"
                title="Select Agency"
                v-slot="{ toggle, visible }"
                @onSelect="changeAgency"
            >
                <GgmsButton styleType="secondary" :classes="['px-1 py-1']" :small="false" @click.stop="toggle">
                    <component
                        :is="visible ? ChevronUpIcon : ChevronDownIcon"
                        class="h-5 w-5 text-gray-500"
                    ></component>
                </GgmsButton>
            </GgmsSearchDropdown>
        </div>
        <GgmsDropdown
            v-if="!collapsed"
            :value="normalizedSelectedDomain"
            :options="domains"
            optionLabel="url"
            class="h-[38px] w-full agency-select-dropdown"
            panelClass="max-w-[310px] truncate text-sm font-normal text-gray-700"
            @onChange="changeDomainWithRedirect"
        >
            <template #customLabel="{ slotProps }">
                {{ getDomainHeader(slotProps.value) }}
            </template>
        </GgmsDropdown>

        <!-- Overlay Panel -->
        <OverlayPanel ref="overlayPanel" :dismissable="true" class="w-80 p-4">
            <div class="flex items-center justify-between gap-x-2">
                <img v-if="agencyLogo" :src="agencyLogo" alt="agency-logo" class="max-w-[185px] max-h-6 m-auto" />
                <span v-else class="text-gray-600 text-xl leading-6 line-clamp-1">
                    {{ selectedAgency?.agencyName }}
                </span>
                <GgmsSearchDropdown
                    :value="selectedAgency"
                    :options="agencies"
                    optionAttribute="agencyName"
                    title="Select Agency"
                    v-slot="{ toggle, visible }"
                    @onSelect="changeAgency"
                >
                    <GgmsButton styleType="secondary" :classes="['px-1 py-1']" :small="false" @click.stop="toggle">
                        <component
                            :is="visible ? ChevronUpIcon : ChevronDownIcon"
                            class="h-5 w-5 text-gray-500"
                        ></component>
                    </GgmsButton>
                </GgmsSearchDropdown>
            </div>

            <GgmsDropdown
                :value="normalizedSelectedDomain"
                :options="domains"
                optionLabel="url"
                class="h-[38px] w-full agency-select-dropdown mt-2"
                panelClass="max-w-[310px] truncate text-sm font-normal text-gray-700"
                @onChange="changeDomainWithRedirect"
            >
                <template #customLabel="{ slotProps }">
                    {{ getDomainHeader(slotProps.value) }}
                </template>
            </GgmsDropdown>
        </OverlayPanel>
    </div>
</template>

<script lang="ts" setup>
import { ref, computed, reactive, watch, onMounted } from "vue"
import GgmsSearchDropdown from "@/components/GgmsSearchDropdown.vue"
import GgmsButton from "@/components/GgmsButton.vue"
import GgmsDropdown from "@/components/GgmsDropdown.vue"
import OverlayPanel from "primevue/overlaypanel"
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/vue/outline"
import { useAgencyStore } from "@/stores/agency"
import { useAgencyThemeStore } from "@/stores/agency-theme"
import { useNavigationSidebarStore } from "@/stores/navigation-sidebar"
import { AgencyDomain, Agency, Url, DNSCheckStatus } from "@/shared/models/agency"
import { getCookieValue, isCustomDomain, setCookie } from "@/shared/utils/helpers"
import OfficeBuildingIcon from "@/components/icons/OfficeBuildingIcon.vue"

const agencyStore = useAgencyStore()
const agencyThemeStore = useAgencyThemeStore()
const navigationSidebarStore = useNavigationSidebarStore()

interface Props {
    collapsed: boolean
}

defineProps<Props>()

// State for showing/hiding the overlay
const overlayPanel = ref(null)

// Agencies
const selectedAgency = ref(getCookieValue("selectedAgency") ? JSON.parse(getCookieValue("selectedAgency") || "{}") : {})
const agencies = computed(() => agencyStore.availableAgencies)

// Domains
const allDomain = reactive({ _id: "all", url: "All domains" } as AgencyDomain)
const selectedDomain = ref(
    getCookieValue("selectedDomain")
        ? normalizeDomainForDropdown(JSON.parse(getCookieValue("selectedDomain")) || "{}")
        : structuredClone(allDomain)
)

const domains = computed(() => {
    const base =
        selectedAgency.value?.agencyDomains?.map((domain: AgencyDomain) => ({
            ...domain,
            url: (domain.url as Url)?.domain ?? domain.url,
        })) ?? []

    if (base.length > 1) {
        return [allDomain, ...base]
    }

    return base
})

// Used for the dropdown label display
const normalizedSelectedDomain = computed(() => {
    if (!selectedDomain.value) return null
    if (selectedDomain.value._id === "all") return allDomain

    return {
        ...selectedDomain.value,
        url: typeof selectedDomain.value.url === "object" ? selectedDomain.value.url.domain : selectedDomain.value.url,
    }
})

const agencyLogo = computed(() => agencyThemeStore.agencyTheme?.logo?.url || undefined)

function normalizeDomainForDropdown(domain: AgencyDomain): AgencyDomain {
    const normalized = structuredClone(domain)
    normalized.url = (domain.url as Url).domain // flatten to string
    return normalized
}

function saveSelectedAgencyToLocalStorage(value: Agency) {
    setCookie("selectedAgency", JSON.stringify(value), true)
}

function getDomainHeader(domain: AgencyDomain) {
    if (!domain) return ""
    if (domain._id === "all") {
        return `All domains (${selectedAgency.value?.agencyDomains?.length || 0})`
    }
    return (domain.url as Url)?.domain ?? domain.url
}

async function changeAgency(agency: Agency) {
    navigationSidebarStore.toggleSidebar(false)
    selectedAgency.value = structuredClone(agency)
    selectedDomain.value = {}
    saveSelectedAgencyToLocalStorage(selectedAgency.value)
    if (selectedAgency.value?.agencyDomains?.length === 1) {
        selectedDomain.value = structuredClone(selectedAgency.value.agencyDomains[0])
    } else {
        selectedDomain.value = structuredClone(allDomain)
    }
    agencyStore.selectedAgency = structuredClone(selectedAgency.value)
    await saveDomain()
    redirectOnSelect()
}

function changeDomain(domain: AgencyDomain) {
    if (domain._id === "all") {
        selectedDomain.value = structuredClone(allDomain)
    } else {
        console.log("[changeDomain] Changing domain to", domain)
        const fullDomain = selectedAgency.value?.agencyDomains?.find(
            (d) => (d.url as Url)?.domain === domain.url || d.url === domain.url
        )
        selectedDomain.value = fullDomain ? structuredClone(fullDomain) : structuredClone(domain)
    }

    saveDomain() // Always save the cookie after changing domain
}

async function changeDomainWithRedirect(domain: AgencyDomain) {
    changeDomain(domain)
    redirectOnSelect()
    saveDomain()
}

function toggleOverlay(event: Event) {
    if (overlayPanel.value) {
        overlayPanel.value.toggle(event)
    }
}

function saveDomain() {
    if (selectedDomain.value._id === "all") {
        setCookie("selectedDomain", JSON.stringify(allDomain), true)
    } else {
        const actualDomain = (selectedDomain.value.url as Url)?.domain ?? selectedDomain.value.url
        setCookie("selectedDomain", JSON.stringify({ ...selectedDomain.value, url: actualDomain }), true)
    }
    agencyStore.selectedDomain = structuredClone(selectedDomain.value)
    agencyStore.selectedAgencyAndDomain = `${selectedAgency.value?._id}${selectedDomain.value?._id}`
}

function redirectOnSelect() {
    if (!process.env.VUE_APP_BASE_WEB_URL || window.location.hostname === "localhost") {
        return
    }

    const baseUrl = new URL(process.env.VUE_APP_BASE_WEB_URL)

    // Special case: All domains always goes back to base URL
    if (selectedDomain.value._id === "all") {
        baseUrl.searchParams.set("selectedDomain", "all")
        return window.location.replace(baseUrl.href)
    }

    const actualDomain = (selectedDomain.value.url as Url)?.domain ?? selectedDomain.value.url

    const selectedAgencyDomain = selectedAgency.value.agencyDomains.find(
        (domain: AgencyDomain) => (domain.url as Url)?.domain === actualDomain || domain.url === actualDomain
    )

    if (!selectedAgencyDomain) {
        console.error(`[redirectOnSelect] Cannot find selected domain '${actualDomain}' in agency domains`)
        return
    }

    const isCustomDomain =
        !!selectedAgencyDomain?.url?.crm?.dns && selectedAgencyDomain?.url?.crm?.status === DNSCheckStatus.configured

    if (isCustomDomain) {
        const targetUrl = `https://${selectedAgencyDomain.url.crm.dns}`
        return window.location.replace(targetUrl)
    }

    baseUrl.hostname = `${actualDomain}.${baseUrl.hostname}`
    baseUrl.searchParams.set("selectedDomain", actualDomain)

    window.location.replace(baseUrl.href)
}

function redirectOnMounted() {
    if (!process.env.VUE_APP_BASE_WEB_URL) {
        return
    }

    const hostname = window.location.hostname
    const baseHostname = new URL(process.env.VUE_APP_BASE_WEB_URL).hostname

    // Handle 'All domains' case — always go back to base URL if needed.
    if (selectedDomain.value._id === "all" || selectedDomain.value.url === "All domains") {
        if (hostname !== baseHostname && !sessionStorage.getItem("alreadyRedirected")) {
            sessionStorage.setItem("alreadyRedirected", "true")
            window.location.replace(process.env.VUE_APP_BASE_WEB_URL)
        }
        return
    }

    if (isCustomDomain(hostname)) {
        console.log(`[redirectOnMounted] Already on custom domain (${hostname}), no auto-redirect.`)
        return
    }

    const currentSubdomain = hostname.split(".")[0]

    // Find matching domain in agencyDomains list
    const matchedDomain = selectedAgency.value?.agencyDomains?.find((domain: AgencyDomain) => {
        const actualDomain = (domain.url as Url)?.domain ?? domain.url
        return actualDomain === currentSubdomain
    })

    if (matchedDomain) {
        selectedDomain.value = structuredClone(matchedDomain)
        return
    }

    // If no match, force back to All Domains (base URL)
    if (selectedDomain.value._id !== "all") {
        redirectOnSelect()
    }
}

function handleDomainCRUD() {
    const domain = selectedAgency.value.agencyDomains.find(
        (agencyDomain: AgencyDomain) => agencyDomain._id === selectedDomain.value._id
    )

    if (!domain) {
        changeDomain(selectedAgency.value.agencyDomains[0])
        return
    }

    const oldDomain = (selectedDomain.value.url as Url)?.domain ?? selectedDomain.value.url
    const newDomain = (domain.url as Url)?.domain ?? domain.url

    if (oldDomain === newDomain) {
        return
    }

    changeDomain(domain)
}

onMounted(() => {
    const fromQuery = getDomainFromQuery()
    if (fromQuery) {
        if (fromQuery === "all") {
            selectedDomain.value = structuredClone(allDomain)
        } else {
            selectedDomain.value = findDomainObject(fromQuery) || structuredClone(allDomain)
        }
        saveDomain()
        return
    }

    if (["dev.crm.ggms.com", "staging.crm.ggms.com", "crm.ggms.com"].includes(window.location.hostname)) {
        const availableDomains = selectedAgency.value?.agencyDomains ?? []

        if (availableDomains.length === 1) {
            // Redirect immediately if only one domain is available
            selectedDomain.value = structuredClone(normalizeDomainForDropdown(availableDomains[0]))
            saveDomain()
            redirectOnSelect()
        } else {
            // Default to "All domains" if multiple domains exist
            selectedDomain.value = structuredClone(allDomain)
            saveDomain()
        }
        return
    }

    const cookieDomain = getCookieValue("selectedDomain")
        ? JSON.parse(getCookieValue("selectedDomain") || "{}")
        : structuredClone(allDomain)

    selectedDomain.value = normalizeDomainForDropdown(cookieDomain)

    if (isCustomDomain(window.location.hostname)) {
        const detected = findDomainFromHostname(window.location.hostname)
        if (detected) {
            selectedDomain.value = detected
            saveDomain() // critical — set the cookie after detecting
        }
    }

    if (selectedAgency.value?.agencyDomains?.length === 1) {
        selectedDomain.value = structuredClone(normalizeDomainForDropdown(selectedAgency.value.agencyDomains[0]))
    }

    if (Object.keys(selectedAgency.value).length) {
        agencyStore.selectedAgency = structuredClone(selectedAgency.value)
    }

    redirectOnMounted()
})

function getDomainFromQuery() {
    const params = new URLSearchParams(window.location.search)
    return params.get("selectedDomain")
}

function findDomainObject(domainUrl: string): AgencyDomain | undefined {
    return selectedAgency.value?.agencyDomains?.find((domain: AgencyDomain) => {
        const actualDomain = (domain.url as Url)?.domain ?? domain.url
        return actualDomain === domainUrl
    })
}

function findDomainFromHostname(hostname: string): AgencyDomain | undefined {
    return selectedAgency.value?.agencyDomains?.find((domain: AgencyDomain) => {
        const crmDns = (domain.url as Url)?.crm?.dns
        return crmDns === hostname
    })
}

watch(
    () => agencyStore.agency,
    async (value, oldValue) => {
        selectedAgency.value = structuredClone(value)
        if (!getCookieValue("selectedAgency") || getCookieValue("selectedAgency") === "{}") {
            saveSelectedAgencyToLocalStorage(selectedAgency.value)
            selectedDomain.value =
                selectedAgency.value?.agencyDomains?.length === 1
                    ? structuredClone(selectedAgency.value.agencyDomains[0])
                    : structuredClone(allDomain)

            await saveDomain()
            return
        }

        if (!oldValue) {
            return
        }

        handleDomainCRUD()
    },
    { immediate: true }
)
</script>

<style>
.agency-select-dropdown .p-dropdown-label {
    @apply text-sm font-medium text-gray-700;
}
</style>
