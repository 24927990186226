import { BaseModel } from "./base-model"
import { Phone } from "@/shared/models/phone"
import { UploadFile } from "./upload-file"
import { Role } from "./role"

export enum DNSCheckStatus {
    unconfigured = "unconfigured",
    pending = "pending",
    configured = "configured",
    failed = "failed",
}

export interface DNSConfig {
    withSubdomain?: string // e.g., https://livetampafied.dev.crm.ggms.com
    dns?: string // e.g., crm.livetampafied.com
    status?: DNSCheckStatus // Unconfigured, Pending, Success, Failed
    error?: string // Store DNS check error
    lastCheck?: Date // Track last poll attempt
}

export interface Url {
    scheme?: string
    domain: string
    isPollingEnabled?: boolean // True when polling is active
    pollingStartedAt?: Date // Track when polling started
    crm?: DNSConfig // CRM DNS info
    cms?: DNSConfig // CMS public site DNS info
    cmsAdmin?: DNSConfig // CMS Admin panel DNS info
    certificateArn?: string // ACM certificate ARN
    isCertificateIssued?: boolean
    crmDistributionId?: string // CloudFront distribution ID for CRM
    cmsDistributionId?: string // CloudFront distribution ID for CMS
}

export interface AgencyDomain extends BaseModel {
    url: string | Url
}

export interface Address {
    line1?: string
    line2?: string
    city?: string
    state?: string
    zipcode?: string
    areaCode?: string
    country?: string
}

export interface Agency extends BaseModel {
    agencyName: string
    agencyDomains: AgencyDomain[]
    parentsIds: string[]
    agencyPhoneNumber: Phone
    address?: Address
    agencyOutboundPhoneNumber?: Phone
    timezone?: string
    voiceAsset?: UploadFile
    billingInfo?: BillingInfo
    subscriptionPlan?: SubscriptionPlan
    currentPlan?: string
    seats?: AgencySeats
    points?: Points
}

export interface SubscriptionPlan {
    id: string
    cancel_at_period_end: boolean
    current_period_end: number
    current_period_start: number
    items: SubscriptionPlanItem[]
    status: string
    lastInvoice?: string
}
export interface SubscriptionPlanItem {
    id: string
    price: SubscriptionPlanItemPrice
    product: SubscriptionPlanItemProduct
    type: string
}
export interface SubscriptionPlanItemPrice {
    id: string
    amount: number
    currency: string
}
export interface SubscriptionPlanItemProduct {
    id: string
    name: number
}

export interface BillingInfo {
    name?: string
    address?: Address
    vat?: string
    email?: string
    phone?: Phone
}

export interface Points {
    balance?: number
    reloadWhenBellow?: number
    reloadWithProdId?: string
}

export interface ChildAgency extends BaseModel {
    agencyName: string
    agencyDomains: ChildAgencyDomain[]
    agencyOwners: ChildAgencyUser[]
    agents: ChildAgencyUser[]
    plan: string
}

export interface AgencySeats {
    total?: number
    used?: number
    additional?: number
    createdAt?: Date
    updatedAt?: Date
}

export interface ChildAgencyUser extends BaseModel {
    fullName: string
    role: Role
    email: string
    phoneNumber?: Phone
    profilePicture?: UploadFile
    inviteLink?: string
    inviteStatus?: string
}

export interface ChildAgencyDomain extends BaseModel {
    url: string
}

export interface PayloadDomain {
    url: {
        domain: string
    }
}

export interface Url {
    domain: string
    isPollingEnabled?: boolean // True when polling is active
    pollingStartedAt?: Date // Track when polling started
    crm?: DNSConfig // CRM DNS info
    cms?: DNSConfig // CMS public site DNS info
    cmsAdmin?: DNSConfig // CMS Admin panel DNS info
}

export interface DNSConfig {
    withSubdomain?: string // e.g., https://livetampafied.dev.crm.ggms.com
    dns?: string // e.g., crm.livetampafied.com
    status?: DNSCheckStatus // Unconfigured, Pending, Success, Failed
    error?: string // Store DNS check error
    lastCheck?: Date // Track last poll attem
}
