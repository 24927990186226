import { defineStore } from "pinia"
import { useGridCollectionStore } from "@/stores/grid-collection"
import { TableState } from "@/shared/models/table"
import { Log } from "@/shared/models/event-log"
import { EventLogService } from "@/shared/services/event-log"
import { ToastService } from "@/shared/services/toast"
import { GgmsError } from "@/shared/services/client"

export const useEventLogStore = defineStore("event-log", {
    state: () => ({
        tableState: {
            data: [],
            selectedData: [],
            currentPage: 0,
            pageLength: 50,
            numberOfPages: 0,
            total: 0,
            isAllSelected: false,
            isLoading: false,
        } as TableState<Log>,
        eventLogService: new EventLogService(),
        toastService: new ToastService(),
        gridCollectionStore: useGridCollectionStore(),
    }),
    actions: {
        async getEventLogs() {
            try {
                this.tableState.isLoading = true

                let lastId = null
                let offset = null

                // If we're going to the next page, use keyset pagination.
                if (this.tableState.currentPage === (this.tableState?.lastPage ?? 0) + 1) {
                    lastId =
                        this.tableState.data.length > 0
                            ? this.tableState.data[this.tableState.data?.length - 1]._id
                            : null
                }
                // If we're jumping to a specific page, use offset-based pagination.
                else if (
                    this.tableState.currentPage !== (this.tableState?.lastPage ?? 0) + 1 &&
                    this.tableState.currentPage > 1
                ) {
                    offset = (this.tableState.currentPage - 1) * this.tableState.pageLength
                }

                const filtersGroups = this.gridCollectionStore.grid.filtersGroups.length
                    ? encodeURIComponent(JSON.stringify(this.gridCollectionStore.grid.filtersGroups))
                    : undefined

                const response = await this.eventLogService.getAll({
                    length: this.tableState.pageLength,
                    column: this.tableState.column,
                    order: this.tableState.order,
                    search: this.tableState.search,
                    filtersGroups,
                    lastId,
                    offset,
                })

                if (!response?.data?.length) {
                    this.resetTableState()
                    return
                }

                // Store the first ID of the new page.
                if (this.tableState.firstIds && this.tableState.currentPage) {
                    this.tableState.firstIds[this.tableState.currentPage - 1] = response.data[0]._id as string
                }

                this.tableState.selectedData = []
                this.tableState.data = response.data
                this.tableState.total = response.total
                this.tableState.numberOfPages = Math.ceil(this.tableState.total / this.tableState.pageLength)
                this.tableState.lastPage = this.tableState.currentPage
            } finally {
                this.tableState.isLoading = false
            }
        },
        async getPointsUsageLogs() {
            try {
                this.tableState.isLoading = true

                let lastId = null
                let offset = null

                // If we're going to the next page, use keyset pagination.
                if (this.tableState.currentPage === (this.tableState?.lastPage ?? 0) + 1) {
                    lastId =
                        this.tableState.data.length > 0
                            ? this.tableState.data[this.tableState.data?.length - 1]._id
                            : null
                }
                // If we're jumping to a specific page, use offset-based pagination.
                else if (
                    this.tableState.currentPage !== (this.tableState?.lastPage ?? 0) + 1 &&
                    this.tableState.currentPage > 1
                ) {
                    offset = (this.tableState.currentPage - 1) * this.tableState.pageLength
                }

                const filtersGroups = this.gridCollectionStore.grid.filtersGroups.length
                    ? encodeURIComponent(JSON.stringify(this.gridCollectionStore.grid.filtersGroups))
                    : undefined

                const response = await this.eventLogService.getAll(
                    {
                        length: this.tableState.pageLength,
                        column: this.tableState.column,
                        order: this.tableState.order,
                        search: this.tableState.search,
                        filtersGroups,
                        lastId,
                        offset,
                    },
                    true
                )

                if (!response?.data?.length) {
                    this.resetTableState()
                    return
                }

                // Store the first ID of the new page.
                if (this.tableState.firstIds && this.tableState.currentPage) {
                    this.tableState.firstIds[this.tableState.currentPage - 1] = response.data[0]._id as string
                }

                this.tableState.selectedData = []
                this.tableState.data = response.data
                this.tableState.total = response.total
                this.tableState.numberOfPages = Math.ceil(this.tableState.total / this.tableState.pageLength)
                this.tableState.lastPage = this.tableState.currentPage
            } finally {
                this.tableState.isLoading = false
            }
        },

        resetTableState() {
            this.tableState.data = []
            this.tableState.total = 0
            this.tableState.selectedData = []
            this.tableState.numberOfPages = Math.ceil(1)
        },

        async getEventLogGrid() {
            try {
                this.tableState.isLoading = true
                const response = await this.gridCollectionStore.getGrid("logs")
                const columnIndex = response.data[0].columns.findIndex((column) => column.attribute === "pointCost")
                response.data[0].columns.splice(columnIndex, 1)
                await this.getEventLogs()

                return response
            } finally {
                this.tableState.isLoading = false
            }
        },

        async getPointsUsageGrid() {
            try {
                this.tableState.isLoading = true
                const response = await this.gridCollectionStore.getGrid("logs")
                await this.getPointsUsageLogs()

                return response
            } finally {
                this.tableState.isLoading = false
            }
        },

        async updateEventLogsGrid() {
            try {
                this.tableState.isLoading = true
                if (!this.gridCollectionStore.grid) return
                this.gridCollectionStore.grid.filtersGroups = this.gridCollectionStore.filtersGroups || []

                const response = await this.gridCollectionStore.updateGrid("logs", this.gridCollectionStore.grid)
                this.gridCollectionStore.gridResponse = response
                this.gridCollectionStore.grid = this.gridCollectionStore.gridResponse.grid

                await this.getEventLogs()
            } finally {
                this.tableState.isLoading = false
            }
        },

        async updateEventLog(id: string, eventLog: Partial<Log>) {
            try {
                this.tableState.isLoading = true
                this.tableState.error = {} as GgmsError
                const responseLog = await this.eventLogService.updateEventLog(id, eventLog)

                const updatedLogIndex = this.tableState.data.findIndex((log) => log._id === id)
                if (updatedLogIndex !== -1) {
                    this.tableState.data[updatedLogIndex].status = responseLog.status
                    this.toastService.addToast({
                        type: "success",
                        message: `${responseLog.shortDescription} updated.`,
                    })
                }
            } catch (error) {
                this.tableState.error = error as GgmsError
            } finally {
                this.tableState.isLoading = false
            }
        },

        async checkEventLogList(typename: string, contactIds: string[]) {
            try {
                return this.eventLogService.checkEventLogList(typename, contactIds)
            } catch (error) {
                const err = error as GgmsError
                if (err.code === "ValidationError") {
                    this.toastService.addToast({
                        type: "error",
                        message: err.message,
                    })
                }
            }
        },
    },
})
