<template>
    <div>
        <div :class="[getWrapperStyleInline()]">
            <div class="flex" :class="getRequiredStyleInline">
                <p
                    v-if="label || inline"
                    :for="name"
                    class="text-sm font-medium text-gray-700"
                    :class="getInlineLabelStyle()"
                >
                    {{ label }}
                </p>
                <span v-if="required && showRequired" :class="starStyle">*</span>
            </div>

            <p v-if="description" class="text-sm text-gray-500 my-4">
                {{ description }}
            </p>
            <div :class="getInputWrapperStyleLabel()">
                <slot name="input">
                    <div
                        v-if="iconStart"
                        class="absolute inset-y-0 left-0 pl-3 flex items-center"
                        :class="getIconsDisabledClass()"
                        @click="$emit('clickIconStart')"
                    >
                        <component :is="iconStart" class="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                    <input
                        ref="inputField"
                        :value="modelValue"
                        :id="name"
                        :name="name"
                        :type="type"
                        :placeholder="placeholder"
                        :autocomplete="autocomplete"
                        :disabled="disabled || readonly"
                        :maxlength="maxLength"
                        :class="readonly ? readonlyStyle : inputStyle"
                        @input="onChangeInput"
                        @blur="showErrors = true"
                    />
                    <div
                        v-if="iconEnd || localInvalid"
                        :class="[getIconsDisabledClass(), exclamationMarkStyle]"
                        @click="$emit('clickIconEnd')"
                    >
                        <component
                            v-if="localInvalid && showErrors"
                            :is="iconEnd || ExclamationCircleIcon"
                            :class="[localInvalid && showErrors ? 'text-red-600' : 'text-gray-400', 'h-5 w-5']"
                            aria-hidden="true"
                        />
                    </div>
                </slot>
                <GgmsTooltip
                    v-if="showCopyButton"
                    text="Copy Text"
                    :icon="DuplicateIcon"
                    class="ml-1"
                    @click="copyToClipboard(modelValue as string)"
                />
            </div>

            <div v-if="inline" class="hidden lg:block"></div>
        </div>

        <p v-if="localErrorMessage && showErrors" class="mt-2 text-sm text-red-600">{{ localErrorMessage }}</p>
    </div>
</template>

<script lang="ts" setup>
import { Component, computed, onMounted, ref, watch, watchEffect, withDefaults } from "vue"
import GgmsTooltip from "@/components/GgmsTooltip.vue"
import { ExclamationCircleIcon } from "@heroicons/vue/solid"
import { DuplicateIcon } from "@heroicons/vue/outline"
import { copyToClipboard } from "@/shared/utils/helpers"

const emit = defineEmits(["update:modelValue", "clickIconStart", "clickIconEnd"])

interface Props {
    modelValue?: string | number
    label?: string
    description?: string
    name?: string
    type?: string
    required?: boolean
    autocomplete?: string
    placeholder?: string
    iconStart?: Component
    iconEnd?: Component
    inline?: boolean
    invalid?: boolean
    small?: boolean
    errorMessage?: string
    styleType?: "primary" | "secondary"
    disabled?: boolean
    textAlign?: string
    inputClass?: string
    wrapperClass?: string
    isAvailableDateFromPast?: boolean
    templateString?: string
    readonly?: boolean
    isFocusExposed?: boolean
    maxLength?: number
    showRequired?: boolean
    showCopyButton?: boolean
    fullWidth?: boolean
    overwriteShowErrors?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    type: "text",
    styleType: "primary",
    required: false,
    small: false,
    disabled: false,
    textAlign: "start",
    invalid: false,
    inputClass: "",
    isAvailableDateFromPast: false,
    templateString: "",
    showRequired: true,
    showCopyButton: false,
    fullWidth: true,
    overwriteShowErrors: false,
})

const showErrors = ref(true)
const localErrorMessage = ref(props.errorMessage)
const localInvalid = ref(props.invalid)

const baseStyle = computed(() => [
    props.iconStart ? "pl-10" : "",
    props.iconEnd ? "pr-10" : "",
    props.inline ? "w-80" : "",
    props.small ? "text-sm" : "text-base",
    props.fullWidth ? "w-full" : "",
    "appearance-none block px-3 py-2 border rounded-md shadow-sm",
    props.inputClass,
    `text-${props.textAlign}`,
])

const inputStyle = computed(() => [
    ...baseStyle.value,
    props.disabled ? "opacity-50 cursor-not-allowed" : "",
    props.styleType === "secondary" ? "bg-gray-100 border-none focus:border-gray-500" : "",
    localInvalid.value && showErrors.value
        ? "pr-10 border-red-300 placeholder-red-300 focus:ring-red-500 focus:border-red-500"
        : "placeholder-gray-400 focus:ring-primary-color-500 focus:border-primary-color-500 border-gray-300 ",
    "focus:outline-none",
])

const readonlyStyle = computed(() => [
    ...baseStyle.value,
    "opacity-50 cursor-not-allowed",
    props.styleType === "secondary" ? "bg-gray-200 border-none" : "bg-gray-100",
    "border-gray-300 placeholder-gray-400",
])

const starStyle = computed(() => [localInvalid.value ? "text-red-600" : "text-gray-700", "pl-1"])
const getRequiredStyleInline = computed(() => (props.inline ? "gap-2" : ""))
const exclamationMarkStyle = computed(() => [
    props.showCopyButton ? "pr-8" : "pr-3",
    "absolute inset-y-0 right-0 flex items-center",
])

const inputField = ref()

function getInlineLabelStyle() {
    return [props.inline ? "w-2/5" : "", "h-6"]
}

function getWrapperStyleInline() {
    return [props.inline ? "flex items-center gap-4" : "", props.wrapperClass]
}

function getInputWrapperStyleLabel() {
    return [props.label && !props.inline ? "mt-1" : "", "relative flex items-center justify-center"]
}

function getIconsDisabledClass() {
    return props.disabled ? "cursor-default" : "cursor-pointer"
}

function disableValidationError() {
    localErrorMessage.value = ""
    localInvalid.value = false
    if (props.overwriteShowErrors) {
        showErrors.value = false
    }
}

function onChangeInput() {
    disableValidationError()
    emit("update:modelValue", inputField.value.value)
}

function onSelectInsertString(templateString: string) {
    const cursorPosition = inputField.value.selectionStart
    const textAreaValue = inputField.value.value
    const textBefore = textAreaValue.substring(0, cursorPosition)
    const textAfter = textAreaValue.substring(cursorPosition, textAreaValue.length)
    inputField.value.value = `${textBefore}${templateString}${textAfter}`
    inputField.value.focus()
    inputField.value.selectionStart = cursorPosition + templateString.length
    inputField.value.selectionEnd = cursorPosition + templateString.length
    emit("update:modelValue", inputField.value.value)
}

function focusInputElement() {
    inputField.value.focus()
}

onMounted(() => {
    disableValidationError()
    //check if datepicker is open
    if (props.type !== "date" || !props.isFocusExposed) {
        return
    }
    inputField.value?.addEventListener("focus", () => {
        try {
            inputField?.value?.showPicker()
        } catch (error) {
            console.error(error)
        }
    })
})

watchEffect(() => {
    if (props.type === "date" && inputField.value && !props.isAvailableDateFromPast) {
        inputField.value.min = new Date().toISOString().split("T")[0]
    }
})

watch(
    () => props.errorMessage,
    (newVal) => {
        localErrorMessage.value = newVal
        localInvalid.value = props.invalid
    }
)

watch(
    () => props.invalid,
    () => {
        localInvalid.value = props.invalid
    }
)

watch(
    () => props.templateString,
    (val) => {
        onSelectInsertString(val)
    }
)

watch(
    () => props.modelValue,
    () => {
        if (!props.modelValue && props.invalid) {
            localErrorMessage.value = props.errorMessage
            localInvalid.value = props.invalid
        }
    }
)

defineExpose({
    focusInputElement,
})
</script>

<style scoped>
input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

input[type="time"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

input[type="date"],
input[type="time"] {
    /* Fix issue on safari */
    max-height: 42px;
}

/* This classes applies hiding number type input's arrow indicators */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
</style>
