<template>
    <div class="flex flex-col gap-y-6">
        <p class="text-lg contacting-7 font-medium text-black">Days of the week workflow should trigger</p>
        <GgmsMultiSelect
            v-model="workflowStore.modelValue.days"
            :options="daysOptions"
            placeholder="Choose day or days"
            classes="w-full"
        ></GgmsMultiSelect>
        <GgmsCalendar
            v-model="workflowStore.modelValue.time"
            position="below"
            label="Select time"
            :showTime="true"
            :showDate="false"
            :iconStart="ClockIcon"
        >
        </GgmsCalendar>
    </div>
</template>
<script setup lang="ts">
import { watch } from "vue"
import { ClockIcon } from "@heroicons/vue/outline"
import { useWorkflowStore } from "@/stores/workflow"
import { dateTimeConverter } from "@/shared/utils/helpers"
import GgmsMultiSelect from "@/components/GgmsMultiSelect.vue"
import GgmsCalendar from "@/components/GgmsCalendar.vue"

const workflowStore = useWorkflowStore()

const daysOptions = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]

watch(
    () => workflowStore.modelValue.time,
    () => {
        //convert time into ISO date
        if (workflowStore.modelValue.time) {
            workflowStore.modelValue.dateTime = dateTimeConverter(workflowStore.modelValue.time)
        }
    }
)

watch(
    () => [workflowStore.modelValue.days, workflowStore.modelValue.time],
    () => {
        workflowStore.areButtonDisabled = !workflowStore.modelValue.days.length || !workflowStore.modelValue.time
    }
)
</script>
