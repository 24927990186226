<template>
    <div class="flex flex-col">
        <GgmsRadioSelect
            v-model="workflowStore.modelValue.event"
            label="Distribution Style"
            :options="distributionOptions"
        >
        </GgmsRadioSelect>
        <hr class="bg-gray-200 mt-5 mb-4 w-[445px] -mx-6" />

        <div id="specificTypeDropdown">
            <div class="flex justify-center items-center gap-x-2 cursor-pointer w-fit" @click="toggleTypeMenu">
                <p class="text-lg leading-7 font-medium text-black">{{ selectionTitle }}</p>
                <div>
                    <ArrowDown v-show="isTypeModalOpen" class="rotate-180 fill-gray-700"></ArrowDown>
                    <ArrowDown v-show="!isTypeModalOpen" class="fill-gray-700"></ArrowDown>
                </div>
            </div>
            <OverlayPanel
                ref="typeMenu"
                appendTo="#specificTypeDropdown"
                class="bg-white rounded-md !left-0 ring-1 ring-black ring-opacity-5 shadow-lg mx-6 right-1 w-64 top-0"
            >
                <div class="flex flex-col cursor-pointer">
                    <div class="flex justify-between border-b border-gray-200 p-3 items-center">
                        <div @click="selectType('agent')">Specific team member/s</div>
                        <CheckIcon v-if="workflowStore.modelValue.value === 'agent'" class="w-4 h-4"></CheckIcon>
                    </div>
                    <div class="flex justify-between p-3 items-center">
                        <div @click="selectType('list')">List/s team members</div>
                        <CheckIcon v-if="workflowStore.modelValue.value === 'list'" class="w-4 h-4"></CheckIcon>
                    </div>
                </div>
            </OverlayPanel>
        </div>
        <p class="text-sm leading-5 font-normal text-gray-500 pt-2">
            Assign enrolled contacts to the selected {{ selectionDescription }}.
        </p>
        <div id="searchOverlayTarget">
            <GgmsInput
                v-model="searchString"
                class="w-full mt-4"
                :iconStart="SearchIcon"
                placeholder="Search"
                @input="toggleSearchMenu"
                @click="toggleSearchMenu"
            ></GgmsInput>
            <OverlayPanel
                ref="searchMenu"
                class="!left-0 ring-1 ring-black ring-opacity-5 shadow-lg rounded-md bg-white mx-6 right-1"
                appendTo="#searchOverlayTarget"
            >
                <div class="flex flex-col max-h-64 overflow-auto w-full top-36">
                    <div class="relative">
                        <div v-if="!data?.length" class="py-6 border-0 px-4">No results found</div>
                        <template v-else>
                            <div
                                v-for="element in data"
                                :key="element._id"
                                class="flex items-center gap-x-2 px-4 py-3 cursor-pointer border-b border-gray-200 last:border-0"
                                tabindex="0"
                            >
                                <GgmsCheckBox :checked="isElementChecked(element)" @click="onClick(element)">
                                    <div class="ml-3 flex items-center gap-x-2">
                                        <ProfilePicture
                                            v-if="workflowStore.modelValue.value === 'agent'"
                                            :image="element?.profilePicture"
                                            :fullName="element?.fullName || ''"
                                        ></ProfilePicture>
                                        <ListIcon v-else></ListIcon>

                                        <div
                                            class="min-w-[32px] min-h-[32px] rounded-full items-center justify-center flex"
                                        >
                                            {{
                                                workflowStore.modelValue.value === ContactDistributionNodeType.agent
                                                    ? element?.fullName
                                                    : element?.displayName
                                            }}
                                        </div>
                                    </div>
                                </GgmsCheckBox>
                            </div>
                        </template>
                        <GgmsLoading v-if="isSearching" />
                    </div>
                </div>
                <div
                    v-if="workflowStore.selectedData.length"
                    class="flex justify-between items-center px-3 bg-white h-[54px] w-full rounded-b-md border-t border-gray-200"
                >
                    <div class="flex items-center gap-x-4">
                        <div class="bg-gray-300 w-4 h-4 rounded-sm cursor-pointer" @click="clearSelectedData">
                            <MinusSmIcon class="w-4 h-4"></MinusSmIcon>
                        </div>
                        <p class="text-base leading-6 font-medium w-fit">
                            {{ workflowStore.selectedData.length }} selected -
                            <span
                                class="font-medium text-primary-color-600 hover:text-primary-color-500 cursor-pointer"
                                @click="onSelect"
                            >
                                {{ selectAllText }}
                            </span>
                        </p>
                    </div>
                    <GgmsButton @click="closeSearchMenu">Done</GgmsButton>
                </div>
            </OverlayPanel>
            <div v-if="showData" class="flex flex-col pt-3">
                <div
                    v-for="data in workflowStore.selectedData"
                    :key="data._id"
                    class="flex justify-between items-center border-b border-gray-200 last:border-0 py-2.5 px-2"
                >
                    <div class="flex items-center gap-x-2">
                        <ProfilePicture
                            v-if="workflowStore.modelValue.value === 'agent'"
                            :image="data?.profilePicture"
                            :fullName="data?.fullName"
                        ></ProfilePicture>
                        <ListIcon v-else></ListIcon>
                        <div class="min-w-[32px] min-h-[32px] rounded-full items-center justify-center flex">
                            {{
                                workflowStore.modelValue.value === ContactDistributionNodeType.agent
                                    ? data?.fullName
                                    : data?.displayName
                            }}
                        </div>
                    </div>
                    <TrashIcon class="x-5 h-5 m-1 cursor-pointer" @click="deleteData(data)"></TrashIcon>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue"
import { CheckIcon, MinusSmIcon, SearchIcon, TrashIcon } from "@heroicons/vue/outline"
import { useWorkflowStore } from "@/stores/workflow"
import { useAgentsStore } from "@/stores/agents"
import { useCollectionStore } from "@/stores/collection"
import { debounce } from "@/shared/utils/helpers"
import { Collection } from "@/shared/models/collection"
import { Agent } from "@/shared/models/agent"
import GgmsRadioSelect from "@/components/GgmsRadioSelect.vue"
import GgmsLoading from "@/components/GgmsLoading.vue"
import GgmsCheckBox from "@/components/GgmsCheckBox.vue"
import GgmsButton from "@/components/GgmsButton.vue"
import GgmsInput from "@/components/GgmsInput.vue"
import ProfilePicture from "@/components/ProfilePicture.vue"
import ArrowDown from "@/components/icons/ArrowDown.vue"
import ListIcon from "@/components/icons/ListIcon.vue"
import { DistributionType, ContactDistributionNodeType } from "@/shared/models/workflow"

const workflowStore = useWorkflowStore()
const agentsStore = useAgentsStore()
const collectionStore = useCollectionStore()

const distributionOptions = ref([
    { displayName: "Sequential", value: DistributionType.sequential },
    { displayName: "Random", value: DistributionType.random },
    { displayName: "Round robin", value: DistributionType.round },
    { displayName: "First to claim", value: DistributionType.first },
])
const searchString = ref("")
const isTypeModalOpen = ref(false)
const isSearching = ref(false)
const data = ref<Agent[] | Collection[]>([])
const searchMenu = ref()
const typeMenu = ref()
const isAllSelected = ref(false)
const total = ref(0)
const showData = ref(false)

const selectionTitle = computed(() =>
    workflowStore.modelValue.value === ContactDistributionNodeType.agent
        ? "Specific team member/s"
        : "List/s of team members"
)
const selectionDescription = computed(() =>
    workflowStore.modelValue.value === ContactDistributionNodeType.agent ? "team member/s" : "list/s of team members"
)
const selectAllText = computed(() => (isAllSelected.value ? "Deselect all" : `Select all (${total.value})`))

async function getAgents() {
    data.value = await agentsStore.getAgents(searchString.value)
}

function isElementChecked(element: Agent | Collection) {
    return !!workflowStore.selectedData.find((el) => el._id === element._id)
}

async function getCollections() {
    data.value = await collectionStore.searchCollections(searchString.value, "agent")
}

function toggleSearchMenu(event?: Event) {
    searchMenu.value.show(event)
}

function toggleTypeMenu(event?: Event) {
    if (isTypeModalOpen.value) {
        typeMenu.value.hide()
        isTypeModalOpen.value = false
    } else {
        typeMenu.value.show(event)
        isTypeModalOpen.value = true
    }
}

function closeSearchMenu() {
    showData.value = true
    searchMenu.value.hide()
    if (workflowStore.modelValue.event) {
        workflowStore.areButtonDisabled = false
    }
}

function onSelect() {
    if (isAllSelected.value) {
        clearSelectedData()
    } else {
        workflowStore.selectedData = data.value
    }
    isAllSelected.value = !isAllSelected.value
}

async function selectType(type: string) {
    workflowStore.modelValue.value = type
    typeMenu.value.hide()
    isTypeModalOpen.value = false
    clearSelectedData()
    type === ContactDistributionNodeType.agent ? await getAgents() : await getCollections()
    total.value = data.value.length
}

function deleteData(element: Agent | Collection) {
    workflowStore.selectedData.splice(workflowStore.selectedData.indexOf(element), 1)
}

function clearSelectedData() {
    workflowStore.selectedData = []
}

function onClick(element: Agent | Collection) {
    if (isElementChecked(element)) {
        const index = workflowStore.selectedData.findIndex((el) => el._id === element._id)
        workflowStore.selectedData.splice(index, 1)
        return
    }

    workflowStore.selectedData.push(element)
}

watch(
    () => searchString.value,
    debounce(async () => {
        isSearching.value = true
        workflowStore.modelValue.value === ContactDistributionNodeType.agent
            ? await getAgents()
            : await getCollections()
        isSearching.value = false
    }, 250)
)

watch(
    () => typeMenu.value?.visible,
    () => {
        isTypeModalOpen.value = typeMenu.value.visible
    }
)

watch(
    () => workflowStore.modelValue.event,
    () => {
        if (showData.value) {
            workflowStore.areButtonDisabled = false
        }
    }
)

watch(
    () => workflowStore.selectedData.length,
    () => {
        if (!workflowStore.selectedData.length) {
            workflowStore.areButtonDisabled = true
            return
        }
        workflowStore.areButtonDisabled = false
    }
)

onMounted(async () => {
    if (workflowStore.selectedData.length) {
        showData.value = true
    }
    workflowStore.modelValue.value === ContactDistributionNodeType.agent ? await getAgents() : await getCollections()
    total.value = data.value.length
})
</script>
