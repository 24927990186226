<template>
    <div class="flex flex-col gap-y-4 mb-4">
        <div class="bg-indigo-50 rounded-md p-2.5 text-sm leading-5 font-normal">
            Only marketing contacts can receive this email. If you haven't done it yet,
            <u>learn how to set contacts as marketing</u>
        </div>
        <label class="block text-sm font-medium text-gray-700 mt-1"> Send email to</label>
        <div v-for="(option, index) in options" :key="option.value" class="flex flex-col" tabindex="0">
            <GgmsCheckBox
                v-model="sendingTypes[index]"
                :checked="isValueChecked(option.value)"
                :classes="['mt-1']"
                :centered="false"
                @click="onSelectType(option.value)"
            >
                <div class="ml-3">
                    {{ option.displayName }}
                </div>
            </GgmsCheckBox>

            <!--            Specific Email          -->
            <div v-if="option.value === SendEmailToType.specificEmail && sendingTypes[index]">
                <GgmsInput v-model="workflowStore.specificEmail" class="mt-2"></GgmsInput>
            </div>

            <!--            Specific Users         -->
            <div v-if="option.value === SendEmailToType.specificUsers && sendingTypes[index]">
                <!-- Dropdown for Selecting Agents -->
                <div id="overlaySelectAgents">
                    <GgmsInput
                        v-model="searchTerm"
                        class="w-full mt-2"
                        :iconStart="SearchIcon"
                        placeholder="Search"
                        @input="toggleMenu"
                        @click="toggleMenu"
                    ></GgmsInput>
                    <OverlayPanel
                        ref="menu"
                        class="!left-0 ring-1 ring-black ring-opacity-5 shadow-lg rounded-md bg-white mx-6 right-1"
                        appendTo="#overlaySelectAgents"
                    >
                        <div class="flex flex-col max-h-64 overflow-auto w-full top-36">
                            <div class="relative">
                                <div v-if="!agents?.length" class="py-6 border-0 px-4">No results found</div>
                                <template v-else>
                                    <div
                                        v-for="element in agents"
                                        :key="element._id"
                                        class="flex items-center gap-x-2 px-4 py-3 cursor-pointer border-b border-gray-200 last:border-0 overflow-hidden"
                                        tabindex="0"
                                    >
                                        <GgmsCheckBox
                                            :checked="isAgentChecked(element)"
                                            @click="onClickAgents(element)"
                                        >
                                            <div class="ml-3 flex items-center gap-x-2">
                                                <ProfilePicture
                                                    v-if="element.type === 'agent' && element?.displayName"
                                                    :image="element?.profilePicture"
                                                    :fullName="element?.displayName"
                                                ></ProfilePicture>
                                                <ListIcon v-if="element.type === 'collection'"></ListIcon>

                                                <div class="min-w-[32px] min-h-[32px] flex flex-col max-w-[310px]">
                                                    <p class="truncate">{{ element?.displayName }}</p>
                                                    <p v-if="element.type === 'agent'" class="truncate">
                                                        {{ element?.email }}
                                                    </p>
                                                </div>
                                            </div>
                                        </GgmsCheckBox>
                                    </div>
                                </template>
                                <GgmsLoading v-if="isSearching" />
                            </div>
                        </div>
                        <div
                            v-if="workflowStore.specificUsers?.length"
                            class="flex justify-between items-center px-3 bg-white h-[54px] w-full rounded-b-md border-t border-gray-200"
                        >
                            <div class="flex items-center gap-x-4">
                                <div class="bg-gray-300 w-4 h-4 rounded-sm cursor-pointer" @click="clearSpecificUsers">
                                    <MinusSmIcon class="w-4 h-4"></MinusSmIcon>
                                </div>
                                <p class="text-base leading-6 font-medium w-fit">
                                    {{ workflowStore.specificUsers?.length }} selected -
                                    <span
                                        class="font-medium text-primary-color-600 hover:text-primary-color-500 cursor-pointer"
                                        @click="onSelectAgents"
                                    >
                                        {{ selectAllTextAgents }}
                                    </span>
                                </p>
                            </div>
                            <GgmsButton @click="closeSearchAgentsMenu">Done</GgmsButton>
                        </div>
                    </OverlayPanel>
                </div>
                <div v-if="showSelectedAgents" class="flex flex-wrap mt-2">
                    <GgmsChip
                        v-for="agent in workflowStore.specificUsers"
                        :key="agent._id"
                        class="mb-1 mr-2 w-fit border border-gray-300 rounded-md"
                        bg-color="#FFFFFF"
                        @onRemove="removeAgent(agent)"
                    >
                        <div class="flex items-center gap-x-2">
                            <ListIcon v-if="agent.type === 'collection'"></ListIcon>
                            <ProfilePicture
                                v-if="agent.type === 'agent' && agent?.displayName"
                                :image="agent?.profilePicture"
                                :fullName="agent?.displayName"
                            />
                            <div class="text-xs">{{ agent.displayName }}</div>
                        </div>
                    </GgmsChip>
                </div>
            </div>
        </div>
    </div>
    <div id="specificTypeDropdown">
        <div class="flex gap-x-2 cursor-pointer w-fit" @click="toggleTypeMenu">
            <p class="text-lg leading-7 font-medium text-black">{{ selectionTitle }}</p>
            <div>
                <ArrowDown v-show="isTypeModalOpen" class="rotate-180 fill-gray-700"></ArrowDown>
                <ArrowDown v-show="!isTypeModalOpen" class="fill-gray-700"></ArrowDown>
            </div>
        </div>
        <OverlayPanel
            ref="typeMenu"
            appendTo="#specificTypeDropdown"
            class="bg-white rounded-md !left-0 ring-1 ring-black ring-opacity-5 shadow-lg mx-6 right-1 w-64 top-0"
        >
            <div class="flex flex-col cursor-pointer">
                <div
                    v-for="type in selectionTypes"
                    :key="type.value"
                    class="flex justify-between text-sm leading-5 font-normal p-3 items-center border-b"
                    @click="selectType(type.value)"
                >
                    <div>{{ type.label }}</div>
                    <CheckIcon v-if="workflowStore.modelValue.value === type.value" class="w-4 h-4"></CheckIcon>
                </div>
            </div>
        </OverlayPanel>
    </div>
    <div v-if="workflowStore.modelValue.value === SendEmailNodeType.custom" class="pt-2 flex flex-col gap-y-4">
        <p class="text-sm leading-5 font-normal text-gray-500">
            Create a new email to send randomly to the receivers. More emails will send individually.
        </p>
        <div
            v-if="!workflowStore.messagePayload?.from && !workflowStore.messagePayload?.body"
            class="flex items-center text-sm leading-5 font-medium text-gray-900 gap-x-1 cursor-pointer"
            @click="openCreateMessageModal"
        >
            <PlusSmIcon class="h-5 w-5"></PlusSmIcon>
            <span>New email</span>
        </div>
        <div v-else class="flex items-center justify-between">
            <div class="py-2 pl-2 flex flex-col gap-y-2 max-w-[350px] cursor-pointer w-full" @click="openUpdateModal">
                <span class="text-gray-800">From: {{ workflowStore.messagePayload?.from?.email?.emailAddress }} </span>
                <div
                    v-html="workflowStore.messagePayload?.subject"
                    class="text-sm leading-5 font-normal text-gray-600"
                ></div>
            </div>
            <TrashIcon class="w-5 h-5 cursor-pointer text-gray-900" @click="deleteTextMessage"></TrashIcon>
        </div>
    </div>
    <div v-if="workflowStore.modelValue.value === SendEmailNodeType.template" class="pt-2 flex flex-col gap-y-4">
        <p class="text-sm leading-5 font-normal text-gray-500">
            Randomly send an email template from the selected list/s to all selected recipients
        </p>
        <div class="w-full flex items-center">
            <span class="text-sm font-medium mr-2">From:</span>
            <ContactDropdown
                dropdownClass="max-w-[355px]"
                senderClass="truncate"
                v-model:selectedSender="workflowStore.selectedSender"
                :senders="senders"
                type="email"
                :isInSidebar="true"
            />
        </div>
        <div class="flex gap-x-4 items-center">
            <div class="flex items-center gap-x-1">
                <span>Randomize</span>
                <InformationCircleIcon class="w-5 h-5"></InformationCircleIcon>
            </div>
            <InputSwitch v-model="workflowStore.modelValue.event"></InputSwitch>
        </div>
        <div id="searchOverlayTarget">
            <GgmsInput
                input-class="bg-gray-100 border-0"
                v-model="searchTerm"
                :small="true"
                :iconEnd="SearchIcon"
                placeholder="Search"
                @input="toggleSearchMenu"
                @click="toggleSearchMenu"
            ></GgmsInput>
            <OverlayPanel
                ref="searchMenu"
                class="!left-0 ring-1 ring-black ring-opacity-5 shadow-lg rounded-md bg-white mx-6 right-1"
                appendTo="#searchOverlayTarget"
            >
                <div class="flex flex-col max-h-64 overflow-auto w-full top-36">
                    <div class="relative">
                        <div v-if="!data?.length" class="py-6 border-0 px-4">No results found</div>
                        <template v-else>
                            <div
                                v-for="template in data"
                                :key="template._id"
                                class="flex gap-x-2 px-4 top-0 py-3 border-b border-gray-200 last:border-0"
                                tabindex="0"
                            >
                                <GgmsCheckBox
                                    :checked="isTemplateChecked(template)"
                                    :classes="['mt-1']"
                                    :centered="false"
                                    @click="onClick(template)"
                                >
                                    <div class="ml-3 flex flex-col gap-x-2 gap-y-1">
                                        <div class="rounded-full items-center flex">
                                            {{ template.displayName }}
                                        </div>
                                        <div class="flex justify-start">
                                            <GgmsChip
                                                v-for="tag in template.tags"
                                                :key="tag._id"
                                                :bgColor="cachedTags[tag._id]?.backgroundColor"
                                                :textColor="cachedTags[tag._id]?.foregroundColor"
                                                :isRemovable="false"
                                                class="h-6"
                                                >{{ tag.displayName }}
                                            </GgmsChip>
                                        </div>
                                        <p class="text-gray-400 text-xs leading-5 font-normal">
                                            {{ formatHumanReadableDate(new Date(template.updatedAt), true) }}
                                        </p>
                                    </div>
                                </GgmsCheckBox>
                            </div>
                        </template>
                        <GgmsLoading v-if="isSearching" />
                    </div>
                </div>
                <div
                    v-if="templateStore.selectedData?.length"
                    class="flex justify-between items-center px-3 bg-white h-[54px] w-full rounded-b-md border-t border-gray-200"
                >
                    <div class="flex items-center gap-x-4">
                        <div class="bg-gray-300 w-4 h-4 rounded-sm cursor-pointer" @click="clearSelectedData">
                            <MinusSmIcon class="w-4 h-4"></MinusSmIcon>
                        </div>
                        <p class="text-base leading-6 font-medium w-fit">
                            {{ templateStore.selectedData?.length }} selected -
                            <span
                                class="font-medium text-primary-color-600 hover:text-primary-color-500 cursor-pointer"
                                @click="onSelect"
                            >
                                {{ selectAllText }}
                            </span>
                        </p>
                    </div>
                    <GgmsButton @click="closeSearchMenu">Done</GgmsButton>
                </div>
            </OverlayPanel>
        </div>
        <div v-if="showData" class="flex flex-col pt-3">
            <div
                v-for="template in templateStore.selectedData"
                :key="template._id"
                class="flex justify-between items-center border-b border-gray-200 last:border-0 py-2.5 px-2"
            >
                <div
                    class="ml-3 flex flex-col gap-x-2 gap-y-1 cursor-pointer w-full"
                    @click.prevent.stop="openCreateTemplateModal(template)"
                >
                    <div class="rounded-full items-center flex">
                        {{ template.displayName }}
                    </div>
                    <div class="flex justify-start">
                        <GgmsChip
                            v-for="tag in template.tags"
                            :key="tag._id"
                            :bgColor="cachedTags[tag._id]?.backgroundColor"
                            :textColor="cachedTags[tag._id]?.foregroundColor"
                            :isRemovable="false"
                            class="h-6"
                            >{{ tag.displayName }}
                        </GgmsChip>
                    </div>
                    <p class="text-gray-400 text-xs leading-5 font-normal">
                        {{ formatHumanReadableDate(new Date(template.updatedAt), true) }}
                    </p>
                </div>
                <TrashIcon class="x-5 h-5 m-1 cursor-pointer" @click="deleteTemplate(template)"></TrashIcon>
            </div>
        </div>
    </div>
    <GgmsModal
        :open="isCreatingMessageModalOpen"
        bodyClass="p-0"
        headerClass="border-b border-gray-200 py-5"
        size="3xl"
        @closeModal="closeCreateMessageModal"
        :style="{ zIndex: 9999 }"
    >
        <template #header>
            <h1 class="text-lg font-medium text-gray-900">New Email</h1>
        </template>
        <CreatingEmail :isWorkflow="true" :message="currentMessage" @saveEmail="saveEmail"></CreatingEmail>
    </GgmsModal>

    <GgmsTemplateModal
        :open="isOpenCreateTemplateModal"
        :isCreateTemplate="false"
        :isPreviewOpen="isPreviewOpen"
        :newTemplate="newTemplate"
        :isDomainsDisabled="true"
        :domains="domains"
        :selectedDomains="selectedDomains"
        :tags="tags"
        :selectedTags="selectedTags"
        :showDeleteButton="false"
        :typeDropdownDisabled="true"
        @update:selectedTags="(value) => (selectedTags = value)"
        @update:selectedDomains="(value) => (selectedDomains = value)"
        @closePreview="closePreview"
        @openPreview="openPreview"
        @closeModal="closeCreateTemplateModal"
        @openDelete="openDeleteTemplateModal(newTemplate)"
        @saveTemplate="updateSelectedTemplate(newTemplate)"
    ></GgmsTemplateModal>
</template>
<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue"
import {
    CheckIcon,
    InformationCircleIcon,
    MinusSmIcon,
    PlusSmIcon,
    SearchIcon,
    TrashIcon,
} from "@heroicons/vue/outline"
import ArrowDown from "@/components/icons/ArrowDown.vue"
import CreatingEmail from "@/components/profile-details/middle-panel/CreatingEmail.vue"
import GgmsModal from "@/components/GgmsModal.vue"
import GgmsButton from "@/components/GgmsButton.vue"
import GgmsInput from "@/components/GgmsInput.vue"
import GgmsCheckBox from "@/components/GgmsCheckBox.vue"
import GgmsChip from "@/components/GgmsChip.vue"
import GgmsLoading from "@/components/GgmsLoading.vue"
import ContactDropdown from "@/components/profile-details/middle-panel/ContactDropdown.vue"
import ListIcon from "@/components/icons/ListIcon.vue"
import ProfilePicture from "@/components/ProfilePicture.vue"
import { debounce, formatHumanReadableDate, stripHtml } from "@/shared/utils/helpers"
import { SendEmailNodeType, SendEmailToType } from "@/shared/models/workflow"
import { useWorkflowStore } from "@/stores/workflow"
import { useTemplateStore } from "@/stores/template"
import { useTagStore } from "@/stores/tag"
import { useAgentsStore } from "@/stores/agents"
import { useIntegrationStore } from "@/stores/integration"
import { useConfigStore } from "@/stores/config"
import { Template } from "@/shared/models/template"
import { Message } from "@/shared/models/message"
import { Sender } from "@/shared/models/sender"
import { Integration } from "@/shared/models/integration"
import { ToastService } from "@/shared/services/toast"
import { Recipient } from "@/shared/models/recipient"
import GgmsTemplateModal from "@/components/GgmsTemplateModal.vue"
import { GgmsError } from "@/shared/services/client"
import { AgencyDomain } from "@/shared/models/agency"
import { Tag, TagType } from "@/shared/models/tag"
import { useAgencyStore } from "@/stores/agency"

const agentStore = useAgentsStore()
const workflowStore = useWorkflowStore()
const templateStore = useTemplateStore()
const tagStore = useTagStore()
const configStore = useConfigStore()
const integrationStore = useIntegrationStore()
const agencyStore = useAgencyStore()

const toastService = new ToastService()

const isTypeModalOpen = ref(false)
const typeMenu = ref()
const searchTerm = ref("")
const data = ref<Template[]>([])
const total = ref(0)
const totalAgents = ref(0)
const isCreatingMessageModalOpen = ref(false)
const searchMenu = ref()
const isSearching = ref(false)
const isAllSelected = ref(false)
const isAllSelectedAgents = ref(false)
const showData = ref(false)
const sendingTypes = ref<boolean[]>([])
const senders = ref<Sender[]>([])
const isLoading = ref(false)
const menu = ref()
const agents = ref<Recipient[]>([])
const showSelectedAgents = ref(false)

const isPreviewOpen = ref(false)
const isOpenCreateTemplateModal = ref(false)
const newTemplate = ref({} as Template)
const isOpenDeleteTemplateModal = ref(false)
const deleteableTemplate = ref({} as Template)
const isViewingFormatted = ref(true)
const isTextBodyModified = ref(false)
const selectedDomains = ref<AgencyDomain[]>([])
const domains = computed(() => agencyStore.agency.agencyDomains || [])
const isDomainsDisabled = ref(false)
const selectedTags = ref<Tag[]>([])
const tags = computed(() => tagStore.tableState.data || [])
const specialVariable = ref("")

const currentMessage = ref()

const integrations = computed(() => configStore.integrations as Integration[])
const cachedTags = computed(() => tagStore.cachedTags)
const selectAllText = computed(() => (isAllSelected.value ? "Deselect all" : `Select all (${total.value})`))
const selectAllTextAgents = computed(() =>
    isAllSelectedAgents.value ? "Deselect all" : `Select all (${totalAgents.value})`
)

const options = [
    { displayName: "Enrolled contacts", value: SendEmailToType.enrolled },
    { displayName: "Associated contact", value: SendEmailToType.associated },
    { displayName: "Specific email", value: SendEmailToType.specificEmail },
    { displayName: "Specific users", value: SendEmailToType.specificUsers },
]

const selectionTypes = [
    { label: "Custom email", value: SendEmailNodeType.custom },
    { label: "Template emails", value: SendEmailNodeType.template },
    { label: "Emails containing certain tags", value: SendEmailNodeType.containsTags },
    { label: "Email list", value: SendEmailNodeType.listOfSavedTexts },
]

const selectionTitle = computed(() => {
    if (workflowStore.modelValue.value === SendEmailNodeType.custom) {
        return "Custom email"
    }
    if (workflowStore.modelValue.value === SendEmailNodeType.template) {
        return "Template emails"
    }
    if (workflowStore.modelValue.value === SendEmailNodeType.containsTags) {
        return "Emails containing certain tags"
    }
    return "Email list"
})

function toggleTypeMenu(event?: Event) {
    if (isTypeModalOpen.value) {
        typeMenu.value.hide()
        isTypeModalOpen.value = false
    } else {
        typeMenu.value.show(event)
        isTypeModalOpen.value = true
    }
}

async function selectType(type: string) {
    typeMenu.value.hide()
    isTypeModalOpen.value = false
    workflowStore.modelValue.value = type
    clearSelectedData()
    await getTemplates()
    total.value = data.value.length
}

async function getTemplates() {
    templateStore.tableState.templateType = "email"
    templateStore.tableState.search = searchTerm.value
    await templateStore.getTemplates()
    data.value = templateStore.tableState.data
}

function clearSelectedData() {
    templateStore.selectedData = []
}

function openCreateMessageModal() {
    isCreatingMessageModalOpen.value = true
}

function openUpdateModal() {
    currentMessage.value = workflowStore.messagePayload
    openCreateMessageModal()
}

function deleteTextMessage() {
    workflowStore.messagePayload = {} as Message
}

function closeCreateMessageModal() {
    isCreatingMessageModalOpen.value = false
}

function saveEmail() {
    isCreatingMessageModalOpen.value = false
}

function toggleSearchMenu(event?: Event) {
    searchMenu.value.show(event)
}

function isTemplateChecked(template: Template) {
    return !!templateStore.selectedData.find((el) => el._id === template._id)
}

function onClick(template: Template) {
    if (isTemplateChecked(template)) {
        const index = templateStore.selectedData.findIndex((el) => el._id === template._id)
        templateStore.selectedData.splice(index, 1)
        return
    }

    templateStore.selectedData.push(template)
}

function isAgentChecked(agent: Recipient) {
    return !!workflowStore.specificUsers.find((el) => el._id === agent._id)
}

function onClickAgents(agent: Recipient) {
    if (isAgentChecked(agent)) {
        const index = workflowStore.specificUsers.findIndex((el) => el._id === agent._id)
        workflowStore.specificUsers.splice(index, 1)
        return
    }

    workflowStore.specificUsers.push(agent)
}

function isValueChecked(value: string) {
    return !!workflowStore.emailSendingTypes.find((el) => el === value)
}

function onSelectType(value: string) {
    if (isValueChecked(value)) {
        const index = workflowStore.emailSendingTypes.findIndex((el) => el === value)
        workflowStore.emailSendingTypes.splice(index, 1)
        return
    }

    workflowStore.emailSendingTypes.push(value)
}

function deleteTemplate(template: Template) {
    templateStore.selectedData.splice(templateStore.selectedData.indexOf(template), 1)
}

function onSelect() {
    if (isAllSelected.value) {
        clearSelectedData()
    } else {
        templateStore.selectedData = data.value
    }
    isAllSelected.value = !isAllSelected.value
}

function closeSearchMenu() {
    showData.value = true
    searchMenu.value.hide()
}

function closeSearchAgentsMenu() {
    showSelectedAgents.value = true
    menu.value[0].hide()
}

function getSendgridSenderProp(propName: string) {
    return integrationStore.integrationConfig?.versions
        ?.at(-1)
        ?.sectionAnswers?.[1]?.answers?.find((answer) => answer.name === propName)?.textAnswers?.[0]
}
async function convertIntegrationsToSenders() {
    integrations.value
        .filter((integration) => integration.integrationType === "inboxEmail" && integration.isEnabled)
        .forEach((integration) => {
            senders.value.push({
                name: integration?.fromName || "",
                email: integration.fromAddress,
                integrationName: integration.integrationName,
            })
        })

    // add Sendgrid integration to the list of senders if the it's enabled
    const sendgridIntegration = integrations.value.find((integration) => integration.integrationName === "sendgrid")
    if (!sendgridIntegration) {
        return
    }
    await integrationStore.getIntegration(sendgridIntegration._id)

    const sendgridSenderEmail = getSendgridSenderProp("item_5")
    const sendgridSenderName = getSendgridSenderProp("item_6")

    if (!sendgridSenderName || !sendgridSenderEmail) {
        return
    }
    senders.value.push({
        name: sendgridSenderName,
        email: sendgridSenderEmail,
        integrationName: sendgridIntegration.integrationName,
    })
}

function setSelectedSender() {
    if (!senders.value?.length) {
        toastService.addToast({
            message: "No email address found. Please add an email address in your profile settings.",
            type: "warning",
        })
        return
    }

    const selectedIndex = senders.value.findIndex((sender) => sender.email === workflowStore.selectedSender.email)

    const senderIndex = selectedIndex === -1 ? 0 : selectedIndex
    workflowStore.selectedSender = senders.value[senderIndex]
}

async function setIntegrations() {
    await convertIntegrationsToSenders()
    setSelectedSender()
}

function toggleMenu(event?: Event) {
    menu.value[0].show(event)
}

function onSelectAgents() {
    if (isAllSelectedAgents.value) {
        clearSpecificUsers()
    } else {
        workflowStore.specificUsers = agents.value
    }
    isAllSelectedAgents.value = !isAllSelectedAgents.value
}

function removeAgent(agent: Recipient) {
    if (workflowStore.specificUsers.includes(agent)) {
        workflowStore.specificUsers.splice(workflowStore.specificUsers.indexOf(agent), 1)
    }
}

function clearSpecificUsers() {
    workflowStore.specificUsers = []
}

function closePreview() {
    isPreviewOpen.value = false
}

function openPreview() {
    isPreviewOpen.value = true
}

function closeCreateTemplateModal() {
    templateStore.validationError = {} as GgmsError
    newTemplate.value = {} as Template
    isOpenCreateTemplateModal.value = false
    closePreview()
    isTextBodyModified.value = false
    selectedDomains.value = []
    selectedTags.value = []
    specialVariable.value = ""
}

function openCreateTemplateModal(template: Template) {
    newTemplate.value = Object.assign({}, template)
    newTemplate.value.textBody = stripHtml(newTemplate.value.body)
    isTextBodyModified.value = true
    // add domains and tags to selected
    selectedDomains.value = domains.value.filter((domain) => newTemplate.value?.domainId === domain._id)
    isDomainsDisabled.value = true
    const templateTagIds = newTemplate.value?.tags?.map((tag) => tag._id)
    selectedTags.value = tags.value.filter((tag) => templateTagIds?.includes(tag._id))
    specialVariable.value = template?.displayName || ""

    isViewingFormatted.value = true
    isOpenCreateTemplateModal.value = true
}

function openDeleteTemplateModal(template: Template) {
    if (isOpenCreateTemplateModal.value) {
        closeCreateTemplateModal()
    }
    isOpenDeleteTemplateModal.value = true
    deleteableTemplate.value = template
}

function updateSelectedTemplate(template: Template) {
    const index = templateStore.selectedData.findIndex((el) => el._id === template._id)
    templateStore.selectedData.splice(index, 1)
    const updatedTemplate = templateStore.tableState.data.find((newTemplate) => template._id === newTemplate._id)
    templateStore.selectedData.push(updatedTemplate)
}

onMounted(async () => {
    try {
        isLoading.value = true
        if (workflowStore.specificUsers.length) {
            showSelectedAgents.value = true
        }
        if (!configStore.integrations.length) {
            await configStore.getConfig()
        }
        await setIntegrations()
        if (workflowStore.emailSendingTypes.length) {
            workflowStore.emailSendingTypes.forEach((type) => {
                const index = options.findIndex((option) => option.value === type)
                sendingTypes.value[index] = true
            })
        }
        if (templateStore.selectedData.length) {
            showData.value = true
        }
        await getTemplates()
        agents.value = await agentStore.searchAgentsRecipients([], searchTerm.value)
        tagStore.tableState.typeName = TagType.messageTemplate
        await tagStore.getTags()
        totalAgents.value = agents.value?.length
        total.value = data.value.length
    } finally {
        isLoading.value = false
    }
})

watch(
    () => searchTerm.value,
    debounce(async () => {
        isSearching.value = true
        agents.value = await agentStore.searchAgentsRecipients([], searchTerm.value)
        isSearching.value = false
    }, 250)
)

watch(
    () => [
        workflowStore.messagePayload,
        workflowStore.emailSendingTypes,
        templateStore.selectedData,
        workflowStore.specificUsers,
        workflowStore.specificEmail,
        workflowStore.modelValue.event,
        workflowStore.selectedSender,
    ],

    () => {
        if (workflowStore.emailSendingTypes.length > 0) {
            if (
                workflowStore.emailSendingTypes.includes(SendEmailToType.specificEmail) &&
                !workflowStore.specificEmail
            ) {
                workflowStore.areButtonDisabled = true
                return
            }
            if (
                workflowStore.emailSendingTypes.includes(SendEmailToType.specificUsers) &&
                workflowStore.specificUsers.length === 0
            ) {
                workflowStore.areButtonDisabled = true
                return
            }
            if (
                workflowStore.modelValue.value === SendEmailNodeType.template &&
                templateStore.selectedData.length === 0
            ) {
                workflowStore.areButtonDisabled = true
                return
            }
            if (
                workflowStore.modelValue.value === SendEmailNodeType.custom &&
                (!workflowStore.messagePayload?.from || !workflowStore.messagePayload?.body)
            ) {
                workflowStore.areButtonDisabled = true
                return
            }
            workflowStore.areButtonDisabled = false
            return
        }
        workflowStore.areButtonDisabled = true
        return
    },
    { deep: true }
)
</script>
