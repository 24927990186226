import { defineStore } from "pinia"
import { AgencyTheme, ThemeColors } from "@/shared/models/agency-theme"
import { AgencyThemeService } from "@/shared/services/agency-theme"
import { ConfigService } from "@/shared/services/config"
import { ToastService } from "@/shared/services/toast"
import { UploadFile, UploadItem } from "@/shared/models/upload-file"
import { setColorShades } from "@/shared/utils/color-helpers"

export const useAgencyThemeStore = defineStore("agencyTheme", {
    state: () => ({
        agencyTheme: {} as AgencyTheme,
        agencyThemeService: new AgencyThemeService(),
        isLoading: false,
        colors: defaultColors,
        themeColors: {} as ThemeColors,
        toastService: new ToastService(),
        configService: new ConfigService(),
    }),
    actions: {
        async getAgencyTheme() {
            try {
                this.isLoading = true
                this.agencyTheme = await this.agencyThemeService.getAgencyTheme()
            } finally {
                this.isLoading = false
            }
        },

        async updateAgencyTheme(payload: AgencyTheme, isLogoDeleted?: boolean) {
            try {
                this.isLoading = true
                this.agencyTheme = (
                    await this.agencyThemeService.updateAgencyTheme({ ...payload, isLogoDeleted })
                ).theme
                this.toastService.addToast({
                    message: "Agency Theme updated successfully",
                    type: "success",
                })
            } finally {
                this.isLoading = false
            }
        },

        async requestPostDataForFileUpload(uploads: UploadItem[], files: File[]): Promise<UploadFile[]> {
            const response = await this.agencyThemeService.requestPostDataForFileUpload(uploads)
            let uploadedFiles = []
            if (response) {
                uploadedFiles = await Promise.all(
                    response.presignedPostUrls.map((file, index) => {
                        const formData = new FormData()
                        Object.entries(file.fields).forEach(([key, value]) => {
                            formData.append(key, value)
                        })
                        formData.append("file", files[index])
                        return this.agencyThemeService.uploadFiles(file.url, formData)
                    })
                )
            }
            uploadedFiles = uploadedFiles.map((file) => file?.data?.upload)

            return uploadedFiles
        },

        async getAgencyThemeBySubdomain() {
            try {
                this.isLoading = true

                const hostname = window.location.hostname
                const baseWebUrl = `${window.location.protocol}//${window.location.host}`
                const currentPath = `${window.location.pathname}${window.location.search}`

                console.log(`[getAgencyThemeBySubdomain] Fetching theme for: ${hostname}`)

                const response = await this.configService.getAgencyThemeBySubdomain(hostname)

                if (response) {
                    const { theme, isDomainActive, redirectToUrl } = response

                    if (theme) {
                        this.agencyTheme = theme

                        setColorShades("--primary-color", theme.primaryButtonColor)
                        setColorShades("--link-color", theme.linkColor)
                        localStorage.setItem("--primary-color", theme.primaryButtonColor)
                        localStorage.setItem("--link-color", theme.linkColor)
                    }

                    if (redirectToUrl && redirectToUrl !== hostname) {
                        const hasProtocol = redirectToUrl.startsWith("http://") || redirectToUrl.startsWith("https://")
                        const finalRedirectUrl = hasProtocol
                            ? redirectToUrl
                            : `${window.location.protocol}//${redirectToUrl}`

                        console.log(`[getAgencyThemeBySubdomain] Redirecting to: ${finalRedirectUrl}${currentPath}`)

                        return window.location.replace(`${finalRedirectUrl}${currentPath}`)
                    }

                    if (!isDomainActive) {
                        const fallbackUrl = process.env.VUE_APP_BASE_WEB_URL || baseWebUrl
                        console.log(`[getAgencyThemeBySubdomain] Domain inactive, redirecting to: ${fallbackUrl}`)
                        return window.location.replace(fallbackUrl)
                    }
                } else {
                    console.warn("[getAgencyThemeBySubdomain] No response received for theme.")
                }
            } finally {
                this.isLoading = false
            }
        },
    },
})

const defaultColors = [
    {
        background: "#0090be",
        text: "#ffffff",
    },
    {
        background: "#2563eb",
        text: "#ffffff",
    },
    {
        background: "#4f46e5",
        text: "#ffffff",
    },
    {
        background: "#7c3aed",
        text: "#ffffff",
    },
    {
        background: "#db2777",
        text: "#ffffff",
    },
    {
        background: "#059669",
        text: "#ffffff",
    },
    {
        background: "#fbbf24",
        text: "#ffffff",
    },
    {
        background: "#ea580c",
        text: "#ffffff",
    },
    {
        background: "#dc2626",
        text: "#ffffff",
    },
    {
        background: "#4b5563",
        text: "#ffffff",
    },
]
