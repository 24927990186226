<template>
    <div class="flex items-center relative">
        <div
            :class="[
                'flex justify-center items-center py-1 px-2.5 rounded-full bg-gray-100 h-6 w-full cursor-pointer',
                dropdownClass,
            ]"
            @click="openOverlayPanel($event)"
        >
            <component v-if="!isMessageType" :is="getIntegrationIcon(localSelectedSender)" class="h-6 w-6" />
            <p :class="['text-sm font-medium text-gray-800 w-fit ml-1 flex gap-x-1.5 items-center', senderClass]">
                {{ localSelectedSender?.name }}
                <HomeIcon v-if="localSelectedSender?.type === 'personal'" class="w-4 h-4"></HomeIcon>
                <OfficeBuildingIcon
                    v-else-if="localSelectedSender?.type === 'agency'"
                    class="w-4 h-4"
                ></OfficeBuildingIcon>
                {{ getSenderData() }}
            </p>
            <ChevronDownIcon class="h-3 w-3 m-0 inline-block ml-1" />
        </div>
        <div :class="dropdownStyle" ref="appendToRef">
            <OverlayPanel ref="menu" :appendTo="appendToRef" @hide="onOverlayHide" :class="overlayStyle">
                <div class="relative flex flex-col h-full z-10">
                    <div
                        v-for="contact in senders"
                        :key="contact?.name"
                        tabindex="0"
                        class="z-10 rounded-md flex items-center gap-x-2 outline-primary-color-500 hover:bg-gray-50 px-4 py-3 cursor-pointer"
                        @click="selectContact(contact)"
                        @keydown.enter="selectContact(contact)"
                    >
                        <div
                            class="min-w-[32px] min-h-[32px] rounded-full bg-gray-300 items-center justify-center flex"
                        >
                            {{ getInitials(contact?.name || "") }}
                        </div>
                        <div class="flex flex-col w-max max-w-[400px]">
                            <div class="flex items-center">
                                <component v-if="!isMessageType" :is="getIntegrationIcon(contact)" class="h-6 w-6" />
                                <span class="ml-2">{{ contact?.name }}</span>
                            </div>
                            <div class="flex items-center gap-x-1">
                                <HomeIcon v-if="contact?.type === 'personal'" class="w-4 h-4 text-gray-400"></HomeIcon>
                                <OfficeBuildingIcon
                                    v-else-if="contact?.type === 'agency'"
                                    class="w-4 h-4 text-gray-400"
                                ></OfficeBuildingIcon>
                                <p class="text-gray-500 overflow-hidden truncate whitespace-nowrap max-w-[350px]">
                                    ({{ getContactInformation(contact) }})
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </OverlayPanel>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, computed, PropType, nextTick } from "vue"
import { ChevronDownIcon, InboxIcon, HomeIcon, OfficeBuildingIcon } from "@heroicons/vue/outline"
import GmailIcon from "@/components/icons/GmailIcon.vue"
import SendgridIcon from "@/components/icons/SendgridIcon.vue"
import OutlookIcon from "@/components/icons/OutlookIcon.vue"
import { Sender } from "@/shared/models/sender"
import { getInitials, internationalPhoneFormat } from "@/shared/utils/helpers"

const props = defineProps({
    type: {
        type: String,
        default: "sms",
        validator: (value: string) => ["sms", "email"].includes(value),
    },
    selectedSender: {
        type: Object as PropType<Sender>,
        default: () => ({} as Sender),
    },
    senders: {
        type: Array as PropType<Sender[]>,
        default: () => [],
    },
    dropdownClass: {
        type: String,
        default: "",
    },
    senderClass: {
        type: String,
        default: "",
    },
    isInSidebar: {
        type: Boolean,
        default: false,
    },
})

const emit = defineEmits(["close", "select", "update:selectedSender"])

const menu = ref()
const appendToRef = ref(null)
const menuVisible = ref(false)

const localSelectedSender = computed({
    get: () => props.selectedSender,
    set: (value: Sender) => {
        emit("update:selectedSender", value)
    },
})
const isMessageType = computed(() => props.type === "sms")
const overlayStyle = computed(() => [
    isMessageType.value ? "!-left-56" : "!-left-80",
    "ring-1 ring-black ring-opacity-5 shadow-lg rounded-md bg-white z-10 w-max !top-[8px] right-0",
])

const dropdownStyle = computed(() => [props.isInSidebar ? "fixed right-[110px]" : "relative", "z-10 right-0 "])

const integrationIconFunctionsMap = {
    gmail: GmailIcon,
    sendgrid: SendgridIcon,
    outlook: OutlookIcon,
    twilio: InboxIcon,
    custom: InboxIcon,
}

function getSenderData() {
    return isMessageType.value
        ? internationalPhoneFormat(localSelectedSender.value?.phone?.number || "")
        : localSelectedSender.value?.email
}

function onOverlayHide() {
    menuVisible.value = false
}

function getContactInformation(contact: Sender) {
    return isMessageType.value ? internationalPhoneFormat(contact?.phone?.number || "") : contact?.email
}

function getIntegrationIcon(contact: Sender) {
    if (!contact.integrationName) {
        return InboxIcon
    }
    return integrationIconFunctionsMap[contact.integrationName]
}

function selectContact(contact: Sender) {
    localSelectedSender.value = contact
    emit("update:selectedSender", contact)
    menuVisible.value = false
    menu.value.hide()
}

function openOverlayPanel(event: MouseEvent) {
    if (!props.senders.length) {
        return
    }
    nextTick(() => {
        menuVisible.value = !menuVisible.value
        menuVisible.value ? menu.value.show(event) : menu.value.hide()
    })
}
</script>
