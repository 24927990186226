<template>
    <div class="flex flex-col">
        <p class="text-lg contacting-7 font-medium text-black pb-6">Time of day workflow should trigger</p>
        <GgmsCalendar
            v-model="workflowStore.modelValue.time"
            position="below"
            label="Time of day"
            :showTime="true"
            :showDate="false"
            :iconStart="ClockIcon"
        >
        </GgmsCalendar>
    </div>
</template>
<script setup lang="ts">
import { watch } from "vue"
import { ClockIcon } from "@heroicons/vue/outline"
import { useWorkflowStore } from "@/stores/workflow"
import { dateTimeConverter } from "@/shared/utils/helpers"
import GgmsCalendar from "@/components/GgmsCalendar.vue"

const workflowStore = useWorkflowStore()

watch(
    () => workflowStore.modelValue.time,
    () => {
        //convert time into ISO date
        if (workflowStore.modelValue.time) {
            workflowStore.modelValue.dateTime = dateTimeConverter(workflowStore.modelValue.time)
        }
        workflowStore.areButtonDisabled = !workflowStore.modelValue.time
    }
)
</script>
