<template>
    <div class="flex flex-col">
        <p class="text-lg leading-7 font-medium text-black pb-6">Date when should move to the next action</p>
        <div class="flex flex-col gap-y-5">
            <GgmsCalendar
                v-model="workflowStore.modelValue.date"
                position="below"
                label="Select day"
                minDate="today"
                :showTime="false"
                :showDate="true"
                :iconStart="CalendarIcon"
            >
            </GgmsCalendar>
            <GgmsCalendar
                v-model="workflowStore.modelValue.time"
                position="below"
                label="Select time"
                :minDate="minHour"
                :showTime="true"
                :showDate="false"
                :iconStart="ClockIcon"
            >
            </GgmsCalendar>
        </div>
    </div>
</template>
<script setup lang="ts">
import { computed, ref, watch } from "vue"
import { CalendarIcon, ClockIcon } from "@heroicons/vue/outline"
import { useWorkflowStore } from "@/stores/workflow"
import { dateTimeConverter, isEarlierToday, isToday } from "@/shared/utils/helpers"
import GgmsCalendar from "@/components/GgmsCalendar.vue"

const workflowStore = useWorkflowStore()
const isSelectedDateToday = ref(false)
const minHour = computed(() => (isSelectedDateToday.value ? "now" : "today"))

watch(
    () => [workflowStore.modelValue?.date, workflowStore.modelValue?.time],
    () => {
        //convert time and date into ISO date
        isSelectedDateToday.value = isToday(new Date(workflowStore.modelValue?.date))
        if (
            workflowStore.modelValue?.date &&
            workflowStore.modelValue?.time &&
            isEarlierToday(workflowStore.modelValue?.date, workflowStore.modelValue?.time)
        ) {
            workflowStore.modelValue.time = ""
        }

        if (workflowStore.modelValue?.date && workflowStore.modelValue?.time) {
            workflowStore.modelValue.dateTime = dateTimeConverter(
                workflowStore.modelValue.time,
                workflowStore.modelValue.date
            )
        } else if (workflowStore.modelValue?.date) {
            workflowStore.modelValue.dateTime = dateTimeConverter("10:00", workflowStore.modelValue.date)
            workflowStore.areButtonDisabled = false
        }
    }
)
</script>
