<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.33398 5.8335C3.33398 5.05693 3.33398 4.66864 3.46085 4.36236C3.63001 3.95398 3.95447 3.62952 4.36285 3.46036C4.66913 3.3335 5.05742 3.3335 5.83398 3.3335H14.1673C14.9439 3.3335 15.3322 3.3335 15.6385 3.46036C16.0468 3.62952 16.3713 3.95398 16.5405 4.36236C16.6673 4.66864 16.6673 5.05693 16.6673 5.8335M7.50065 16.6668H12.5007M10.0007 3.3335V16.6668"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>
