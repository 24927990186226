<template>
    <div>
        <div id="specificTypeDropdown">
            <div class="flex gap-x-2 cursor-pointer w-fit" @click="toggleTypeMenu">
                <p class="text-lg leading-7 font-medium text-black">{{ selectionTitle }}</p>
                <div>
                    <ArrowDown v-show="isTypeModalOpen" class="rotate-180 fill-gray-700"></ArrowDown>
                    <ArrowDown v-show="!isTypeModalOpen" class="fill-gray-700"></ArrowDown>
                </div>
            </div>
            <OverlayPanel
                ref="typeMenu"
                appendTo="#specificTypeDropdown"
                class="bg-white rounded-md !left-0 ring-1 ring-black ring-opacity-5 shadow-lg mx-6 right-1 w-64 top-0"
            >
                <div class="flex flex-col cursor-pointer">
                    <div
                        v-for="type in selectionTypes"
                        :key="type.value"
                        class="flex justify-between text-sm leading-5 font-normal p-3 items-center border-b"
                        @click="selectType(type.value)"
                    >
                        <div>{{ type.label }}</div>
                        <CheckIcon v-if="workflowStore.modelValue.value === type.value" class="w-4 h-4"></CheckIcon>
                    </div>
                </div>
            </OverlayPanel>
        </div>
        <div
            v-if="workflowStore.modelValue.value === SendTextMessageNodeType.custom"
            class="pt-2 flex flex-col gap-y-4"
        >
            <p class="text-sm leading-5 font-normal text-gray-500">
                Send a custom text message to all enrolled contacts phone numbers.
            </p>
            <div
                v-if="!workflowStore.messagePayload?.from && !workflowStore.messagePayload?.body"
                class="flex items-center text-sm leading-5 font-medium text-gray-900 gap-x-1 cursor-pointer"
                @click="openCreateMessageModal"
            >
                <PlusSmIcon class="h-5 w-5"></PlusSmIcon>
                <span>New text message</span>
            </div>
            <div v-else class="flex items-center justify-between">
                <div
                    class="py-2 pl-2 flex flex-col gap-y-2 max-w-[350px] cursor-pointer w-full"
                    @click="openUpdateModal"
                >
                    <span class="text-gray-800"
                        >From: {{ workflowStore.messagePayload?.from?.name }}
                        {{ workflowStore.messagePayload?.from?.number }}</span
                    >
                    <p class="text-sm leading-5 font-normal text-gray-600">
                        {{ workflowStore.messagePayload?.body }}
                    </p>
                </div>
                <TrashIcon class="w-5 h-5 cursor-pointer text-gray-900" @click="deleteTextMessage"></TrashIcon>
            </div>
        </div>
        <div
            v-if="workflowStore.modelValue.value === SendTextMessageNodeType.template"
            class="pt-2 flex flex-col gap-y-4"
        >
            <p class="text-sm contacting-5 font-normal text-gray-500">
                Randomly send a text message template from the selected list/s to all enrolled contacts phone numbers
            </p>
            <div class="w-full flex items-center">
                <span class="text-sm font-medium mr-2">From:</span>
                <ContactDropdown v-model:selectedSender="workflowStore.selectedSender" :senders="senders" type="sms" />
            </div>
            <div class="flex gap-x-4 items-center">
                <div class="flex items-center gap-x-1">
                    <span>Randomise</span>
                    <InformationCircleIcon class="w-5 h-5"></InformationCircleIcon>
                </div>
                <InputSwitch v-model="workflowStore.modelValue.event"></InputSwitch>
            </div>
            <div id="searchOverlayTarget">
                <GgmsInput
                    input-class="bg-gray-100 border-0"
                    v-model="searchTerm"
                    :small="true"
                    :iconEnd="SearchIcon"
                    placeholder="Search"
                    @input="toggleSearchMenu"
                    @click="toggleSearchMenu"
                ></GgmsInput>
                <OverlayPanel
                    ref="searchMenu"
                    class="!left-0 ring-1 ring-black ring-opacity-5 shadow-lg rounded-md bg-white mx-6 right-1"
                    appendTo="#searchOverlayTarget"
                >
                    <div class="flex flex-col max-h-64 overflow-auto w-full top-36">
                        <div class="relative">
                            <div v-if="!data?.length" class="py-6 border-0 px-4">No results found</div>
                            <template v-else>
                                <div
                                    v-for="template in data"
                                    :key="template._id"
                                    class="flex gap-x-2 px-4 top-0 py-3 border-b border-gray-200 last:border-0"
                                    tabindex="0"
                                >
                                    <GgmsCheckBox
                                        :checked="isTemplateChecked(template)"
                                        :classes="['mt-1']"
                                        :centered="false"
                                        @click="onClick(template)"
                                    >
                                        <div class="ml-3 flex flex-col gap-x-2 gap-y-1">
                                            <div class="rounded-full items-center flex">
                                                {{ template.displayName }}
                                            </div>
                                            <div class="flex justify-start">
                                                <GgmsChip
                                                    v-for="tag in template.tags"
                                                    :key="tag._id"
                                                    :bgColor="cachedTags[tag._id]?.backgroundColor"
                                                    :textColor="cachedTags[tag._id]?.foregroundColor"
                                                    :isRemovable="false"
                                                    class="h-6"
                                                    >{{ tag.displayName }}
                                                </GgmsChip>
                                            </div>
                                            <p class="text-gray-400 text-xs contacting-5 font-normal">
                                                {{ formatHumanReadableDate(new Date(template.updatedAt), true) }}
                                            </p>
                                        </div>
                                    </GgmsCheckBox>
                                </div>
                            </template>
                            <GgmsLoading v-if="isSearching" />
                        </div>
                    </div>
                    <div
                        v-if="templateStore.selectedData.length"
                        class="flex justify-between items-center px-3 bg-white h-[54px] w-full rounded-b-md border-t border-gray-200"
                    >
                        <div class="flex items-center gap-x-4">
                            <div class="bg-gray-300 w-4 h-4 rounded-sm cursor-pointer" @click="clearSelectedData">
                                <MinusSmIcon class="w-4 h-4"></MinusSmIcon>
                            </div>
                            <p class="text-base contacting-6 font-medium w-fit">
                                {{ templateStore.selectedData.length }} selected -
                                <span
                                    class="font-medium text-primary-color-600 hover:text-primary-color-500 cursor-pointer"
                                    @click="onSelect"
                                >
                                    {{ selectAllText }}
                                </span>
                            </p>
                        </div>
                        <GgmsButton @click="closeSearchMenu">Done</GgmsButton>
                    </div>
                </OverlayPanel>
            </div>
            <div v-if="showData" class="flex flex-col pt-3">
                <div
                    v-for="template in templateStore.selectedData"
                    :key="template._id"
                    class="flex justify-between items-center border-b border-gray-200 last:border-0 py-2.5 px-2"
                >
                    <div
                        class="ml-3 flex flex-col gap-x-2 gap-y-1 cursor-pointer w-full"
                        @click.prevent.stop="openCreateTemplateModal(template)"
                    >
                        <div class="rounded-full items-center flex">
                            {{ template.displayName }}
                        </div>
                        <div class="flex justify-start">
                            <GgmsChip
                                v-for="tag in template.tags"
                                :key="tag._id"
                                :bgColor="cachedTags[tag._id]?.backgroundColor"
                                :textColor="cachedTags[tag._id]?.foregroundColor"
                                :isRemovable="false"
                                class="h-6"
                                >{{ tag.displayName }}
                            </GgmsChip>
                        </div>
                        <p class="text-gray-400 text-xs contacting-5 font-normal">
                            {{ formatHumanReadableDate(new Date(template.updatedAt), true) }}
                        </p>
                    </div>
                    <TrashIcon class="x-5 h-5 m-1 cursor-pointer" @click="deleteTemplate(template)"></TrashIcon>
                </div>
            </div>
        </div>
    </div>
    <GgmsModal
        :open="isCreatingMessageModalOpen"
        bodyClass="p-0"
        headerClass="border-b border-gray-200 py-5"
        size="3xl"
        @closeModal="closeCreateMessageModal"
        :style="{ zIndex: 9999 }"
    >
        <template #header>
            <div>New Message</div>
        </template>
        <CreatingMessage
            :isWorkflow="true"
            :textMessage="currentMessage"
            @saveTextMessage="saveTextMessage"
        ></CreatingMessage>
    </GgmsModal>

    <GgmsTemplateModal
        :open="isOpenCreateTemplateModal"
        :isCreateTemplate="false"
        :isPreviewOpen="isPreviewOpen"
        :newTemplate="newTemplate"
        :isDomainsDisabled="true"
        :domains="domains"
        :selectedDomains="selectedDomains"
        :tags="tags"
        :selectedTags="selectedTags"
        :showDeleteButton="false"
        :typeDropdownDisabled="true"
        @update:selectedTags="(value) => (selectedTags = value)"
        @update:selectedDomains="(value) => (selectedDomains = value)"
        @closePreview="closePreview"
        @openPreview="openPreview"
        @closeModal="closeCreateTemplateModal"
        @openDelete="openDeleteTemplateModal(newTemplate)"
        @saveTemplate="updateSelectedTemplate(newTemplate)"
    ></GgmsTemplateModal>
</template>
<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue"
import ArrowDown from "@/components/icons/ArrowDown.vue"
import {
    CheckIcon,
    InformationCircleIcon,
    MinusSmIcon,
    PlusSmIcon,
    SearchIcon,
    TrashIcon,
} from "@heroicons/vue/outline"
import GgmsModal from "@/components/GgmsModal.vue"
import CreatingMessage from "@/components/profile-details/middle-panel/CreatingMessage.vue"
import GgmsInput from "@/components/GgmsInput.vue"
import GgmsCheckBox from "@/components/GgmsCheckBox.vue"
import GgmsButton from "@/components/GgmsButton.vue"
import GgmsLoading from "@/components/GgmsLoading.vue"
import GgmsChip from "@/components/GgmsChip.vue"
import GgmsTemplateModal from "@/components/GgmsTemplateModal.vue"
import ContactDropdown from "@/components/profile-details/middle-panel/ContactDropdown.vue"
import { useWorkflowStore } from "@/stores/workflow"
import { useTemplateStore } from "@/stores/template"
import { useAgencyStore } from "@/stores/agency"
import { debounce, formatHumanReadableDate, getUser, stripHtml } from "@/shared/utils/helpers"
import { useTagStore } from "@/stores/tag"
import { Sender } from "@/shared/models/sender"
import { SendTextMessageNodeType } from "@/shared/models/workflow"
import { AgencyDomain } from "@/shared/models/agency"
import { Tag, TagType } from "@/shared/models/tag"
import { GgmsError } from "@/shared/services/client"
import { Template } from "@/shared/models/template"
import { Message } from "@/shared/models/message"

const workflowStore = useWorkflowStore()
const templateStore = useTemplateStore()
const tagStore = useTagStore()
const agencyStore = useAgencyStore()

const isTypeModalOpen = ref(false)
const typeMenu = ref()
const isCreatingMessageModalOpen = ref(false)
const searchTerm = ref("")
const data = ref<Template[]>([])
const isSearching = ref(false)
const isAllSelected = ref(false)
const total = ref(0)
const showData = ref(false)
const searchMenu = ref()
const senders = ref<Sender[]>([])
const user = getUser()

const isPreviewOpen = ref(false)
const isOpenCreateTemplateModal = ref(false)
const newTemplate = ref({} as Template)
const isOpenDeleteTemplateModal = ref(false)
const deleteableTemplate = ref({} as Template)
const isViewingFormatted = ref(true)
const isTextBodyModified = ref(false)
const selectedDomains = ref<AgencyDomain[]>([])
const domains = computed(() => agencyStore.agency.agencyDomains || [])
const isDomainsDisabled = ref(false)
const selectedTags = ref<Tag[]>([])
const tags = computed(() => tagStore.tableState.data || [])
const specialVariable = ref("")
const currentMessage = ref()

const agency = ref(agencyStore.agency)

const cachedTags = computed(() => tagStore.cachedTags)
const selectAllText = computed(() => (isAllSelected.value ? "Deselect all" : `Select all (${total.value})`))

const selectionTypes = [
    { label: "Custom text message", value: SendTextMessageNodeType.custom },
    { label: "Template text message", value: SendTextMessageNodeType.template },
    { label: "Texts containing certain tags", value: SendTextMessageNodeType.containsTags },
    { label: "From list of saved texts", value: SendTextMessageNodeType.listOfSavedTexts },
]

const selectionTitle = computed(() => {
    if (workflowStore.modelValue.value === SendTextMessageNodeType.custom) {
        return "Custom text message"
    }
    if (workflowStore.modelValue.value === SendTextMessageNodeType.template) {
        return "Template text message"
    }
    if (workflowStore.modelValue.value === SendTextMessageNodeType.containsTags) {
        return "Texts containing certain tags"
    }
    return "From list of saved texts"
})

function toggleTypeMenu(event?: Event) {
    if (isTypeModalOpen.value) {
        typeMenu.value.hide()
        isTypeModalOpen.value = false
    } else {
        typeMenu.value.show(event)
        isTypeModalOpen.value = true
    }
}

async function selectType(type: string) {
    typeMenu.value.hide()
    isTypeModalOpen.value = false
    workflowStore.modelValue.value = type
    clearSelectedData()
    await getTemplates()
    total.value = data.value.length
}

function openCreateMessageModal() {
    isCreatingMessageModalOpen.value = true
}

function closeCreateMessageModal() {
    isCreatingMessageModalOpen.value = false
}

function saveTextMessage() {
    isCreatingMessageModalOpen.value = false
}

function deleteTextMessage() {
    workflowStore.messagePayload = {} as Message
}

async function getTemplates() {
    templateStore.tableState.templateType = "sms"
    templateStore.tableState.search = searchTerm.value
    await templateStore.getTemplates()
    data.value = templateStore.tableState.data
}

function isTemplateChecked(template: Template) {
    return !!templateStore.selectedData.find((el) => el._id === template._id)
}

function clearSelectedData() {
    templateStore.selectedData = []
}

function deleteTemplate(template: Template) {
    templateStore.selectedData.splice(templateStore.selectedData.indexOf(template), 1)
}

function onSelect() {
    if (isAllSelected.value) {
        clearSelectedData()
    } else {
        templateStore.selectedData = data.value
    }
    isAllSelected.value = !isAllSelected.value
}

function onClick(template: Template) {
    if (isTemplateChecked(template)) {
        const index = templateStore.selectedData.findIndex((el) => el._id === template._id)
        templateStore.selectedData.splice(index, 1)
        return
    }

    templateStore.selectedData.push(template)
}

function closeSearchMenu() {
    showData.value = true
    searchMenu.value.hide()
    workflowStore.areButtonDisabled = false
}

function toggleSearchMenu(event?: Event) {
    searchMenu.value.show(event)
}

function setOutboundPhoneNumbers() {
    if (user?.outboundPhoneNumber) {
        senders.value.push({
            name: user?.fullName || "",
            phone: user?.outboundPhoneNumber,
        })
    }

    const agencyPhone = agencyStore.agency?.agencyOutboundPhoneNumber
    if (agencyPhone) {
        senders.value.push({
            name: agencyStore.agency?.agencyName || "",
            phone: agencyPhone,
        })
    }

    if (senders.value.length) {
        workflowStore.selectedSender = senders.value?.[0]
        return
    }
}

function closePreview() {
    isPreviewOpen.value = false
}

function openPreview() {
    isPreviewOpen.value = true
}

function closeCreateTemplateModal() {
    templateStore.validationError = {} as GgmsError
    newTemplate.value = {} as Template
    isOpenCreateTemplateModal.value = false
    closePreview()
    isTextBodyModified.value = false
    selectedDomains.value = []
    selectedTags.value = []
    specialVariable.value = ""
}

function openCreateTemplateModal(template: Template) {
    newTemplate.value = Object.assign({}, template)
    newTemplate.value.textBody = stripHtml(newTemplate.value.body)
    isTextBodyModified.value = true
    // add domains and tags to selected
    selectedDomains.value = domains.value.filter((domain) => newTemplate.value?.domainId === domain._id)
    isDomainsDisabled.value = true
    const templateTagIds = newTemplate.value?.tags?.map((tag) => tag._id)
    selectedTags.value = tags.value.filter((tag) => templateTagIds?.includes(tag._id))
    specialVariable.value = template?.displayName || ""
    isViewingFormatted.value = true
    isOpenCreateTemplateModal.value = true
}

function openDeleteTemplateModal(template: Template) {
    if (isOpenCreateTemplateModal.value) {
        closeCreateTemplateModal()
    }
    isOpenDeleteTemplateModal.value = true
    deleteableTemplate.value = template
}

function updateSelectedTemplate(template: Template) {
    const index = templateStore.selectedData.findIndex((el) => el._id === template._id)
    templateStore.selectedData.splice(index, 1)
    const updatedTemplate = templateStore.tableState.data.find((newTemplate) => template._id === newTemplate._id)
    templateStore.selectedData.push(updatedTemplate)
}

function openUpdateModal() {
    currentMessage.value = workflowStore.messagePayload
    openCreateMessageModal()
}

watch(
    () => workflowStore.messagePayload,
    () => {
        workflowStore.areButtonDisabled = !(workflowStore.messagePayload?.from && workflowStore.messagePayload?.body)
    }
)

watch(
    () => [templateStore.selectedData.length, workflowStore.modelValue.event],
    () => {
        if (!templateStore.selectedData.length) {
            workflowStore.areButtonDisabled = true
            return
        }
        workflowStore.areButtonDisabled = false
    }
)

watch(
    () => searchTerm.value,
    debounce(async () => {
        isSearching.value = true
        await getTemplates()
        isSearching.value = false
    }, 250)
)

onMounted(async () => {
    if (templateStore.selectedData.length) {
        showData.value = true
    }
    setOutboundPhoneNumbers()
    await getTemplates()
    tagStore.tableState.typeName = TagType.messageTemplate
    await tagStore.getTags()
    total.value = data.value.length
})
</script>
