import { defineStore } from "pinia"
import { Agent } from "@/shared/models/agent"
import { AgentService } from "@/shared/services/agent"
import { TableState } from "@/shared/models/table"
import { GgmsError } from "@/shared/services/client"
import { ToastService } from "@/shared/services/toast"
import { UploadFile, UploadItem } from "@/shared/models/upload-file"
import { User } from "@/shared/models/user"
import { useAuthStore } from "@/stores/auth"
import { setCookie } from "@/shared/utils/helpers"
import { useGridCollectionStore } from "@/stores/grid-collection"

export const useAgentsStore = defineStore("agents", {
    state: () => ({
        tableState: {
            data: [],
            selectedData: [],
            currentPage: 0,
            lastPage: 0,
            pageLength: 50,
            numberOfPages: 0,
            total: 0,
            isAllSelected: false,
            firstIds: [],
        } as TableState<Agent>,
        agentService: new AgentService(),
        toastService: new ToastService(),
        isLoading: false,
        validationError: {} as GgmsError,
        authStore: useAuthStore(),
        cachedAgents: [] as Agent[],
        gridCollectionStore: useGridCollectionStore(),
    }),
    getters: {
        activeAgents(): { name: string; id: string }[] {
            return this.tableState.data
                .filter((agent: Agent) => agent.status !== "pending" && agent.fullName && agent._id)
                .map((agent: Agent) => ({ name: agent.fullName, id: agent._id }))
        },
    },
    actions: {
        async loadAgents(isFromCollection = false) {
            try {
                this.tableState.isLoading = true

                let lastId = null
                let offset = null

                // If we're going to the next page, use keyset pagination.
                if (this.tableState.currentPage === (this.tableState?.lastPage ?? 0) + 1) {
                    lastId =
                        this.tableState.data.length > 0
                            ? this.tableState.data[this.tableState.data?.length - 1]._id
                            : null
                }
                // If we're jumping to a specific page, use offset-based pagination.
                else if (
                    this.tableState.currentPage !== (this.tableState?.lastPage ?? 0) + 1 &&
                    this.tableState.currentPage > 1
                ) {
                    offset = (this.tableState.currentPage - 1) * this.tableState.pageLength
                }

                if (!this.gridCollectionStore?.grid?.filtersGroups?.length && isFromCollection) {
                    this.tableState.data = []
                    this.tableState.total = 0
                    this.tableState.selectedData = []
                    this.tableState.numberOfPages = Math.ceil(1)
                    this.tableState.lastPage = this.tableState.currentPage
                    return
                }

                const filtersGroups = this.gridCollectionStore?.grid?.filtersGroups?.length
                    ? encodeURIComponent(JSON.stringify(this.gridCollectionStore.grid.filtersGroups))
                    : undefined

                const response = await this.agentService.getAll({
                    length: this.tableState.pageLength,
                    column: this.tableState.column,
                    order: this.tableState.order,
                    search: this.tableState.search,
                    filtersGroups,
                    lastId,
                    offset,
                })

                if (response && response.data) {
                    this.tableState.data = response.data
                    this.tableState.total = response.total
                    this.tableState.selectedData = []
                    this.tableState.numberOfPages = Math.ceil(response.total / this.tableState.pageLength)
                    this.tableState.lastPage = this.tableState.currentPage
                } else {
                    this.tableState.data = []
                    this.tableState.total = 0
                    this.tableState.selectedData = []
                    this.tableState.numberOfPages = 0
                    this.tableState.lastPage = 0
                }
            } finally {
                this.tableState.isLoading = false
            }
        },

        resetTableState() {
            this.tableState.data = []
            this.tableState.total = 0
            this.tableState.selectedData = []
            this.tableState.numberOfPages = Math.ceil(1)
        },

        async getAgents(searchString: string | undefined) {
            const response = await this.agentService.search({ search: searchString })
            if (!searchString) {
                this.cachedAgents = response.data
            }
            return response.data
        },

        async searchAgentsPermissions(ids: string[], search: string) {
            try {
                this.isLoading = true
                const notInIds = ids.length ? ids?.join(",") : undefined
                const agents = await this.agentService.searchPermissions(search, notInIds, this.tableState.pageLength)

                return agents.data
            } finally {
                this.isLoading = false
            }
        },

        async searchAgentsRecipients(ids: string[], search: string) {
            try {
                this.isLoading = true
                const notInIds = ids.length ? ids?.join(",") : undefined
                const agents = await this.agentService.searchRecipients(search, notInIds, this.tableState.pageLength)

                return agents.data
            } finally {
                this.isLoading = false
            }
        },

        async getAgentsGrid(hasFilters?: boolean) {
            try {
                this.tableState.isLoading = true
                const response = await this.gridCollectionStore.getGrid("agents")

                await this.loadAgents()
                return response
            } finally {
                this.tableState.isLoading = false
            }
        },

        async updateAgentsGrid() {
            try {
                this.tableState.isLoading = true
                if (!this.gridCollectionStore.grid) return
                this.gridCollectionStore.grid.filtersGroups = this.gridCollectionStore.filtersGroups || []

                const response = await this.gridCollectionStore.updateGrid("agents", this.gridCollectionStore.grid)
                this.gridCollectionStore.gridResponse = response
                this.gridCollectionStore.grid = this.gridCollectionStore.gridResponse.grid
                this.gridCollectionStore.filtersGroups = this.gridCollectionStore.grid.filtersGroups

                await this.loadAgents()
            } finally {
                this.tableState.isLoading = false
            }
        },

        clearFilters() {
            this.gridCollectionStore.grid.filtersGroups = []
            this.gridCollectionStore.filtersGroups = []

            this.updateAgentsGrid()
        },

        async validatePassword(password: string): Promise<boolean> {
            try {
                this.isLoading = true
                this.validationError = {} as GgmsError
                const res = await this.agentService.validatePassword(password)
                if (!res.isValid) {
                    this.validationError.message = "Password is not valid"
                    return false
                }
                return true
            } catch (error) {
                this.validationError = error as GgmsError
                return false
            } finally {
                this.isLoading = false
            }
        },

        async changePassword(password: string, confirmPassword: string): Promise<boolean> {
            try {
                this.isLoading = true
                if (password !== confirmPassword) {
                    this.validationError.message = "Passwords do not match"
                    return false
                }
                const res = await this.agentService.changePassword(password)
                if (res) {
                    this.toastService.addToast({
                        message: "Password changed successfully",
                        type: "success",
                    })
                }
                return true
            } catch (error) {
                this.validationError = error as GgmsError
                return false
            } finally {
                this.isLoading = false
            }
        },

        async requestPostDataForFileUpload(uploads: UploadItem[], files: File[]): Promise<UploadFile[]> {
            const response = await this.agentService.requestPostDataForFileUpload(uploads)
            let uploadedFiles = []
            if (!response) throw new Error("Failed to upload files")
            uploadedFiles = await Promise.all(
                response.presignedPostUrls.map((file, index) => {
                    const formData = new FormData()
                    Object.entries(file.fields).forEach(([key, value]) => {
                        formData.append(key, value)
                    })
                    formData.append("file", files[index])
                    return this.agentService.uploadFiles(file.url, formData)
                })
            )
            uploadedFiles = uploadedFiles.map((file) => file?.data?.upload)

            return uploadedFiles
        },

        async updateAgent(agent: Partial<User>, showMessage = true) {
            try {
                this.isLoading = true
                this.validationError = {} as GgmsError
                const res = await this.agentService.updateAgent(agent)
                if (!res) throw new Error("Failed to update agent")
                if (showMessage) {
                    this.toastService.addToast({
                        message: "Agent updated successfully",
                        type: "success",
                    })
                }
                return res
            } catch (error) {
                this.validationError = error as GgmsError
            } finally {
                this.isLoading = false
            }
        },

        async updateAgentRole(agentId: string, role: string) {
            try {
                this.isLoading = true
                const res = await this.agentService.updateAgentRole(agentId, role)
                if (res) {
                    this.toastService.addToast({
                        message: "Agent role updated successfully",
                        type: "success",
                    })
                }
                return res
            } finally {
                this.isLoading = false
            }
        },

        async getMe() {
            try {
                const response = await this.agentService.getMe()
                if (response && response.agent) {
                    const me = response.agent
                    this.authStore.currentUser = me
                    setCookie("user", JSON.stringify(me), true)
                    return me
                }
            } catch (error) {
                console.log(error)
            }
        },

        async inviteAgent(email: string, role: string) {
            try {
                this.isLoading = true
                const res = await this.agentService.inviteAgent(email, role)
                if (res.agent) {
                    this.toastService.addToast({
                        message: "Agent invited successfully",
                        type: "success",
                    })
                }
                return res.agent
            } finally {
                this.isLoading = false
            }
        },

        async disableAgent(userId: string, agencyId: string) {
            try {
                this.isLoading = true
                const res = await this.agentService.disableAgent(userId, agencyId)
                if (res) {
                    this.toastService.addToast({
                        message: "Agent disabled successfully",
                        type: "success",
                    })
                }
                return res
            } finally {
                this.isLoading = false
            }
        },

        async removeAgent(userId: string, agencyId: string) {
            try {
                this.isLoading = true
                const res = await this.agentService.removeAgent(userId, agencyId)
                if (res) {
                    this.toastService.addToast({
                        message: "Agent removed successfully",
                        type: "success",
                    })
                }
                return res
            } finally {
                this.isLoading = false
            }
        },

        async getAgentByEmailCode(emailCode: string) {
            return await this.agentService.getAgentByEmailCode(emailCode)
        },

        async resendEmailVerification() {
            return await this.agentService.resendEmailVerification()
        },

        async getAgentById(id: string) {
            const agentResp = await this.agentService.getOne(id)
            return agentResp.agent
        },

        async checkAgentList(typename: string, entityIds: string[]) {
            try {
                return this.agentService.checkAgentList(typename, entityIds)
            } catch (error) {
                const err = error as GgmsError
                if (err.code === "ValidationError") {
                    this.toastService.addToast({
                        type: "error",
                        message: err.message,
                    })
                }
            }
        },
    },
})
