<template>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css" />
    <div v-if="editor" class="container w-auto">
        <div class="control-group mb-4">
            <div
                class="button-group toolbar flex flex-wrap gap-2 bg-gray-100 p-2 rounded-md shadow border border-gray-200"
            >
                <!-- Bold, Italic, Strike -->
                <button
                    @click="editor.chain().focus().toggleBold().run()"
                    :disabled="!editor.can().chain().focus().toggleBold().run()"
                    :class="['toolbar-button', editor.isActive('bold') ? 'active' : '']"
                >
                    <i class="fas fa-bold"></i>
                </button>
                <button
                    @click="editor.chain().focus().toggleItalic().run()"
                    :disabled="!editor.can().chain().focus().toggleItalic().run()"
                    :class="['toolbar-button', editor.isActive('italic') ? 'active' : '']"
                >
                    <i class="fas fa-italic"></i>
                </button>
                <button
                    @click="editor.chain().focus().toggleStrike().run()"
                    :disabled="!editor.can().chain().focus().toggleStrike().run()"
                    :class="['toolbar-button', editor.isActive('strike') ? 'active' : '']"
                >
                    <i class="fas fa-strikethrough"></i>
                </button>

                <!-- Bullet List, Ordered List, Blockquote -->
                <button
                    @click="editor.chain().focus().toggleBulletList().run()"
                    :class="['toolbar-button', editor.isActive('bulletList') ? 'active' : '']"
                >
                    <i class="fas fa-list-ul"></i>
                </button>
                <button
                    @click="editor.chain().focus().toggleOrderedList().run()"
                    :class="['toolbar-button', editor.isActive('orderedList') ? 'active' : '']"
                >
                    <i class="fas fa-list-ol"></i>
                </button>
                <button
                    @click="toggleBlockquote"
                    :class="['toolbar-button', editor.isActive('blockquote') ? 'active' : '']"
                >
                    <i class="fas fa-quote-right"></i>
                </button>

                <!-- Link, Image, Video with OverlayPanel -->
                <button @click="openLinkPanel($event)" class="toolbar-button">
                    <i class="fas fa-link"></i>
                </button>
                <button @click="openVideoPanel($event)" class="toolbar-button">
                    <i class="fas fa-film"></i>
                </button>
                <button @click="openImagePanel($event)" class="toolbar-button">
                    <i class="fas fa-image"></i>
                </button>

                <!-- Link Overlay Panel -->
                <OverlayPanel ref="linkPanel">
                    <div class="p-4">
                        <label class="font-semibold mb-2 block">Enter Link URL:</label>
                        <input v-model="linkUrl" type="text" class="modal-input" />
                        <div class="modal-buttons">
                            <button @click="confirmLink" class="modal-button primary-button w-full">Confirm</button>
                            <button
                                @click="closeOverlayPanel('linkPanel')"
                                class="modal-button secondary-button w-full"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </OverlayPanel>

                <!-- Video Overlay Panel -->
                <OverlayPanel ref="videoPanel">
                    <div class="p-4">
                        <label class="font-semibold mb-2 block">Enter Video Embed URL:</label>
                        <input v-model="videoUrl" type="text" class="modal-input" />
                        <div class="modal-buttons">
                            <button @click="confirmVideo" class="modal-button primary-button w-full">Confirm</button>
                            <button
                                @click="closeOverlayPanel('videoPanel')"
                                class="modal-button secondary-button w-full"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </OverlayPanel>

                <!-- Image Overlay Panel -->
                <OverlayPanel ref="imagePanel">
                    <div class="p-4">
                        <label class="font-semibold mb-2 block">Upload Image:</label>
                        <input type="file" @change="handleImageUpload" class="modal-input" />
                        <div class="modal-buttons">
                            <button
                                @click="closeOverlayPanel('imagePanel')"
                                class="modal-button secondary-button w-full"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </OverlayPanel>

                <!-- Alignment -->
                <button @click="editor.chain().focus().setTextAlign('left').run()" class="toolbar-button">
                    <i class="fas fa-align-left"></i>
                </button>
                <button @click="editor.chain().focus().setTextAlign('center').run()" class="toolbar-button">
                    <i class="fas fa-align-center"></i>
                </button>
                <button @click="editor.chain().focus().setTextAlign('right').run()" class="toolbar-button">
                    <i class="fas fa-align-right"></i>
                </button>

                <!-- Undo, Redo -->
                <button
                    @click="editor.chain().focus().undo().run()"
                    :disabled="!editor.can().chain().focus().undo().run()"
                    class="toolbar-button"
                >
                    <i class="fas fa-undo"></i>
                </button>
                <button
                    @click="editor.chain().focus().redo().run()"
                    :disabled="!editor.can().chain().focus().redo().run()"
                    class="toolbar-button"
                >
                    <i class="fas fa-redo"></i>
                </button>

                <!-- Add Listings Button -->
                <button @click="openListingsPanel($event)" class="toolbar-button">
                    <OfficeBuildingIcon class="text-gray-500" />
                </button>

                <!-- Listings Overlay Panel -->
                <OverlayPanel ref="listingsPanel">
                    <div class="p-4 w-96 shadow-lg">
                        <h3 class="font-semibold mb-4 text-lg text-primary-color-700">Select Listings to Add</h3>

                        <div v-if="isLoading" class="flex items-center justify-center h-32">
                            <i class="fas fa-spinner fa-spin text-primary-color-600 text-3xl"></i>
                        </div>

                        <div v-else>
                            <div class="grid grid-cols-1 gap-4 max-h-64 overflow-y-auto">
                                <div
                                    v-for="(listing, index) in listingsStore.tableState.data"
                                    :key="listing.listingId"
                                    class="flex items-center gap-4 p-3 border rounded-lg hover:bg-gray-50 transition-shadow shadow-sm cursor-pointer"
                                    @click="toggleListingSelection(index)"
                                >
                                    <img
                                        :src="
                                            listing?.attributes?.media[0]?.mediaURL
                                                ? listing.attributes?.media[0].mediaURL
                                                : require('@/assets/icons/no_image.png')
                                        "
                                        alt="Listing Image"
                                        class="w-16 h-16 rounded-md object-cover"
                                    />
                                    <div class="flex-1">
                                        <p class="font-medium text-gray-800">{{ listing.listingId }}</p>
                                    </div>
                                    <!-- Custom GgmsCheckBox with independent state tracking and click stop propagation -->
                                    <GgmsCheckBox
                                        v-model="selectedState[index]"
                                        :label="''"
                                        @click.stop
                                        @update:modelValue="() => updateSelectedListings(index)"
                                    />
                                </div>
                            </div>

                            <div class="modal-buttons mt-4">
                                <button @click="confirmListings" class="modal-button primary-button w-full">
                                    Add Listings
                                </button>
                                <button
                                    @click="closeOverlayPanel('listingsPanel')"
                                    class="modal-button secondary-button w-full"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </OverlayPanel>

                <button @click="openMergeFieldsModal" class="toolbar-button">
                    <DocumentTextIcon class="h-5 w-5 text-gray-500" />
                </button>
                <!-- Add Merge Fields Button -->
                <GgmsMergeFields
                    :open="isMergeFieldsModalOpen"
                    :isForRichEditor="true"
                    :specialVariable="specialVariable"
                    :isTemplateTypeEmail="true"
                    @add-merge-field="addMergeFieldToTextMessage"
                    @closeModal="closeMergeFieldsModal"
                />
            </div>
        </div>
        <editor-content
            :editor="editor"
            v-model="body"
            class="editor-content border border-gray-300 rounded-md shadow-sm min-h-48"
        />
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch, defineProps, defineEmits } from "vue"

// Import Tiptap Editor and Extensions
import { Editor, EditorContent } from "@tiptap/vue-3"
import { Color } from "@tiptap/extension-color"
import ListItem from "@tiptap/extension-list-item"
import Placeholder from "@tiptap/extension-placeholder"
import TextStyle from "@tiptap/extension-text-style"
import StarterKit from "@tiptap/starter-kit"
import TextAlign from "@tiptap/extension-text-align"
import Link from "@tiptap/extension-link"
import ResizableImage from "@/components/rich-editor-extensions/ResizableImage"
import Youtube from "@tiptap/extension-youtube"

// Import Table Extensions
import Table from "@tiptap/extension-table"
import TableRow from "@tiptap/extension-table-row"
import TableCell from "@tiptap/extension-table-cell"
import TableHeader from "@tiptap/extension-table-header"

// Import PrimeVue Components
import OverlayPanel from "primevue/overlaypanel"

// Import Heroicons
import { OfficeBuildingIcon, DocumentTextIcon } from "@heroicons/vue/outline"

// Import Stores
import { useListingStore } from "@/stores/listings"

// Import Custom Components
import GgmsCheckBox from "@/components/GgmsCheckBox.vue"
import GgmsMergeFields from "@/components/GgmsMergeFields.vue"

// Import Models
import { Listing } from "@/shared/models/listing"

const props = defineProps({
    modelValue: {
        type: String,
        default: "",
    },
    errorMessage: {
        type: String,
        default: "",
    },
    placeholder: {
        type: String,
        default: "Write your email here...",
    },
})

const emit = defineEmits(["update:modelValue", "disableValidationError"])

const listingsStore = useListingStore()

const editor = ref<Editor | null>(null)
const linkUrl = ref<string>("")
const videoUrl = ref<string>("")
const body = ref<string>(props.modelValue)

// listings
const selectedListings = ref<Listing[]>([])
const selectedState = ref<boolean[]>([])

// merge fields
const specialVariable = ref("")

// Refs for OverlayPanels
const linkPanel = ref<OverlayPanel | null>(null)
const videoPanel = ref<OverlayPanel | null>(null)
const imagePanel = ref<OverlayPanel | null>(null)
const listingsPanel = ref<OverlayPanel | null>(null)

// Loading
const isLoading = ref<boolean>(false)

function openListingsPanel(event: Event) {
    listingsPanel.value?.toggle(event)
}

const isMergeFieldsModalOpen = ref(false)

function openMergeFieldsModal() {
    specialVariable.value = ""
    isMergeFieldsModalOpen.value = true
}

function closeMergeFieldsModal() {
    isMergeFieldsModalOpen.value = false
}

function confirmListings() {
    const editorInstance = editor.value

    if (selectedListings.value.length > 0 && editorInstance) {
        // Get the current editor content as HTML
        let currentHTML = editorInstance.getHTML()

        // Build a new table HTML with the selected listings, with email-friendly inlined styles
        let newTableHTML = `
            <table style="border-collapse: collapse; width: 100%; border: 1px solid #ddd;" cellpadding="10" cellspacing="0" border="1">
                <thead>
                    <tr style="background-color: #f0f0f0;">
                        <th style="border: 1px solid #ddd; padding: 12px; text-align: center;">Image</th>
                        <th style="border: 1px solid #ddd; padding: 12px; text-align: center;">Listing ID</th>
                    </tr>
                </thead>
                <tbody>
        `

        selectedListings.value.forEach((listing) => {
            const linkURL = `http://localhost:3000/listings/${listing.listingId}`
            const imageURL = listing.attributes?.media[0]?.mediaURL
                ? listing.attributes?.media[0].mediaURL
                : require("@/assets/icons/no_image.png")

            newTableHTML += `
                <tr>
                    <td style="border: 1px solid #ddd; padding: 12px; text-align: center; vertical-align: middle;">
                        <img src="${imageURL}" alt="Listing Image" width="320" height="180" style="width: 320px; height: 180px; display: block; border: 1px solid #ccc;" />
                    </td>
                    <td style="border: 1px solid #ddd; padding: 12px; text-align: center; vertical-align: middle;">
                        <div style="display: flex; align-items: center; justify-content: center; height: 100%;">
                            <a href="${linkURL}" target="_blank" style="text-decoration: none; color: #1a0dab;">${listing.listingId}</a>
                        </div>
                    </td>
                </tr>
            `
        })

        newTableHTML += `
                </tbody>
            </table>
        `

        // Append the new table HTML to the existing editor content
        currentHTML += newTableHTML

        // Set the updated content back to the editor
        editorInstance.commands.setContent(currentHTML)

        // Update the body ref to sync with the editor content
        body.value = addListingsImageAttributes(editorInstance.getHTML())

        // Close the overlay panel and reset the selections
        closeOverlayPanel("listingsPanel")
    }
}

function addListingsImageAttributes(html) {
    return html.replace(/<img([^>]+)>/g, (match, attrs) => {
        if (!/width="100"/.test(attrs)) {
            attrs += ' width="320" height="180" style="max-width: 100%; max-height: 100%; display: block;"'
        }
        return `<img${attrs}>`
    })
}

// Toggle the selected state of a listing when clicking anywhere in the container
function toggleListingSelection(index: number) {
    selectedState.value[index] = !selectedState.value[index]
    updateSelectedListings(index)
}

// Update selected listings when checkboxes change
function updateSelectedListings(index: number) {
    const listing = listingsStore.tableState.data[index]
    if (selectedState.value[index]) {
        selectedListings.value.push(listing)
    } else {
        selectedListings.value = selectedListings.value.filter((item) => item.listingId !== listing.listingId)
    }
}

function addMergeFieldToTextMessage(mergeField: string) {
    if (editor.value) {
        editor.value.chain().focus().insertContent(mergeField).run()
    }
}

// Synchronize body with parent component
watch(body, (newValue) => {
    emit("update:modelValue", newValue)
})

// Watch for changes in props and update body accordingly
watch(
    () => props.modelValue,
    (newValue) => {
        if (newValue !== body.value) {
            body.value = newValue
            if (editor.value) {
                editor.value.commands.setContent(newValue)
            }
        }
    }
)

onMounted(async () => {
    try {
        isLoading.value = true
        editor.value = new Editor({
            content: body.value,
            extensions: [
                Color.configure({ types: [TextStyle.name, ListItem.name] }),
                TextStyle.configure({ types: [ListItem.name] }),
                StarterKit,
                Placeholder.configure({
                    placeholder: props.placeholder,
                }),
                TextAlign.configure({
                    types: ["heading", "paragraph"],
                }),
                Link.configure({
                    autolink: false,
                    openOnClick: true,
                }).extend({
                    inclusive: false,
                }),
                ResizableImage,
                Youtube.configure({
                    controls: true,
                    width: 640,
                    height: 480,
                    HTMLAttributes: {
                        class: "responsive-video",
                    },
                }),
                Table.configure({
                    resizable: true,
                }),
                TableRow,
                TableHeader,
                TableCell,
            ],
            editorProps: {
                handlePaste: (view, event) => {
                    const text = event.clipboardData?.getData("text/plain")
                    const isYoutube =
                        text?.startsWith("https://www.youtube.com/watch?v=") || text?.startsWith("https://youtu.be/")

                    // Check if the pasted text is a YouTube URL
                    if (text && isYoutube) {
                        event.preventDefault() // Prevent default paste behavior

                        // Format the URL for embedding
                        let formattedUrl = text
                        if (text.includes("youtube.com/watch?v=")) {
                            formattedUrl = text.replace("watch?v=", "embed/")
                        } else if (text.includes("youtu.be/")) {
                            const videoId = text.split("youtu.be/")[1]
                            formattedUrl = `https://www.youtube.com/embed/${videoId}`
                        }

                        // Insert video with paragraphs before and after
                        editor.value
                            ?.chain()
                            .focus()
                            .insertContent([
                                { type: "paragraph" },
                                {
                                    type: "youtube",
                                    attrs: {
                                        src: formattedUrl,
                                        class: "responsive-video",
                                    },
                                },
                                { type: "paragraph" },
                            ])
                            .run()

                        return true // Indicate that the paste was handled
                    }

                    // Check if the pasted text is a valid URL
                    const urlRegex = /^(https?:\/\/[^\s]+)$/
                    if (text && urlRegex.test(text) && !isYoutube) {
                        event.preventDefault() // Prevent the default paste behavior

                        // Insert the text as a clickable link
                        editor.value
                            ?.chain()
                            .focus()
                            .insertContent({
                                type: "text",
                                text: text,
                                marks: [
                                    {
                                        type: "link",
                                        attrs: {
                                            href: text,
                                            target: "_blank",
                                            rel: "noopener noreferrer",
                                        },
                                    },
                                ],
                            })
                            .run()

                        return true // Indicate the paste event was handled
                    }

                    if (text && (text.startsWith("http://") || text.startsWith("https://"))) {
                        event.preventDefault() // Prevent the default behavior
                        editor.value?.chain().focus().insertContent(text).run() // Insert as plain text
                        return true // Indicate the paste event is handled
                    }

                    return false // Let the default behavior handle non-video content
                },
            },
            onUpdate: ({ editor }) => {
                body.value = editor.getHTML()
            },
        })
        if (listingsStore.tableState.data.length === 0) {
            await listingsStore.getAll()
        }

        // Initialize selectedState to false for all listings
        selectedState.value = listingsStore.tableState.data.map(() => false)
        selectedListings.value = [] // Initially, no listings are selected
    } finally {
        isLoading.value = false
    }
})

// Functions to open panels
function openLinkPanel(event: Event) {
    const { href } = editor.value?.getAttributes("link") || {}
    linkUrl.value = href ? href : ""
    linkPanel.value?.toggle(event)
}

function openVideoPanel(event: Event) {
    videoPanel.value?.toggle(event)
}

function openImagePanel(event: Event) {
    imagePanel.value?.toggle(event)
}

// Function to toggle blockquote
function toggleBlockquote() {
    if (editor.value) {
        if (editor.value.isActive("blockquote")) {
            editor.value.chain().focus().toggleBlockquote().run()
        } else {
            editor.value.chain().focus().toggleBlockquote().run()

            // Insert help text after a short delay to indicate how to exit
            setTimeout(() => {
                editor.value
                    ?.chain()
                    .focus()
                    .insertContent([
                        {
                            type: "paragraph",
                            content: [
                                {
                                    type: "text",
                                    text: "Tip: Press Enter twice to exit the quote. Delete this before sending the message.",
                                    marks: [{ type: "italic" }],
                                },
                            ],
                        },
                    ])
                    .run()
            }, 10)
        }
    }
}

// Function to close overlay panels
function closeOverlayPanel(panelName: string) {
    switch (panelName) {
        case "linkPanel":
            linkPanel.value?.hide()
            break
        case "videoPanel":
            videoPanel.value?.hide()
            break
        case "imagePanel":
            imagePanel.value?.hide()
            break
        case "listingsPanel":
            listingsPanel.value?.hide()

            // Reset selected listings
            selectedState.value = listingsStore.tableState.data.map(() => false)
            selectedListings.value = [] // Initially, no listings are selected
            break
    }
}

// Function to confirm a link
function confirmLink() {
    if (linkUrl.value) {
        if (!linkUrl.value.startsWith("http://") && !linkUrl.value.startsWith("https://")) {
            linkUrl.value = `https://${linkUrl.value}`
        }
        if (editor.value?.isActive("link")) {
            editor.value.chain().focus().extendMarkRange("link").setLink({ href: linkUrl.value }).run()
        } else {
            editor.value?.chain().focus().setLink({ href: linkUrl.value }).run()
        }
        linkUrl.value = ""
        closeOverlayPanel("linkPanel")
    }
}

// Function to confirm a video embed
function confirmVideo() {
    if (videoUrl.value) {
        let videoId = ""

        if (videoUrl.value.includes("youtube.com/watch?v=")) {
            videoId = videoUrl.value.split("watch?v=")[1]
        } else if (videoUrl.value.includes("youtu.be/")) {
            videoId = videoUrl.value.split("youtu.be/")[1]
        }

        if (videoId) {
            // Format the URL for embedding in an iframe
            const embedUrl = `https://www.youtube.com/embed/${videoId}`

            // Insert the YouTube video as an iframe
            editor.value
                ?.chain()
                .focus()
                .insertContent([
                    { type: "paragraph" },
                    {
                        type: "youtube",
                        attrs: {
                            src: embedUrl,
                            width: 640,
                            height: 360,
                            controls: true,
                            allowfullscreen: true,
                        },
                    },
                    { type: "paragraph" },
                ])
                .run()
        }

        videoUrl.value = ""
        closeOverlayPanel("videoPanel")
    }
}

// Handle image upload
function handleImageUpload(event: Event) {
    const input = event.target as HTMLInputElement
    const file = input?.files ? input.files[0] : null
    if (file) {
        const reader = new FileReader()
        reader.onload = (e) => {
            const url = e.target?.result as string
            editor.value
                ?.chain()
                .focus()
                .insertContent([
                    { type: "paragraph" },
                    {
                        type: "image",
                        attrs: {
                            src: url,
                            width: 320,
                            height: 180,
                        },
                    },
                    { type: "paragraph" },
                ])
                .run()
        }
        reader.readAsDataURL(file)
        closeOverlayPanel("imagePanel")
    }
}
</script>

<style scoped>
/* Toolbar button styles using Tailwind + ::v-deep to target elements inside the editor */
::v-deep .toolbar-button {
    @apply bg-white border border-gray-300 rounded-md p-2 flex items-center justify-center transition-all duration-200 hover:bg-gray-200 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed w-8 h-8;
}

/* Active button styles */
::v-deep .toolbar-button.active {
    @apply bg-blue-200 border-blue-500;
}

/* Editor content styles */
::v-deep .editor-content {
    @apply flex flex-col flex-1 p-4 border border-gray-300 rounded-md shadow-sm min-h-48 cursor-text;
}

::v-deep .editor-content .ProseMirror {
    min-height: 100%;
    flex-grow: 1;
    outline: none; /* Remove any default focus outline */
}

::v-deep .editor-content img {
    max-width: 100%; /* Prevent images from exceeding the container width */
    height: auto; /* Maintain aspect ratio */
    display: block;
}

::v-deep .editor-content iframe {
    @apply w-full h-auto aspect-video;
}

/* Table styles */
::v-deep .editor-content table {
    @apply border-collapse m-0 overflow-hidden table-fixed w-full;
}

::v-deep .editor-content td,
::v-deep .editor-content th {
    @apply border border-gray-300 box-border min-w-[1em] p-1.5 relative align-top;
}

::v-deep .editor-content td > *,
::v-deep .editor-content th > * {
    @apply mb-0;
}

::v-deep .editor-content th {
    @apply bg-gray-100 font-bold text-left;
}

::v-deep .editor-content .selectedCell:after {
    @apply bg-gray-200 content-[''] left-0 right-0 top-0 bottom-0 pointer-events-none absolute z-10;
}

::v-deep .editor-content .column-resize-handle {
    @apply bg-purple-500 bottom-[-2px] pointer-events-none absolute right-[-2px] top-0 w-1;
}

::v-deep .editor-content .tableWrapper {
    @apply my-6 overflow-x-auto;
}

::v-deep .editor-content.resize-cursor {
    @apply cursor-ew-resize cursor-col-resize;
}

/* Heading styles */
/* ::v-deep .tiptap h1,
::v-deep .tiptap h2,
::v-deep .tiptap h3,
::v-deep .tiptap h4,
::v-deep .tiptap h5,
::v-deep .tiptap h6 {
    line-height: 1.1;
    margin-top: 2.5rem;
}

::v-deep .tiptap h1,
::v-deep .tiptap h2 {
    margin-top: 3.5rem;
    margin-bottom: 1.5rem;
}

::v-deep .tiptap h1 {
    font-size: 1.4rem;
}

::v-deep .tiptap h2 {
    font-size: 1.2rem;
}

::v-deep .tiptap h3 {
    font-size: 1.1rem;
}

::v-deep .tiptap h4,
::v-deep .tiptap h5,
::v-deep .tiptap h6 {
    font-size: 1rem;
} */

/* Linked text style */
::v-deep .tiptap a {
    @apply text-blue-600 underline hover:text-blue-800 cursor-pointer;
}

/* YouTube Thumbnail Styling */
::v-deep img.youtube-thumbnail {
    position: relative;
    max-width: 100%;
    cursor: pointer;
}

::v-deep img.youtube-thumbnail::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin-top: -30px;
    margin-left: -30px;
    background: url("https://upload.wikimedia.org/wikipedia/commons/7/7e/Youtube_play_button_icon_%282013-2017%29.svg")
        no-repeat center;
    background-size: cover;
    opacity: 0.8;
}

::v-deep .tiptap pre {
    background: #2d2d2d; /* Dark gray background for code blocks */
    border-radius: 0.5rem; /* Rounded corners */
    color: #f8f8f2; /* Lighter text color for contrast */
    font-family: "JetBrainsMono", monospace; /* Monospace font for readability */
    padding: 1rem; /* More padding for larger code blocks */
    margin: 1.5rem 0; /* Separate code blocks from the rest of the content */
    overflow-x: auto; /* Scrollbar for long code lines */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for elevation */
}

/* Enhanced Blockquote Styles */
::v-deep .tiptap blockquote {
    border-left: 4px solid #4f46e5; /* Purple border for blockquote */
    background-color: #f9fafb; /* Light gray background for quote content */
    padding: 1rem; /* Padding inside the blockquote */
    margin: 1.5rem 0; /* Margin to separate from other content */
    font-style: italic; /* Italic text for blockquotes */
    color: #374151; /* Dark gray for readability */
    border-radius: 0.375rem; /* Rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05); /* Subtle shadow for depth */
}

/* List styles */
::v-deep .tiptap ul {
    list-style-type: disc; /* Ensure unordered lists use bullet points */
    list-style-position: outside; /* Position the bullets outside the list item */
    padding-left: 1.5rem; /* Add padding for better spacing */
    margin: 1.25rem 1rem 1.25rem 1rem; /* Adjust margins to give more space */
}

::v-deep .tiptap ol {
    list-style-type: decimal; /* Ensure ordered lists use numbers */
    list-style-position: outside; /* Position the numbers outside the list item */
    padding-left: 1.5rem; /* Add padding for better spacing */
    margin: 1.25rem 1rem 1.25rem 1rem; /* Adjust margins to give more space */
}

::v-deep .tiptap ul li,
::v-deep .tiptap ol li {
    padding-left: 0.5rem; /* Add some padding to list items for better spacing */
}

::v-deep .tiptap ul li p,
::v-deep .tiptap ol li p {
    margin-top: 0.25em;
    margin-bottom: 0.25em;
}

/* Placeholder (at the top) */
::v-deep .tiptap p.is-editor-empty:first-child::before {
    color: #9ca3af; /* Light gray for placeholder text */
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
}

/* Modal Styles */
.modal-input {
    @apply mb-4 p-2 border border-gray-300 rounded-md w-full;
    transition: border-color 0.2s;
}
.modal-input:focus {
    @apply border-blue-400 outline-none;
}

/* Modal buttons container */
.modal-buttons {
    @apply flex flex-col gap-2;
}

/* Base styles for modal buttons */
.modal-button {
    @apply px-4 py-2 font-medium text-sm rounded-md transition-all duration-200 shadow-sm focus:outline-none w-full;
}

/* Primary button styles */
.primary-button {
    @apply bg-primary-color-600 text-white hover:bg-primary-color-700 focus:ring focus:ring-blue-300;
}

/* Secondary button styles */
.secondary-button {
    @apply bg-gray-300 text-gray-700 hover:bg-gray-400 focus:ring focus:ring-gray-200;
}
</style>
