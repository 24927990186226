<template>
    <div class="flex flex-col">
        <p class="text-lg leading-7 font-medium text-black pb-6">Add a delay between actions. Select a delay type:</p>
        <div class="flex flex-col gap-y-3">
            <DelayTypeCard
                v-for="delay in delayTypes"
                :key="delay.title"
                :title="delay.title"
                :example="delay.example"
                @click="selectDelayType(delay.component)"
            ></DelayTypeCard>
        </div>
    </div>
</template>
<script lang="ts" setup>
import { Component } from "vue"
import { ClockIcon } from "@heroicons/vue/outline"
import CalendarDateComponent from "@/components/workflows/sidebar/action/delay/CalendarDateComponent.vue"
import DatePropertyComponent from "@/components/workflows/sidebar/action/delay/DatePropertyComponent.vue"
import TimeAmountComponent from "@/components/workflows/sidebar/action/delay/TimeAmountComponent.vue"
import WeekDaysComponent from "@/components/workflows/sidebar/action/delay/WeekDaysComponent.vue"
import TimeOfDayComponent from "@/components/workflows/sidebar/action/delay/TimeOfDayComponent.vue"
import DelayTypeCard from "@/components/workflows/sidebar/action/delay/DelayTypeCard.vue"

const emit = defineEmits(["changeAction"])

const delayTypes = [
    { title: "Calendar date", example: "Delay until 03/21/2023 at 12:00 PM", component: CalendarDateComponent },
    { title: "Date field", example: "Delay until contact's Last activity date", component: DatePropertyComponent },
    { title: "Set amount of time", example: "Delay for 3 days and 4 hours", component: TimeAmountComponent },
    { title: "Days of week", example: "Delay until Monday", component: WeekDaysComponent },
    { title: "Time of day", example: "Delay until 12:00 PM EST", component: TimeOfDayComponent },
    {
        title: "Event occurrence",
        example: "Delay until a contact visits your website",
        component: CalendarDateComponent,
    },
]

function selectDelayType(sidebarComponent: Component) {
    emit("changeAction", {
        icon: ClockIcon,
        title: "Configure delay...",
        sidebarComponent,
    })
}
</script>
