<template>
    <div class="flex flex-col gap-y-4">
        <span class="px-2 text-lg leading-7 font-medium text-gray-900"
            >Manage Marketing Preferences for the enrolled contacts</span
        >
        <div class="flex flex-col gap-y-2">
            <div class="grid grid-cols-4 text-sm contacting-5 font-normal text-gray-500 pe-3">
                <div></div>
                <div></div>
                <div class="text-center">Workflow</div>
                <div class="text-center">Manual</div>
            </div>
            <div class="border border-gray-200 rounded-xl flex flex-col">
                <div class="bg-gray-50 rounded-t-xl w-full grid grid-cols-4 py-2.5 px-4">
                    <div
                        class="flex text-gray-500 text-sm contacting-5 font-medium gap-x-1.5 items-center col-start-1 col-span-2"
                    >
                        <MailIcon class="w-4 h-4"></MailIcon>
                        <span>Emails</span>
                    </div>
                    <div class="text-center">
                        <InputSwitch v-model="workflowStore.marketingPreferences.isEnabledEmailWorkflow"></InputSwitch>
                    </div>
                    <div class="text-center">
                        <InputSwitch v-model="workflowStore.marketingPreferences.isEnabledEmailManual"></InputSwitch>
                    </div>
                </div>
                <div class="rounded-b-xl w-full grid grid-cols-4 py-2.5 px-4">
                    <div
                        class="flex text-gray-500 text-sm contacting-5 font-medium gap-x-1.5 items-center col-start-1 col-span-2"
                    >
                        <ChatAltIcon class="w-4 h-4"></ChatAltIcon>
                        <span>Messages</span>
                    </div>
                    <div class="text-center">
                        <InputSwitch
                            v-model="workflowStore.marketingPreferences.isEnabledMessageWorkflow"
                        ></InputSwitch>
                    </div>
                    <div class="text-center">
                        <InputSwitch v-model="workflowStore.marketingPreferences.isEnabledMessageManual"></InputSwitch>
                    </div>
                </div>
            </div>
        </div>

        <div class="border border-gray-200 rounded-xl flex flex-col">
            <div class="rounded-t-xl w-full grid grid-cols-4 py-2.5 px-4">
                <div
                    class="flex text-gray-500 text-sm contacting-5 font-medium gap-x-1.5 items-center col-start-1 col-span-2"
                >
                    <PhoneIcon class="w-4 h-4"></PhoneIcon>
                    <span>Phone Calls</span>
                </div>
                <div></div>
                <div class="text-center">
                    <InputSwitch v-model="workflowStore.marketingPreferences.isEnabledPhoneCallManual"></InputSwitch>
                </div>
            </div>
            <div class="bg-gray-50 rounded-b-xl w-full grid grid-cols-4 py-2.5 px-4">
                <div
                    class="flex text-gray-500 text-sm contacting-5 font-medium gap-x-1.5 items-center col-start-1 col-span-2"
                >
                    <BellIcon class="w-4 h-4"></BellIcon>
                    <span>Property Alerts</span>
                </div>
                <div class="text-center">
                    <InputSwitch v-model="workflowStore.marketingPreferences.isEnabledAlertWorkflow"></InputSwitch>
                </div>
                <div></div>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import { BellIcon, ChatAltIcon, MailIcon, PhoneIcon } from "@heroicons/vue/outline"
import { useWorkflowStore } from "@/stores/workflow"

const workflowStore = useWorkflowStore()
</script>
