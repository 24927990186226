<template>
    <div :class="style" @click="onInput">
        <input
            ref="checkBox"
            :checked="modelValue || checked"
            :id="name"
            :name="name"
            type="checkbox"
            :disabled="disabled"
            class="h-4 w-4 text-primary-color-600 focus:ring-primary-color-500 border-gray-300 rounded cursor-pointer"
            :class="[...classes, disabledStyle]"
        />
        <label
            v-if="label"
            :for="name"
            class="ml-3 block text-sm text-gray-900 cursor-pointer"
            :class="[...labelClasses, disabledStyle, isLabelClickable]"
        >
            {{ label }}
        </label>
        <slot v-else :for="name"></slot>
    </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, withDefaults, computed } from "vue"

const emits = defineEmits(["update:modelValue"])

interface Props {
    modelValue?: boolean | null
    label?: string
    name?: string
    checked?: boolean
    indeterminate?: boolean
    classes?: string[]
    disabled?: boolean
    isLabelClickable?: boolean
    centered?: boolean
    labelClasses?: string[]
}

const props = withDefaults(defineProps<Props>(), {
    classes: () => [],
    labelClasses: () => [],
    modelValue: false,
    centered: true,
})

const checkBox = ref()

const disabledStyle = computed(() => ({
    "cursor-not-allowed": props.disabled,
    "opacity-70": props.disabled,
}))

const style = computed(() => [props.centered ? "items-center" : "", "flex w-fit cursor-pointer", disabledStyle.value])

const isLabelClickable = computed(() => ({
    "pointer-events-none": props.isLabelClickable,
    "cursor-default": props.isLabelClickable,
}))

function onInput() {
    if (props.disabled) return
    if (props.indeterminate) {
        switch (props.modelValue) {
            case null:
                emits("update:modelValue", true)
                break
            case true:
                emits("update:modelValue", false)
                break
            case false:
                checkBox.value.indeterminate = true
                emits("update:modelValue", null)
                break
        }
    } else {
        emits("update:modelValue", !props.modelValue)
    }
}

onMounted(() => {
    if (props.modelValue === null && props.indeterminate) {
        checkBox.value.indeterminate = true
    }
})
</script>
