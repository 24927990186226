<template>
    <GgmsInput v-model="tagStore.tableState.search" placeholder="Search values" :iconStart="SearchIcon" />

    <div class="px-4 py-4.5 border border-gray-200 shadow sm:rounded-lg h-52 overflow-auto">
        <GgmsLoading v-if="tagStore.tableState.isLoading" />
        <template v-else>
            <div v-for="(tag, i) in tags" :key="tag._id" class="flex items-center" :class="getTagClass(tag)">
                <template v-if="!tag.isHidden">
                    <GgmsCheckBox v-model="localtagsSelectedState[i]" :indeterminate="tagsSelectedState[i] === null">
                        <GgmsChip
                            :bgColor="tag.backgroundColor"
                            :textColor="tag.foregroundColor"
                            :isRemovable="false"
                            class="ml-2"
                            >{{ tag.displayName }}</GgmsChip
                        >
                    </GgmsCheckBox>
                </template>
            </div>

            <p v-if="!tags.length" class="text-gray-500 mb-4">
                {{ getNotFoundText("tags", tagStore.tableState.search) }}
            </p>
        </template>
    </div>
</template>

<script lang="ts" setup>
import { computed, onUnmounted, ref, watch } from "vue"
import GgmsInput from "@/components/GgmsInput.vue"
import GgmsLoading from "@/components/GgmsLoading.vue"
import GgmsCheckBox from "@/components/GgmsCheckBox.vue"
import GgmsChip from "@/components/GgmsChip.vue"
import { SearchIcon } from "@heroicons/vue/outline"
import { useTagStore } from "@/stores/tag"
import { Tag, TagType } from "@/shared/models/tag"
import { debounce, getNotFoundText } from "@/shared/utils/helpers"

interface Props {
    tagsSelectedState: boolean[]
    hasFilterTags: boolean
    typeName: string
}

const props = defineProps<Props>()
const emit = defineEmits(["onTagSelect"])

const tagStore = useTagStore()

const localtagsSelectedState = ref<boolean[]>(props.tagsSelectedState)

const tags = computed(() => tagStore.tableState.data)

function getTagClass(tag: Tag) {
    return !tag.isHidden ? "mb-5 last:mb-0" : ""
}

function createFilterTag() {
    // get the selected tags ids which are selected in localtagsSelectedState
    const selectedTagsIds = localtagsSelectedState.value
        .map((selected, index) => (selected ? tags.value[index]._id : null))
        .filter((id) => id !== null)

    emit("onTagSelect", selectedTagsIds)
}

onUnmounted(() => {
    tagStore.tableState.search = ""
})

watch(
    () => tagStore.tableState.search,
    debounce(() => {
        if (props.hasFilterTags) {
            // this is to prevent the search from being triggered when the modal is closed
            return
        }
        tagStore.tableState.currentPage = 0
        tagStore.tableState.typeName = props.typeName === "template" ? TagType.messageTemplate : props.typeName
        tagStore.getTags()
    }, 250)
)

watch(
    () => localtagsSelectedState.value,
    () => {
        createFilterTag()
    },
    { deep: true }
)

watch(
    () => props.tagsSelectedState,
    () => {
        localtagsSelectedState.value = props.tagsSelectedState
    },
    { deep: true }
)
</script>
