import Crud from "./crud"
import { Agency, AgencyDomain, ChildAgency, PayloadDomain } from "@/shared/models/agency"
import { Client } from "@/shared/services/client"
import { ResponsePresignedUrl, UploadItem } from "../models/upload-file"

const client = new Client()

export class AgencyService extends Crud<Agency> {
    constructor() {
        super("crm/agency")
    }

    getAgency(): Promise<{ agency: Agency }> {
        return client.get(`${this.endpoint}/current`)
    }

    getDNSConfig(id: string): Promise<{ config: AgencyDomain }> {
        return client.get(`${this.endpoint}/domain/dns/config/${id}`)
    }

    updateAgency(data: Partial<Agency>): Promise<{ agency: Agency }> {
        return client.put(`${this.endpoint}`, data)
    }

    getAvailableAgencies(): Promise<{ agencies: Agency[] }> {
        return client.get(`${this.endpoint}`)
    }

    getChildAgencies(): Promise<{ data: ChildAgency[]; total: number }> {
        return client.get(`${this.endpoint}/children`)
    }

    getAgencyProfile(id: string): Promise<{ agency: ChildAgency }> {
        return client.get(`${this.endpoint}/children/${id}`)
    }

    requestPostDataForFileUpload(uploads: UploadItem[]): Promise<ResponsePresignedUrl> {
        return client.post(`${this.endpoint}/files`, { uploads })
    }

    uploadFiles(endpoint: string, data: FormData) {
        return client.postFile(endpoint, data)
    }

    createDomain(domain: PayloadDomain): Promise<{ agency: Agency }> {
        return client.post(`${this.endpoint}/domain`, domain)
    }

    configureDomain(id: string): Promise<{ success: boolean }> {
        return client.post(`${this.endpoint}/domain/dns/config/${id}`)
    }

    updateDomain(id: string, domain: PayloadDomain): Promise<{ agency: Agency }> {
        return client.patch(`${this.endpoint}/domain/${id}`, domain)
    }

    deleteDomain(id: string): Promise<{ agency: Agency }> {
        return client.delete(`${this.endpoint}/domain/${id}`)
    }
}
