import Crud from "./crud"
import { Collection } from "@/shared/models/collection"
import { Client } from "@/shared/services/client"
import { PagedResponse } from "@/shared/models/paged-response"
import { TableQueryParams } from "@/shared/models/table-query-params"

const client = new Client()

export class CollectionService extends Crud<Collection> {
    constructor() {
        super("crm")
    }

    getCollections(queryParams: TableQueryParams): Promise<PagedResponse<Collection>> {
        return client.get(`${this.endpoint}/collections`, { params: queryParams })
    }

    createCollection(data: Partial<Collection>): Promise<{ collection: Collection }> {
        return client.post(`${this.endpoint}/collections`, data)
    }

    updateCollection(collectionId: string, data: Partial<Collection>): Promise<{ collection: Collection }> {
        return client.patch(`${this.endpoint}/collections/${collectionId}`, data)
    }

    bulkUpdateCollections(collections: Partial<Collection>[], contactId?: string) {
        return client.patch(`${this.endpoint}/collections/bulk/update`, { collections, contactId })
    }

    search(queryParams: TableQueryParams): Promise<PagedResponse<Collection>> {
        return client.get(`${this.endpoint}/collections/autocomplete`, { params: queryParams })
    }

    bulkDelete(ids: string[], all: boolean): Promise<{ deletedCount: number }> {
        return client.post(`${this.endpoint}/collections/bulk/delete`, { ids, all })
    }
}
