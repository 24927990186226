<template>
    <div class="flex flex-col gap-4">
        <div class="flex items-center gap-4">
            <ArrowLeftIcon
                v-if="canGoBack"
                class="h-6 w-6 text-primary-color-700 cursor-pointer shrink-0"
                @click.stop="cancelFilter"
            />
            <GgmsInput v-model="searchQuery" :iconStart="SearchIcon" placeholder="Search" class="w-full" />
        </div>
        <div>
            <!-- If collectionType is 'listings', display attributes in collapsible groups -->
            <div v-if="gridCollectionStore.collectionType === 'listings'">
                <div v-for="(group, index) in visiblePropertyGroups" :key="group.name" :class="{ 'mt-4': index > 0 }">
                    <!-- Group Name -->
                    <div
                        class="flex items-center gap-x-2 pt-3 pb-3 px-4 cursor-pointer bg-primary-color-100 border border-gray-300 rounded-lg shadow-sm"
                        @click="toggleGroup(group.name)"
                    >
                        <span class="text-lg leading-6 font-semibold text-primary-color-700">{{ group.name }}</span>
                        <ArrowDown v-if="openGroups[group.name]" class="fill-gray-700"></ArrowDown>
                        <ArrowDown v-else class="rotate-180 fill-gray-700"></ArrowDown>
                    </div>

                    <!-- Group Properties -->
                    <transition name="fade">
                        <div v-if="openGroups[group.name]" class="flex flex-col pt-3">
                            <!-- Display Properties of the Group -->
                            <div
                                v-for="attribute in getFilteredAttributes(group.properties)"
                                :key="attribute._id"
                                class="p-2.5 gap-x-2 text-sm leading-5 font-medium text-gray-500 hover:text-gray-900 cursor-pointer hover:bg-gray-100 rounded-lg flex group"
                                @click.stop="selectAttribute(attribute)"
                            >
                                <component
                                    :is="getIconByType(attribute.type, attribute.name)"
                                    :class="[
                                        useFill(attribute.type)
                                            ? 'fill-gray-500 group-hover:fill-gray-900'
                                            : 'stroke-gray-500 group-hover:stroke-gray-900',
                                        'w-5 h-5 text-gray-500  group-hover:text-gray-900',
                                    ]"
                                ></component>
                                <span class="">{{ attribute.displayName }}</span>
                            </div>

                            <!-- Subgroups -->
                            <div v-if="group.subGroups">
                                <div v-for="subGroup in getVisibleSubGroups(group)" :key="subGroup.name">
                                    <div class="flex gap-x-2 pt-3 cursor-pointer" @click="toggleGroup(subGroup.name)">
                                        <span class="text-base leading-6 font-semibold text-gray-700 ml-4">{{
                                            subGroup.name
                                        }}</span>
                                        <ArrowDown v-if="openGroups[subGroup.name]" class="fill-gray-700"></ArrowDown>
                                        <ArrowDown v-else class="rotate-180 fill-gray-700"></ArrowDown>
                                    </div>

                                    <!-- Subgroup Properties -->
                                    <transition name="fade">
                                        <div v-if="openGroups[subGroup.name]" class="flex flex-col pt-3 ml-4">
                                            <div
                                                v-for="attribute in getFilteredAttributes(subGroup.properties)"
                                                :key="attribute._id"
                                                class="p-2.5 gap-x-2 text-sm leading-5 font-medium text-gray-500 hover:text-gray-900 cursor-pointer hover:bg-gray-100 rounded-lg flex group"
                                                @click.stop="selectAttribute(attribute)"
                                            >
                                                <component
                                                    :is="getIconByType(attribute.type, attribute.name)"
                                                    :class="[
                                                        useFill(attribute.type)
                                                            ? 'fill-gray-500 group-hover:fill-gray-900'
                                                            : 'stroke-gray-500 group-hover:stroke-gray-900',
                                                        'w-5 h-5 text-gray-500  group-hover:text-gray-900',
                                                    ]"
                                                ></component>
                                                <span class="">{{ attribute.displayName }}</span>
                                            </div>
                                        </div>
                                    </transition>
                                </div>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>

            <!-- Else display attributes in their original format -->
            <div v-else>
                <div v-for="prototype in prototypes" :key="prototype.typeName">
                    <div class="flex flex-col gap-1">
                        <div
                            v-if="getFilteredAttributes(prototype.attributes).length === 0 && searchQuery"
                            class="p-2.5 text-gray-900"
                        >
                            No results
                        </div>
                        <div
                            v-else
                            v-for="category in getAttributesByCategories(prototype, [], true)"
                            :key="category._id"
                        >
                            <div class="flex gap-x-2 pt-3 cursor-pointer" @click="toggleGroup(category.name)">
                                <span class="text-base leading-6 font-semibold text-gray-700">{{
                                    category.displayName
                                }}</span>
                                <ArrowDown v-if="openGroups[category.name]" class="fill-gray-700"></ArrowDown>
                                <ArrowDown v-else class="rotate-180 fill-gray-700"></ArrowDown>
                            </div>
                            <transition name="fade">
                                <div v-if="openGroups[category.name]" class="flex flex-col pt-3">
                                    <div
                                        v-for="attribute in getFilteredAttributes(category.attributes)"
                                        :key="attribute._id"
                                        class="p-2.5 gap-x-2 text-sm leading-5 font-medium text-gray-500 hover:text-gray-900 cursor-pointer hover:bg-gray-100 rounded-lg flex group"
                                        @click.stop="selectAttribute(attribute)"
                                    >
                                        <component
                                            :is="getIconByType(attribute.type, attribute.name)"
                                            :class="[
                                                useFill(attribute.type)
                                                    ? 'fill-gray-500 group-hover:fill-gray-900'
                                                    : 'stroke-gray-500 group-hover:stroke-gray-900',
                                                'w-5 h-5 text-gray-500  group-hover:text-gray-900',
                                            ]"
                                        ></component>
                                        <span class="">{{ attribute.displayName }}</span>
                                    </div>
                                </div>
                            </transition>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from "vue"
import GgmsInput from "@/components/GgmsInput.vue"
import {
    ArrowLeftIcon,
    AtSymbolIcon,
    CalendarIcon,
    ChatAltIcon,
    HashtagIcon,
    LinkIcon,
    OfficeBuildingIcon,
    PhoneIcon,
    SearchIcon,
    TagIcon,
    UserCircleIcon,
    UserIcon,
} from "@heroicons/vue/outline"
import { Attribute, AttributeType } from "@/shared/models/attributes"
import { useGridCollectionStore } from "@/stores/grid-collection"
import { useConfigStore } from "@/stores/config"
import { getAttributesByCategories } from "@/shared/utils/helpers"
import { PropertyGroup } from "@/shared/models/property-group-layout"
import ArrowDown from "@/components/icons/ArrowDown.vue"
import StringTypeIcon from "@/components/icons/StringTypeIcon.vue"
import BooleanTypeToggleIcon from "@/components/icons/BooleanTypeToggleIcon.vue"

const gridCollectionStore = useGridCollectionStore()
const configStore = useConfigStore()

const emit = defineEmits(["onAttributeSelect", "cancel"])

const searchQuery = ref("")
const prototypes = computed(() => gridCollectionStore.prototypes)
const canGoBack = computed(() => gridCollectionStore.collectionTypeHistory.length > 1)
const propertyLayoutGroups = ref<PropertyGroup[]>([])

// Initialize all groups as open by default
const openGroups = ref<Record<string, boolean>>({})

const visiblePropertyGroups = computed(() => {
    return propertyLayoutGroups.value.filter((group) => {
        const groupVisible = hasVisibleProperties(group.properties)
        const subGroupsVisible = group.subGroups?.some((sub) => hasVisibleProperties(sub.properties)) || false
        return groupVisible || subGroupsVisible
    })
})

function hasVisibleProperties(properties: Attribute[] = []) {
    return getFilteredAttributes(properties).length > 0
}

function getVisibleSubGroups(group: PropertyGroup) {
    return group.subGroups?.filter((sub) => hasVisibleProperties(sub.properties)) || []
}

function getFilteredAttributes(attributes: Attribute[] = []) {
    if (!Array.isArray(attributes)) {
        return [] // return an empty array if attributes is undefined or not an array
    }

    return attributes
        .filter(
            (attribute) =>
                attribute.displayName.toLowerCase().includes(searchQuery.value.toLowerCase()) &&
                attribute.name !== "_id" &&
                attribute.name !== "password"
        )
        .sort((a, b) => a.displayName.localeCompare(b.displayName))
}

function selectAttribute(attribute: Attribute) {
    emit("onAttributeSelect", attribute)
}

function cancelFilter() {
    emit("cancel")
}

// Toggle the group open/closed
function toggleGroup(groupName: string) {
    openGroups.value[groupName] = !openGroups.value[groupName]
}

function getIconByType(type: string, name: string) {
    switch (type.toLowerCase()) {
        case AttributeType.Date:
            return CalendarIcon
        case AttributeType.String:
            if (name === "owner" || name === "agent" || name === "data.command.query.createdById") return UserCircleIcon
            if (name === "email") return AtSymbolIcon
            return StringTypeIcon
        case AttributeType.Strings:
            return StringTypeIcon
        case AttributeType.Number:
            return HashtagIcon
        case AttributeType.Boolean:
            return BooleanTypeToggleIcon
        case AttributeType.Tag:
            return TagIcon
        case AttributeType.Tags:
            return TagIcon
        case AttributeType.Origin:
            return LinkIcon
        case AttributeType.Phones:
            return PhoneIcon
        case AttributeType.Emails:
            return AtSymbolIcon
        case AttributeType.EmailMessaging:
            return ChatAltIcon
        case AttributeType.Object:
            if (name === "agent") return UserCircleIcon
            if (name === "createdBy") return UserCircleIcon
            if (name === "fromPhoneNumber") return PhoneIcon
            if (name === "lead") return UserIcon
            if (name === "properties") return OfficeBuildingIcon
            break
        case AttributeType.Objects:
            if (name === "leads") return UserIcon
            if (name === "messages") return ChatAltIcon
            break
    }
}

function useFill(type: string) {
    return type === AttributeType.Boolean
}

onMounted(() => {
    propertyLayoutGroups.value = configStore.crmPropertyGroups

    // Open all groups and subgroups by default
    propertyLayoutGroups.value.forEach((group: PropertyGroup) => {
        // Open main group
        openGroups.value[group.name] = true

        // Check if the group has subGroups
        if (group.subGroups) {
            // Loop through each subgroup and open them as well
            group.subGroups.forEach((subGroup: { name: string }) => {
                openGroups.value[subGroup.name] = true
            })
        }
    })

    // Open the prototypes' categories and subgroups as well
    prototypes.value.forEach((prototype) => {
        const categories = getAttributesByCategories(prototype, [], true)
        categories.forEach((category) => {
            openGroups.value[category.name as string] = true

            // Check if the category has subgroups
            if (category.subGroups) {
                category.subGroups.forEach((subGroup: { name: string }) => {
                    openGroups.value[subGroup.name] = true
                })
            }
        })
    })
})
</script>
