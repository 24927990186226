import { defineStore } from "pinia"
import { Form, FormAnswer } from "@/shared/models/form"
import { FormService } from "@/shared/services/form"
import { ToastService } from "@/shared/services/toast"
import { TableState } from "@/shared/models/table"
import { Tag } from "@/shared/models/tag"
import { copyToClipboard, getUser } from "@/shared/utils/helpers"
import InputDefaultIcon from "@/components/icons/InputDefaultIcon.vue"
import InputLongIcon from "@/components/icons/InputLongIcon.vue"
import BulletListWithOneCheckmarkIcon from "@/components/icons/BulletListWithOneCheckmarkIcon.vue"
import BulletListWithTwoCheckmarkIcon from "@/components/icons/BulletListWithTwoCheckmarkIcon.vue"
import RowsIcon from "@/components/icons/RowsIcon.vue"
import SectionIcon from "@/components/icons/SectionIcon.vue"
import SeparatorIcon from "@/components/icons/SeparatorIcon.vue"
import FormButtonIcon from "@/components/icons/FormButtonIcon.vue"
import { CalendarIcon, ClipboardIcon, ClockIcon, PhoneIcon, AtSymbolIcon, HashtagIcon } from "@heroicons/vue/outline"
import { useGridCollectionStore } from "@/stores/grid-collection"
import { UploadIcon } from "@heroicons/vue/solid"
import { UploadFile, UploadItem } from "../shared/models/upload-file"
import PasswordFormIcon from "@/components/icons/PasswordFormIcon.vue"
import { useSocketStore } from "./socket"
import { GgmsError } from "@/shared/services/client"

export const useFormStore = defineStore("forms", {
    state: () => ({
        // forms
        tableState: {
            data: [],
            selectedData: [],
            currentPage: 0,
            lastPage: 0,
            pageLength: 50,
            numberOfPages: 0,
            total: 0,
            isLoading: false,
            isAllSelected: false,
            templateType: "",
            firstIds: [],
        } as TableState<Form>,
        // formResponses
        tableStateForResponses: {
            data: [],
            currentPage: 0,
            pageLength: 50,
            numberOfPages: 0,
            total: 0,
            isLoading: false,
            templateType: "",
            search: "",
        } as TableState<FormAnswer>,
        form: {} as Form,
        currentItem: {} as Form,
        currentFormAnswer: {} as FormAnswer,
        formService: new FormService(),
        toastService: new ToastService(),
        sectionIndex: 0,
        elementIndex: 0,
        isElementSelected: false,
        isCreatingForm: false,
        gridCollectionStore: useGridCollectionStore(),
        socketStore: useSocketStore(),
        hasUnsavedChanges: false,
    }),
    actions: {
        async getForms(isFromCollection = false) {
            try {
                this.tableState.isLoading = true

                let lastId = null
                let offset = null

                // If we're going to the next page, use keyset pagination.
                if (this.tableState.currentPage === (this.tableState?.lastPage ?? 0) + 1) {
                    lastId =
                        this.tableState.data.length > 0
                            ? this.tableState.data[this.tableState.data?.length - 1]._id
                            : null
                }
                // If we're jumping to a specific page, use offset-based pagination.
                else if (
                    this.tableState.currentPage !== (this.tableState?.lastPage ?? 0) + 1 &&
                    this.tableState.currentPage > 1
                ) {
                    offset = (this.tableState.currentPage - 1) * this.tableState.pageLength
                }

                const filtersGroups = this.gridCollectionStore?.grid?.filtersGroups?.length
                    ? encodeURIComponent(JSON.stringify(this.gridCollectionStore.grid.filtersGroups))
                    : undefined

                if (!this.gridCollectionStore?.grid?.filtersGroups?.length && isFromCollection) {
                    this.tableState.data = []
                    this.tableState.total = 0
                    this.tableState.selectedData = []
                    this.tableState.numberOfPages = Math.ceil(1)
                    this.tableState.lastPage = this.tableState.currentPage
                    return
                }

                const response = await this.formService.getAll({
                    length: this.tableState.pageLength,
                    column: this.tableState.column,
                    order: this.tableState.order,
                    search: this.tableState.search,
                    filtersGroups,
                    lastId,
                    offset,
                })

                if (!response) {
                    return
                }

                // Store the first ID of the new page.
                if (this.tableState.firstIds && this.tableState.currentPage) {
                    this.tableState.firstIds[this.tableState.currentPage - 1] = response.data[0]?._id
                }

                this.tableState.selectedData = []
                this.tableState.data = response.data
                this.tableState.total = response.total
                this.tableState.numberOfPages = Math.ceil(this.tableState.total / this.tableState.pageLength)
                this.tableState.lastPage = this.tableState.currentPage
            } finally {
                this.tableState.isLoading = false
            }
        },

        resetTableState() {
            this.tableState.data = []
            this.tableState.total = 0
            this.tableState.selectedData = []
            this.tableState.numberOfPages = Math.ceil(1)
        },

        async getFormById(id: string) {
            const { form } = await this.formService.getOne(id)
            if (!form) {
                return
            }
            this.form = form
            this.currentItem = form
        },

        async getStartCallSurveyForm(contactId: string) {
            const response = await this.formService.getAll({ isCallSurvey: true, contactId })
            if (!response) {
                return
            }
            this.form = response.data[0]
            this.currentItem = response.data[0]
        },

        async getEndCallSurveyForm(contactId: string) {
            const response = await this.formService.getAll({ isEndedCallSurvey: true, contactId })
            if (!response) {
                return
            }
            this.form = response.data[0]
            this.currentItem = response.data[0]
        },

        async getRegisterAgentForm() {
            const response = await this.formService.getAll({ isRegistrationAgent: true })
            if (!response) {
                return
            }
            this.form = response.data[0]
            this.currentItem = response.data[0]
        },

        async createForm(form: Form) {
            const response = await this.formService.createForm(form)
            if (!response) {
                return
            }
            this.toastService.addToast({
                message: "Form submitted successfully",
                type: "success",
            })
        },

        async updateForm(form: Form) {
            const response = await this.formService.updateForm(form._id, form)
            if (!response) {
                return
            }
            this.toastService.addToast({
                message: "Form updated successfully",
                type: "success",
            })
        },

        async deleteForm(id: string) {
            const response = await this.formService.deleteOne(id)
            if (!response) {
                return
            }
            this.toastService.addToast({
                message: "Form deleted successfully",
                type: "success",
            })
            this.tableState.data = this.tableState.data.filter((form) => form._id !== id)
        },

        async requestPostDataForFileUpload(uploads: UploadItem[], files: File[]): Promise<UploadFile[]> {
            const response = await this.formService.requestPostDataForFileUpload(uploads)
            let uploadedFiles = []
            if (!response) throw new Error("Failed to upload files")
            uploadedFiles = await Promise.all(
                response.presignedPostUrls.map((file, index) => {
                    const formData = new FormData()
                    Object.entries(file.fields).forEach(([key, value]) => {
                        formData.append(key, value)
                    })
                    formData.append("file", files[index])
                    return this.formService.uploadFiles(file.url, formData)
                })
            )
            uploadedFiles = uploadedFiles.map((file) => file?.data?.upload)

            return uploadedFiles
        },

        async bulkUpdateTags(formIds: string[], toAdd: Tag[], toRemove: Tag[], all: boolean) {
            const { job } = await this.formService.bulkUpdateTags(formIds, toAdd, toRemove, all)
            this.socketStore.addJob(job)
            this.form.tags = this.form?.tags?.concat(toAdd)
            this.form.tags = this.form?.tags?.filter((tag) => !toRemove.find((t) => t._id === tag._id))
        },

        async getFormResponses(formId: string) {
            try {
                this.tableStateForResponses.isLoading = true
                const { responses } = await this.formService.getFormResponses(formId)
                if (!responses) {
                    return
                }
                this.tableStateForResponses.data = responses
                this.tableStateForResponses.total = responses.length
            } finally {
                this.tableStateForResponses.isLoading = false
            }
        },

        async getFormResponse(formId: string, formResponseId: string) {
            const { response } = await this.formService.getFormResponse(formId, formResponseId)
            if (!response) {
                return
            }
            this.currentFormAnswer = response
        },

        async createFormResponse(formId: string, formAnswer: FormAnswer) {
            const response = await this.formService.createFormResponse(formId, formAnswer)
            if (!response) {
                return
            }
            this.toastService.addToast({
                message: "Form answers submitted successfully",
                type: "success",
            })
            return response
        },

        copyPublicUrl(formId?: string, token?: string, responseId?: string) {
            if (!formId || !token) {
                return
            }

            const userId = getUser()?._id

            const url = responseId
                ? `${window.location.origin}/public/forms/${formId}/response/${responseId}?token=${token}`
                : `${window.location.origin}/public/forms/${formId}?token=${token}&userId=${userId}`
            copyToClipboard(url)
            this.toastService.addToast({
                message: "Public Url copied to clipboard",
                type: "success",
            })
        },

        resetFormStore() {
            this.tableState = {
                data: [],
                selectedData: [],
                currentPage: 0,
                lastPage: 0,
                pageLength: 50,
                numberOfPages: 0,
                total: 0,
                isAllSelected: false,
            }
        },

        clearFilters() {
            this.gridCollectionStore.grid.filtersGroups = []
            this.gridCollectionStore.filtersGroups = []

            this.updateFormsGrid()
        },

        async getFormsGrid() {
            try {
                this.tableState.isLoading = true
                const response = await this.gridCollectionStore.getGrid("forms")

                await this.getForms()
                return response
            } finally {
                this.tableState.isLoading = false
            }
        },

        async updateFormsGrid() {
            try {
                this.tableState.isLoading = true
                if (!this.gridCollectionStore.grid) return
                this.gridCollectionStore.grid.filtersGroups = this.gridCollectionStore.filtersGroups || []

                const response = await this.gridCollectionStore.updateGrid("forms", this.gridCollectionStore.grid)
                this.gridCollectionStore.gridResponse = response
                this.gridCollectionStore.grid = this.gridCollectionStore.gridResponse.grid
                this.gridCollectionStore.filtersGroups = this.gridCollectionStore.grid.filtersGroups

                await this.getForms()
            } finally {
                this.tableState.isLoading = false
            }
        },
        async autocomplete(search?: string): Promise<Form[]> {
            return await this.formService.autocomplete(search)
        },

        async checkFormList(typename: string, entityIds: string[]) {
            try {
                return this.formService.checkFormList(typename, entityIds)
            } catch (error) {
                const err = error as GgmsError
                if (err.code === "ValidationError") {
                    this.toastService.addToast({
                        type: "error",
                        message: err.message,
                    })
                }
            }
        },
    },
})

export const elementTypeOptions = [
    {
        label: "Button",
        value: "button",
        icon: FormButtonIcon,
    },
    {
        label: "Date",
        value: "date",
        icon: CalendarIcon,
    },
    {
        label: "Dropdown",
        value: "dropdown",
        icon: RowsIcon,
    },
    {
        label: "Email",
        value: "email",
        icon: AtSymbolIcon,
    },
    {
        label: "Login",
        value: "login",
        icon: ClipboardIcon,
    },
    {
        label: "Long Answer",
        value: "longAnswer",
        icon: InputLongIcon,
    },
    {
        label: "Multiple Choice",
        value: "multipleChoice",
        icon: BulletListWithTwoCheckmarkIcon,
    },
    {
        label: "Number",
        value: "number",
        icon: HashtagIcon,
    },
    {
        label: "Password",
        value: "password",
        icon: PasswordFormIcon,
    },
    {
        label: "Phone Number",
        value: "phoneNumber",
        icon: PhoneIcon,
    },
    {
        label: "Registration",
        value: "registration",
        icon: ClipboardIcon,
    },
    {
        label: "Separator",
        value: "separator",
        icon: SeparatorIcon,
    },
    {
        label: "Short Answer",
        value: "shortAnswer",
        icon: InputDefaultIcon,
    },
    {
        label: "Single Choice",
        value: "singleChoice",
        icon: BulletListWithOneCheckmarkIcon,
    },
    {
        label: "Time",
        value: "time",
        icon: ClockIcon,
    },
    {
        label: "Upload",
        value: "upload",
        icon: UploadIcon,
    },
]

export const sectionTypeOptions = [
    {
        label: "Section",
        value: "section",
        icon: SectionIcon,
    },
]
